<div class="app-study-history">
  <div class="study-history-table">
    <div class="history-row">
      <div
        class="history-cell history-header-cell"
        *ngFor="let headerCell of studyHistoryTable?.headerRow"
      >
        <div class="history-title">{{ headerCell.title }}</div>
        <div class="history-text">{{ headerCell.text }}</div>
      </div>
    </div>
    <div class="history-row" *ngFor="let historyTableRow of studyHistoryTable?.bodyRows">
      <div
        class="history-cell"
        *ngFor="let historyTableCell of historyTableRow"
        [ngClass]="{
          'history-cell--has-changed': historyTableCell.hasChanged
        }"
      >
        <div class="history-title">{{ historyTableCell.title }}</div>
        <div class="history-text">{{ historyTableCell.text }}</div>
      </div>
    </div>
  </div>
</div>

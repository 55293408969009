import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { StudyPageService } from './study-page.service';

@Component({
  selector: 'app-study',
  styleUrls: ['./study.component.scss'],
  templateUrl: './study.component.html',
})
export class StudyComponent implements OnInit {
  studyId: number;
  search: string;
  sidenavOpened = true;

  constructor(
    private route: ActivatedRoute,
    private studyPageService: StudyPageService,
  ) {}

  ngOnInit() {
    this.studyId = this.route.snapshot.params['id'];

    this.studyPageService.search$.subscribe(({ searchId }) => {
      this.sidenavOpened = true;
      this.search = searchId;
    });
  }
}

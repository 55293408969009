<div class="app-study-form-page">
  <app-study-header
    [saveButtonVisible]="true"
    [saveButtonDisabled]="!studyFormChange.isUnsaved"
    [saveButtonLoading]="isSaving"
    [showInvalidMessage]="!studyFormChange.isValid"
    (save)="save()"
  />
  <div class="scroll-container">
    <app-loading-spinner *ngIf="loading" />
    <div class="page-content" *ngIf="!loading">
      <app-error-snack-bar [message]="errorMessage" />
      <app-study-dispatch-dialogue
        [dispatch]="study?.lastDispatch"
        [disabled]="studyFormChange.isUnsaved || isSaving"
        (saved)="disptachSaved()"
      />
      <app-study-form
        [study]="study"
        [errorMessage]="errorMessage"
        [disabled]="disabled || isSaving"
        (search)="searchInDocumentExplorer($event)"
        (valueChanges)="onValueChanges($event)"
      />
    </div>
  </div>
</div>

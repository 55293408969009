import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Study } from '../../types';

import { StudyFormChange } from './types';

@Component({
  selector: 'app-study-form',
  templateUrl: './study-form.component.html',
  styleUrls: ['./study-form.component.scss'],
})
export class StudyFormComponent {
  @Input() study: Study;
  @Input() disabled: boolean;
  @Input() errorMessage: string;

  @Output() search = new EventEmitter<string>();
  @Output() valueChanges = new EventEmitter<StudyFormChange>();

  sectionChanges: Record<string, StudyFormChange> = {
    mainSection: {
      changes: {},
      isValid: true,
      isUnsaved: false,
    },
    durationSection: {
      changes: {},
      isValid: true,
      isUnsaved: false,
    },
    shareholdersSection: {
      changes: {},
      isValid: true,
      isUnsaved: false,
    },
    administrationSection: {
      changes: {},
      isValid: true,
      isUnsaved: false,
    },
  };

  get allSections() {
    return Object.values(this.sectionChanges);
  }

  isUnsaved(): boolean {
    return this.allSections.some(({ isUnsaved }) => isUnsaved);
  }

  isValid(): boolean {
    return this.allSections.every(({ isValid }) => isValid);
  }

  getStudyChanges(): Partial<Study> {
    return this.allSections.reduce((changes, section) => {
      return {
        ...changes,
        ...section.changes,
      };
    }, {} as Partial<Study>);
  }

  onAttributeSearch(searchId: string) {
    this.search.emit(searchId);
  }

  onValueChanges(newSectionState: StudyFormChange, sectionName: string) {
    const currentSectionState = this.sectionChanges[sectionName];
    const didSectionStateChanged =
      JSON.stringify(currentSectionState) !== JSON.stringify(newSectionState);

    if (!didSectionStateChanged) {
      return;
    }

    this.sectionChanges = {
      ...this.sectionChanges,
      [sectionName]: newSectionState,
    };

    /**
     * This is a workaround to emit the valueChanges event after the
     * current change detection cycle because the study updates sections
     * validation, which updates this component's validation, creating
     * a circular co-dependency.
     */
    setTimeout(() => {
      this.valueChanges.emit({
        changes: this.getStudyChanges(),
        isValid: this.isValid(),
        isUnsaved: this.isUnsaved(),
      });
    });
  }
}

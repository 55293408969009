<div class="app-resultado-loading" *ngIf="cargando">
  <div class="contenedor-cargando">
    <i class="fas fa-cog a-spin"></i>
    <p>cargando estudio</p>
  </div>
</div>
<div class="app-resultado" *ngIf="!cargando">
  <app-sidenav-layout
    [(opened)]="showingExtraction"
    [disabled]="!showRightPanel"
  >
    <div class="documents-container" sidenav>
      <app-study-documents-explorer
        [search]="search"
        [selectedDocumentId]="selectedActuationCVE"
        [studyId]="studyId"
      />
    </div>
    <section class="study-container">
      <app-resultado-nav
        [prestudios]="prestudios"
        [prestudio_seleccionado]="prestudio_seleccionado"
        [dispatch]="lastDispatch"
        [estado]="estado"
        (seleccionar)="seleccionarPrestudio($event)"
        (mostrar_historial)="goToHistory()"
      ></app-resultado-nav>
      <app-resultado-principal
        [customer]="customer"
        [id]="id"
        [(status)]="status"
        [(sglCode)]="sglCode"
        [prestudio_seleccionado]="prestudio_seleccionado"
        [status]="status"
        [isDispatchOpen]="isDispatchOpen"
        (busqueda)="busquedaTexto($event)"
        (selectedTabChange)="selectedTabChange($event)"
        (textCopied)="textCopied($event)"
      ></app-resultado-principal>
    </section>
  </app-sidenav-layout>
</div>

import { ModificationExtractionBlock, PDFBlock } from '../../../types';
import { HtmlBuilder } from './html-builder-interface';

const childPdfBlockHtmlBuilder = (
  pdfBlock: PDFBlock,
  xOffset: number,
): string => {
  const { posX, posY, texto } = pdfBlock;

  const leftPosition = posX - xOffset;
  const style = `position: absolute; left: ${leftPosition}px; top: ${posY}px;`;

  return `<div style="${style}">${texto}</div>`;
};

const mainPdfBlockHtmlBuilder = (pdfBlock: PDFBlock): string => {
  const { objetos: pdfSubBlocks } = pdfBlock;

  const maxYPosition = Math.max(...pdfSubBlocks.map(({ posY }) => posY));
  const minXPsoition = Math.min(...pdfSubBlocks.map(({ posX }) => posX));

  const height = maxYPosition + 20;

  const style = `position: relative; font-size: 10px; height: ${height}px;`;
  const subBlocksHtml = pdfSubBlocks
    .map((pdfSubBlock) => childPdfBlockHtmlBuilder(pdfSubBlock, minXPsoition))
    .join('');

  return `<div style="${style}">${subBlocksHtml}</div>`;
};

export const modificationHtmlBuilder: HtmlBuilder<
  ModificationExtractionBlock
> = (block) => {
  const pdfBlocks = block.bloques;

  return `<div>${pdfBlocks.map(mainPdfBlockHtmlBuilder).join('')}</div>`;
};

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

import { environment } from '../../../environments/environment';
import { createErrorResponse } from '../utils';
import { SimpleResponse } from '../types';
import { UNKNOWN_HTTP_ERROR } from '../constants';
import { LegacyV1BackendStudyData } from './types';

const LEGACY_STUDIES_URL = `${environment.api}/v1/studies/`;

type LegacyV1BackendResponse<T> = {
  ok: boolean;
  mensaje: string;
  data: T;
};

@Injectable({
  providedIn: 'root',
})
export class LegacyV1StudiesService {
  constructor(private http: HttpClient) {}

  public async getStudy(
    studyId: number,
  ): Promise<SimpleResponse<LegacyV1BackendStudyData>> {
    const url = `${LEGACY_STUDIES_URL}${studyId}/`;

    try {
      const result = await lastValueFrom(
        this.http.get<LegacyV1BackendResponse<LegacyV1BackendStudyData>>(url),
      );

      const { ok, mensaje: errorMessage, data } = result;

      if (!ok) {
        return createErrorResponse(Error(errorMessage));
      }

      return { data };
    } catch {
      return createErrorResponse(UNKNOWN_HTTP_ERROR);
    }
  }
}

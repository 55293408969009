<div class="app-study-info-page">
  <app-study-header />
  <app-error-snack-bar [message]="errorMessage" />
  <div class="scroll-container">
    @if (loading) {
      <app-loading-spinner />
    } @else if (study) {
      <div class="page-content">
        <mat-card>
          <mat-card-content>
            <app-study-read-only-section>
              <app-study-read-only-section-field
                label="Estado"
                [value]="study.statusDisplay"
              />
              <app-study-read-only-section-field
                label="SGL"
                [value]="study.sglCode"
              />
            </app-study-read-only-section>
          </mat-card-content>
        </mat-card>

        <mat-card>
          <mat-card-content>
            <app-study-read-only-section>
              <app-study-read-only-section-field
                label="RUT"
                [value]="study.rut"
              />
              <app-study-read-only-section-field
                label="Razón Social"
                [value]="study.businessName"
                (search)="searchField('businessName')"
              />
              <app-study-read-only-section-field
                label="Tipo de Sociedad"
                [value]="study.companyKindDisplay"
              />
              <app-study-read-only-section-field
                label="Domicilio"
                [value]="study.address"
                (search)="searchField('address')"
              />
              <app-study-read-only-section-field
                label="Objeto"
                [value]="study.corporatePurpose"
                (search)="searchField('corporatePurpose')"
              />
              <app-study-read-only-section-field
                label="Nombre de Fantasía"
                [value]="study.fantasyName"
                (search)="searchField('fantasyName')"
              />
            </app-study-read-only-section> </mat-card-content
        ></mat-card>

        <mat-card>
          <mat-card-content>
            <app-study-read-only-section>
              <app-study-read-only-section-field
                label="Duración"
                [value]="study.durationDisplay"
                (search)="searchField('duration')"
              />
              @if (study.durationEndDate) {
                <app-study-read-only-section-field
                  label="Fecha de Término"
                  [value]="study.durationEndDateDisplay"
                  (search)="searchField('duration')"
                />
              }
              @if (study.durationTerm) {
                <app-study-read-only-section-field
                  label="Plazo"
                  [value]="study.durationTerm.toString()"
                  (search)="searchField('duration')"
                />
                <app-study-read-only-section-field
                  label="Tipo Plazo"
                  [value]="study.durationTermTypeDisplay"
                  (search)="searchField('duration')"
                />
              }
            </app-study-read-only-section> </mat-card-content
        ></mat-card>

        <mat-card>
          <mat-card-content>
            <app-study-read-only-section>
              <app-study-read-only-section-field
                label="Capital Total"
                type="equity"
                [value]="study.equityDisplay"
                (search)="searchField('equity')"
              />
              <app-study-read-only-section-field
                label="Acciones"
                [value]="study.stocksDisplay"
                (search)="searchField('equity')"
              />
            </app-study-read-only-section>
            <app-study-read-only-section-shareholders-table
              [shareholders]="study.associates"
              (search)="searchField('shareholders')"
            ></app-study-read-only-section-shareholders-table>
          </mat-card-content>
        </mat-card>

        <mat-card>
          <mat-card-content>
            <mat-accordion>
              @for (group of study.groups; track $index) {
                <mat-expansion-panel [expanded]="$index === 0">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Grupo de administración {{ $index + 1 }}</mat-panel-title
                    >
                  </mat-expansion-panel-header>
                  <app-study-read-only-section>
                    <app-study-read-only-section-field
                      label="Forma de Administrar"
                      [value]="study.administrationDisplay"
                      (search)="searchField('administration')"
                    />
                    <app-study-read-only-section-field
                      label="Número de Firmas"
                      [value]="group.numberOfSignatures.toString()"
                      (search)="searchField('administration')"
                    />
                  </app-study-read-only-section>

                  <app-study-read-only-section-administrators-table
                    [administrators]="group.administration"
                    (search)="searchField('administration')"
                  />
                  <app-study-read-only-section-faculties-table
                    [faculties]="group.faculties"
                  />
                </mat-expansion-panel>
              }
            </mat-accordion>
          </mat-card-content>
        </mat-card>
      </div>
    }
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

import { environment } from '../../../environments/environment';
import { mapFiltersToParams } from '../../utils/filters';
import { SearchFilter } from '../../types';
import { createErrorResponse, createErrorPaginatedResponse } from '../utils';
import { PaginatedResponse, SimpleResponse } from '../types';
import { UNKNOWN_HTTP_ERROR } from '../constants';
import { BackendStudy } from './types';

const STUDIES_URL = `${environment.api}/v2/studies/`;

type LegacyV1BackendResponse<T> = {
  ok: boolean;
  mensaje: string;
  data: T;
};

export type StudyUpdateParams = Partial<{
  address: string;
  administrationKind: number;
  administrationObservation: string;
  businessName: string;
  companyKind: number;
  corporatePurpose: string;
  durationEndDate: string;
  durationTerm: number;
  durationTermType: number;
  durationType: number;
  equity: number;
  id: number;
  rut: string;
  sglCode: string;
  status: number;
}>;

@Injectable({
  providedIn: 'root',
})
export class V2StudiesService {
  constructor(private http: HttpClient) {}

  public async getStudy(
    studyId: number,
  ): Promise<SimpleResponse<BackendStudy>> {
    const url = `${STUDIES_URL}${studyId}/`;

    /**
     * We need to explicitly request to get the default study response
     * because the backend returns a custom study response for each customer.
     * This "default response" flag is called "all_fields" in the backend.
     *
     * TODO: Once backend "roles" and "template" epics are done we need to decide
     * if we need to add this flag to all other requests in this service. We can't do
     * it now because default backend response doesn't include executives which are
     * required for the study list.
     * Ticket: https://equipolegalbot.atlassian.net/browse/LB-1183
     */
    const params = {
      all_fields: true,
    };

    try {
      const response = await lastValueFrom(
        this.http.get<BackendStudy>(url, {
          params,
        }),
      );

      return {
        data: response,
      };
    } catch {
      return createErrorResponse(UNKNOWN_HTTP_ERROR);
    }
  }

  public async getStudies(
    filter: SearchFilter,
    pageUrl?: string,
  ): Promise<PaginatedResponse<BackendStudy[]>> {
    if (pageUrl && !pageUrl.startsWith(STUDIES_URL)) {
      return createErrorPaginatedResponse(
        Error('Invalid page URL for study list'),
      );
    }

    const url = pageUrl || STUDIES_URL;
    const params = pageUrl ? undefined : mapFiltersToParams(filter);

    try {
      const response = await lastValueFrom(
        this.http.get<PaginatedResponse<BackendStudy[]>>(url, { params }),
      );

      return response;
    } catch {
      return createErrorPaginatedResponse(UNKNOWN_HTTP_ERROR);
    }
  }

  public async getStudyFailedRules(
    studyId: number,
  ): Promise<SimpleResponse<string[]>> {
    const url = `${STUDIES_URL}${studyId}/failed-rules/`;

    try {
      const response = await lastValueFrom(
        this.http.get<{ rules: string[] }>(url),
      );

      return {
        data: response?.rules || [],
      };
    } catch {
      return createErrorResponse(UNKNOWN_HTTP_ERROR);
    }
  }

  public async updateStudy(
    studyId: number,
    params: StudyUpdateParams,
  ): Promise<SimpleResponse<BackendStudy>> {
    const url = `${STUDIES_URL}${studyId}/`;

    try {
      const response = await lastValueFrom(
        this.http.patch<LegacyV1BackendResponse<BackendStudy>>(url, params),
      );

      const { ok, mensaje: errorMessage, data: backendStudy } = response;

      if (!ok) {
        return createErrorResponse(Error(errorMessage));
      }

      return {
        data: backendStudy,
      };
    } catch {
      return createErrorResponse(UNKNOWN_HTTP_ERROR);
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

import { environment } from '../../../environments/environment';
import { createErrorResponse } from '../utils';
import { SimpleResponse } from '../types';
import { BackendFaculty } from './types';

const FACULTIES_URL = `${environment.api}/v2/faculties/`;

@Injectable({
  providedIn: 'root',
})
export class FacultiesService {
  constructor(private http: HttpClient) {}

  public async updateFaculties(
    faculties: Omit<BackendFaculty, 'id' | 'message'>[],
  ): Promise<SimpleResponse<BackendFaculty[]>> {
    try {
      const response = await lastValueFrom(
        this.http.post<BackendFaculty[]>(FACULTIES_URL, faculties),
      );

      return {
        data: response,
      };
    } catch (error) {
      return createErrorResponse(
        /**
         * FIXME: We should use a more specific error message provided by the backend
         * Backend will return a better error message after this issue is resolved:
         * https://equipolegalbot.atlassian.net/browse/LB-1243
         */
        Error(
          'Error al actualizar las facultades. Asegúrate de que hayas seleccionado a lo menos una',
        ),
      );
    }
  }
}

import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

import { HeaderComponent } from './header.component';
import { HeaderContainerComponent } from './header-container.component';

@NgModule({
  declarations: [HeaderContainerComponent, HeaderComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    MatButtonModule,
    MatToolbarModule,
    RouterModule,
  ],
  exports: [HeaderContainerComponent],
})
export class HeaderModule {}

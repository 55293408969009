import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ROUTES, AUTH_HOME_ROUTE } from './constants/routes';
import {
  authGuard,
  capabilitiesGuard,
  unAuthGuard,
  unsavedStudyFormGuard,
} from './guards';
import { CustomersComponent } from './pages/customers/customers.component';
import { DispatchesComponent } from './pages/dispatches/dispatches.component';
import { ErrorComponent } from './pages/error/error.component';
import { LoginComponent } from './pages/login/login.component';
import { ResultadoComponent } from './resultado/resultado.component';
import { StudiesComponent } from './pages/studies/studies.component';
import { StudyComponent } from './pages/study/study.component';
import { StudyFormPageComponent } from './pages/study/study-form/study-form-page.component';
import { StudyInfoPageComponent } from './pages/study/study-info/study-info-page.component';
import { StudyHistoryPageComponent } from './pages/study/study-history/study-history-page.component';
import { UserCapabilities } from './services/auth/user-capabilities';

export const routes: Routes = [
  {
    path: '',
    redirectTo: AUTH_HOME_ROUTE,
    pathMatch: 'full',
  },
  {
    path: ROUTES.LOGIN,
    canActivate: [unAuthGuard],
    component: LoginComponent,
  },
  {
    path: ROUTES.STUDIES_LIST,
    canActivate: [
      authGuard,
      capabilitiesGuard(UserCapabilities.PAGE_SECTION.STUDIES_LIST),
    ],
    component: StudiesComponent,
  },
  {
    path: ROUTES.CUSTOMER_STUDIES_LIST,
    canActivate: [
      authGuard,
      capabilitiesGuard(UserCapabilities.PAGE_SECTION.CUSTOMER_STUDIES_LIST),
    ],
    component: CustomersComponent,
  },
  {
    path: ROUTES.DISPATCHED_STUDIES_LIST,
    canActivate: [
      authGuard,
      capabilitiesGuard(UserCapabilities.PAGE_SECTION.DISPATCHED_STUDIES_LIST),
    ],
    component: DispatchesComponent,
  },
  {
    path: `${ROUTES.STUDY}/:id`,
    canActivate: [
      authGuard,
      capabilitiesGuard(UserCapabilities.PAGE_SECTION.STUDY),
    ],
    component: ResultadoComponent,
  },
  {
    path: `${ROUTES.STUDY__BETA}/:id`,
    canActivate: [
      authGuard,
      capabilitiesGuard(UserCapabilities.PAGE_SECTION.STUDY),
    ],
    component: StudyComponent,
    children: [
      {
        path: ROUTES.STUDY__BETA__FORM,
        component: StudyFormPageComponent,
        canDeactivate: [unsavedStudyFormGuard],
      },
      {
        path: ROUTES.STUDY__BETA__HISTORY,
        component: StudyHistoryPageComponent,
      },
      {
        path: ROUTES.STUDY__BETA__INFO,
        component: StudyInfoPageComponent,
      },
      {
        path: '',
        redirectTo: ROUTES.STUDY__BETA__FORM,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

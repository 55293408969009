import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { init as sentryInit } from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

console.info(`app version: ${environment.env} | ${environment.version}`);

if (environment.production) {
  enableProdMode();
}

sentryInit({
  dsn: environment.sentry.dsn,
  environment: environment.env,
  release: environment.version,
  dist: environment.env,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

<app-study-section
  [initiallyExpanded]="initiallyExpanded"
  [isInvalid]="studyForm.invalid"
  [isUnsaved]="studyForm.dirty"
  class="app-study-administration-section"
  title="Administración"
>
  <app-study-form-input
    (undo)="resetField('administrationKind')"
    (search)="searchField('administration')"
    [control]="studyForm.controls.administrationKind"
    label="Forma de administrar"
    type="select"
    [options]="administrationKindOptions"
  />
  <app-input-group
    title="Administradores*"
    [formArray]="administrators"
    (add)="addAdministrator()"
  >
    <app-study-section
      *ngFor="let administrator of administrators.controls; let i = index"
      [title]="administrator.controls.name.value"
      [initiallyExpanded]="administrator.invalid"
      [isUnsaved]="administrator.dirty"
      [isInvalid]="administrator.invalid"
    >
      <app-study-form-input
        appRutMask
        (undo)="resetAdministratorField('rut', i)"
        (search)="searchField('administration')"
        [control]="administrator.controls.rut"
        label="RUT"
      />
      <app-study-form-input
        (undo)="resetAdministratorField('name', i)"
        (search)="searchField('administration')"
        [control]="administrator.controls.name"
        label="Nombre"
      />
      <app-study-form-input
        (undo)="resetAdministratorField('administrationKind', i)"
        (search)="searchField('administration')"
        [control]="administrator.controls.administrationKind"
        label="Tipo de administración"
        type="select"
        [options]="administrationKindOptions"
      />
      <app-study-form-input
        (undo)="resetAdministratorField('administrationRole', i)"
        (search)="searchField('administration')"
        [control]="administrator.controls.administrationRole"
        label="Forma individual de administrar"
        type="select"
        [options]="administrationRoleOptions"
      />
      <app-button
        color="secondary"
        [disabled]="disabled"
        (buttonClick)="removeAdministrator(i)"
      >
        Eliminar
      </app-button>
    </app-study-section>
  </app-input-group>
  <app-list-box
    title="Facultades*"
    [control]="studyForm.controls.faculties"
    [options]="facultiesOptions"
  >
    <button mat-icon-button (click)="searchField('faculties')">
      <mat-icon>search</mat-icon>
    </button>
  </app-list-box>
</app-study-section>

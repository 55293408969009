import { ExtractionBlock, PreStudy } from '../../types';
import { blockToHtml } from './block-to-html';
import { createExhibitsHtmlBlock } from './create-exhibits-html-block';

const BASE_STYLES = `
<style>
  .html-document {
    font-family: Arial,Helvetica,sans-serif;
    font-size: 12px;
    line-height: 25px;
  }

  .html-document h1 {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  .html-document h2 {
    font-size: 14px;
    font-weight: bold;
  }

  .html-document h3 {
    font-size: 12px;
    font-weight: bold;
    margin: 0;
  }

  .html-document tr {
    vertical-align: top;
  }

  .html-document #exhibits-list {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #CCC;
    font-size: 14px;
  }
</style>
`;

export const preStudyToHtml = (
  preStudy: PreStudy,
  styles: string = BASE_STYLES,
  blockToHtmlFn: (block: ExtractionBlock) => string = blockToHtml,
) => {
  const blocks = preStudy.estructura;
  const htmlBlocks = blocks.map((block) => blockToHtmlFn(block));

  const html = htmlBlocks.join('');
  const exhibitsBlock = createExhibitsHtmlBlock(preStudy.anexos);

  return `<div class="html-document">${styles}${html}${exhibitsBlock}</div>`;
};

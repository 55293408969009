import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { getDisplayName } from 'src/app/utils/displays';
import { STUDY_EDIT_FIELD } from 'src/app/components/study-partial-edit-dialog/constants';
import { STUDY_STATUSES } from 'src/app/constants/statuses';
import { StudyPartialEditDialogContainerComponent } from 'src/app/components/study-partial-edit-dialog/study-partial-edit-dialog-container.component';
import { STUDY_ATTRIBUTE_TO_SEARCH_ID } from 'src/app/constants/document';

/**
 * TODO: https://equipolegalbot.atlassian.net/browse/LB-925
 * Create types for:
 * - PreStudy
 * - SearchFilter
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type PreStudy = any;

@Component({
  selector: 'app-resultado-principal',
  templateUrl: './resultado-principal.component.html',
})
export class ResultadoPrincipalComponent {
  STUDY_EDIT_FIELD = STUDY_EDIT_FIELD;

  // inputs
  p: PreStudy; // variable con el valor actual del preestudio seleccionado
  @Input() customer: string;
  @Input() id: string;
  @Input() set prestudio_seleccionado(prestudio_seleccionado: PreStudy) {
    this.p = prestudio_seleccionado;
    this.generarAlertasTab();
    if (this.customer.toLowerCase() === 'datamart') {
      this.generarFacultadesMapeadasDatamart();
    } else {
      this.generarFacultadesMapeadasSantander();
    }
  }
  @Input() sglCode: string;
  @Input() status: number;
  @Input() isDispatchOpen = false;
  // outputs
  @Output() busqueda = new EventEmitter();
  @Output() sglCodeChange = new EventEmitter();
  @Output() statusChange = new EventEmitter();
  @Output() selectedTabChange = new EventEmitter<string>();
  @Output() textCopied = new EventEmitter<{ text: string; type: string }>();

  constructor(public editDialog: MatDialog) {}
  get statusDisplay() {
    return getDisplayName(this.status, STUDY_STATUSES);
  }
  // atributos de estado
  // tabs de navegación
  tabs_navegacion = [
    { nombre: 'Resumen', alerta: false, numero: 0, activo: true },
    { nombre: 'Socios', alerta: false, numero: 0, activo: true },
    { nombre: 'Administración', alerta: false, numero: 0, activo: true },
    { nombre: 'Adjuntos', alerta: false, numero: 0, activo: true },
  ];
  tab_navegacion_seleccionado = 0;

  // apoyo para loop de objetos
  objectKeys = Object.keys;

  // listado de facultades mapeadas BCI
  facultades_mapeadas;

  seleccionarTab(indice: number) {
    const tab = this.tabs_navegacion[indice];

    // verificar que el indice sea menor que el tamaño de pre-estudios
    if (indice >= this.tabs_navegacion.length) return;

    // modificar la selección
    this.selectedTabChange.emit(tab.nombre);
    this.tab_navegacion_seleccionado = indice;
  }

  generarAlertasTab() {
    // referencia al estudio seleccionado
    const e = this.p;

    // no generar alertas si es caso de error fatal o disolución
    if (e.errorFatal || e.disuelto) {
      for (const tab of this.tabs_navegacion) {
        this.marcarTab(tab.nombre, 'activo', false);
        this.marcarTab(tab.nombre, 'alerta', false);
      }
      return;
    }

    // si no, habilitar
    else {
      for (const tab of this.tabs_navegacion) {
        this.marcarTab(tab.nombre, 'activo', true);
      }
    }

    // generar alertas, dependiendo los tipos
    if (
      !e.razonSocial.ok ||
      !e.domicilio.ok ||
      !e.objeto.ok ||
      !e.duracion.ok ||
      !e.articulosRepetidos.ok
    ) {
      this.marcarTab('Resumen', 'alerta', true);
    } else {
      this.marcarTab('Resumen', 'alerta', false);
    }

    if (!e.capital.ok || !e.accionistas.ok) {
      this.marcarTab('Accionistas', 'alerta', true);
    } else {
      this.marcarTab('Accionistas', 'alerta', false);
    }

    if (!e.administracion.ok || !e.facultades.ok) {
      this.marcarTab('Administración', 'alerta', true);
    } else {
      this.marcarTab('Administración', 'alerta', false);
    }

    if (e.anexos.length > 0) {
      this.marcarTab('Adjuntos', 'numero', e.anexos.length);
      this.marcarTab('Adjuntos', 'activo', true);
    } else {
      this.marcarTab('Adjuntos', 'numero', 0);
      this.marcarTab('Adjuntos', 'activo', false);
    }
  }

  marcarTab(nombre: string, marca: string, valor: number | boolean | string) {
    for (const t of this.tabs_navegacion) {
      if (t.nombre === nombre) {
        t[marca] = valor;
        return;
      }
    }
  }

  generarFacultadesMapeadasSantander() {
    if (this.p.disuelto || !this.p.facultades.valor.facultades) return;

    // iniciar facultades del Santander
    const facultades_santander = {
      1: 'Contratar / Abrir cuentas corrientes',
      2: 'Girar en cuentas corrientes',
      3: 'Sobregirar en cuentas corrientes',
      4: 'Reconocer y rechazar saldos en cuentas corrientes',
      5: 'Retirar talonarios de cheques',
      6: 'Cancelar cheques (cobrar)',
      7: 'Endosar en dominio cheques',
      8: 'Endosar cheques y efectos de comercio para depositar en cuentas corrientes (endoso en cobranza)',
      '9.1': 'Transferencia electrónica de fondos',
      '9.2': 'Girar letras de cambio',
      10: 'Aceptar y reaceptar letras de cambio',
      11: 'Endosar en dominio letras de cambio',
      12: 'Endosar en cobranza letras de cambio',
      13: 'Endosar en garantía letras de cambio',
      14: 'Suscribir pagarés y demás documentos negociables',
      15: 'Endosar en dominio pagarés y demás documentos negociables',
      16: 'Endosar en cobranza pagarés y demás documentos negociables',
      17: 'Endosar en garantía pagarés y demás documentos negociables',
      18: 'Descontar letras de cambio, pagarés y demás documentos negociables',
      19: 'Contratar mutuos o préstamos',
      20: 'Tomar boletas bancarias de garantía',
      21: 'Avalar letras de cambio y pagarés',
      22: 'Otorgar fianzas simples',
      23: 'Otorgar / constituir fianzas solidarias y/o codedudas solidarias',
      24: 'Otorgar / Constituir prendas',
      25: 'Otorgar / Constituir hipotecas',
      26: 'Comprar inmuebles',
      27: 'Vender inmuebles',
      28: 'Dar y tomar en arrendamiento bienes inmueble',
      29: 'Comprar bienes muebles',
      30: 'Vender bienes muebles',
      31: 'Dar y tomar en arrendamiento bienes muebles',
      32: 'Comprar acciones, cuotas de FFMM y valores mobiliarios',
      33: 'Vender acciones, cuotas de FFMM y valores mobiliarios',
      34: 'Ceder créditos y aceptar cesiones',
      35: 'Novar',
      36: 'Celebrar contratos de leasing sobre bienes muebles',
      37: 'Celebrar contratos de leasing sobre bienes inmuebles',
      38: 'Celebrar contratos de fáctoring',
      39: 'Contratar cuentas de ahorro y girar en ellas',
      40: 'Tomar depósitos a plazo y a la vista',
      41: 'Invertir en mercado de capitales',
      42: 'Retirar documentos y valores',
      43: 'Contratar y usar cajas de seguridad',
      44: 'Cobrar o percibir',
      45: 'Efectuar importaciones y exportaciones, suscribir informes, registros y anexos',
      46: 'Contratar acreditivos o créditos documentarios',
      47: 'Endosar y retirar documentos de embarque',
      48: 'Hacer declaraciones juradas',
      49: 'Comprar y vender moneda y documentos en moneda extranjera',
      50: 'Celebrar compraventas de divisas a futuro y condicionales',
      51: 'Dar órdenes de pago en moneda extranjera y de emisión de cheques en moneda extranjera',
      52: 'Asumir riesgos de diferencias de cambios',
      53: 'Liquidar y remesar divisas',
      54: 'Celebrar contratos de futuro',
      55: 'Autorizar cargos en cuentas corrientes para operaciones de comercio exterior y cambios',
      56: 'Constituir, ingresar o modificar sociedades',
      57: 'Representar en sociedades (Hacer valer derechos de socio en sociedad)',
      58: 'Conferir mandatos generales',
      59: 'Conferir mandatos especiales',
      60: 'Facultades judiciales ordinarias',
      61: 'Facultades judiciales especiales',
      62: 'Autocontratación ',
      63: 'Pagos de derechos de Aduana',
      68: 'Pago servicio web SII',
      70: 'Pago servicio web previred',
      71: 'Pago servicio web otros comercios',
      72: 'Mandato para contratar productos financieros',
    };

    // mapeo de facultades empresas en un día a Santander
    const mapeo = {
      'REPRESENTACION ANTE INSTITUCIONES': [63, 68, 70],

      'REPRESENTACION ANTE SOCIEDADES Y ASOCIACIONES': [57, 56],

      'CELEBRACION DE CONTRATOS': [
        1, 54, 49, 41, 38, 37, 36, 34, 33, 32, 31, 30, 29, 28, 27, 26, 19,
      ],

      'CONSTITUCION DE GARANTIAS': [13, 14, 17, 20, 21, 22, 23, 24, 25],

      'CONTRATOS DE TRABAJO': [],

      'CONSTITUCION DE SOCIEDADES': [56],

      'OPERACIONES CON CHEQUES, LETRAS, PAGARES Y OTROS DOCUMENTOS MERCANTILES':
        [5, 7, 8, '9.2', 10, 11, 12, 13, 14, 15, 16, 18, 21, 34, 51],
      'COBRAR Y PERCIBIR': [44],

      'OPERACIONES CON BANCOS E INSTITUCIONES FINANCIERAS': [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        '9.1',
        '9.2',
        19,
        20,
        39,
        40,
        42,
        43,
        52,
        55,
        53,
      ],

      CREDITOS: [19, 46],

      DERIVADOS: [54],

      SEGUROS: [],

      'REGISTRO DE MARCAS': [],

      'OPERACIONES DE COMERCIO EXTERIOR': [45, 46, 47, 48, 49, 50, 51, 55],

      'PAGOS Y EXTINCION DE OBLIGACIONES': [35],

      'FIRMA DE DOCUMENTOS Y RETIRO DE CORRESPONDENCIA': [42, 48, 63],

      MANDATOS: [58, 59],

      'REPRESENTACION JUDICIAL': [60, 61],

      AUTOCONTRATACION: [62],
    };

    // iniciar diccionario de mapeo
    const diccionario_mapeo = {};
    for (const i of Object.keys(facultades_santander)) {
      diccionario_mapeo[i] = {
        n: i,
        f: facultades_santander[i],
        m: [],
      };
    }

    // mapear
    for (const fres of Object.keys(this.p.facultades.valor.facultades)) {
      for (const i of mapeo[fres]) {
        // agregar
        diccionario_mapeo[i].m.push(fres);
      }
    }

    // traspasar
    this.facultades_mapeadas = Object.keys(diccionario_mapeo).map(
      (i) => diccionario_mapeo[i],
    );
  }

  generarFacultadesMapeadasDatamart() {
    if (this.p.disuelto || !this.p.facultades.valor.facultades) return;

    const facultades_datamart = {
      1: 'contratar cuentas corrientes',
      2: 'girar cheques / autorizar cargos en cuenta corriente',
      3: 'pagos masivos',
      4: 'endosar y cancelar cheques',
      5: 'solicitar saldos',
      6: 'retirar talonarios de cheques',
      7: 'contratar creditos',
      8: 'contratar boletas de garantía',
      9: 'aceptar y reaceptar letras',
      10: 'endosar en dominio letras y pagarés',
      11: 'endosar en garantía letras y pagarés',
      12: 'endosar en cobranza letras y pagarés',
      13: 'descontar letras y pagarés',
      14: 'suscribir y renovar pagarés',
      15: 'firmar, endosar y cancelar dctos. de embarque',
      16: 'contratar acreditivos',
      17: 'contratar operaciones de comercio exterior',
      18: 'avalar letras de cambio y pagarés',
      19: 'constituir en fiador y codeudor solidario',
      20: 'constituir prendas',
      21: 'constituir hipotecas',
      22: 'comprar bienes raíces',
      23: 'vender bienes raíces',
      24: 'comprar y vender bienes muebles corporales',
      25: 'comprar y vender valores mobiliarios',
      26: 'arrendar bienes raíces',
      27: 'arrendar bienes muebles',
      28: 'celebrar operaciones de leasing bienes raíces',
      29: 'celebrar operaciones de leasing bienes muebles',
      30: 'ceder créditos y aceptar cesiones',
      31: 'tomar y retirar depósitos a plazo',
      32: 'tomar inversiones en fondos mutuos',
      33: 'abrir y arrendar cajas de seguridad',
      34: 'contratar y girar en cuentas de ahorro/en cuentas a la vista',
      35: 'cobrar y percibir',
      36: 'novar para asumir y/o extinguir obligaciones',
      37: 'contratar derivados',
      38: 'constituir sociedades',
      39: 'otorgar poderes y/o delegar',
    };

    const mapeo = {
      'REPRESENTACION ANTE INSTITUCIONES': [],

      'REPRESENTACION ANTE SOCIEDADES Y ASOCIACIONES': [],

      'CELEBRACION DE CONTRATOS': [22, 23, 24, 25, 26, 27, 28, 29, 30, 32],

      'CONSTITUCION DE GARANTIAS': [18, 19, 20, 21],

      'CONTRATOS DE TRABAJO': [],

      'CONSTITUCION DE SOCIEDADES': [38],

      'OPERACIONES CON CHEQUES, LETRAS, PAGARES Y OTROS DOCUMENTOS MERCANTILES':
        [2, 3, 4, 10, 11, 12, 13, 14],

      'COBRAR Y PERCIBIR': [35],

      'OPERACIONES CON BANCOS E INSTITUCIONES FINANCIERAS': [
        1, 2, 3, 5, 6, 7, 8, 31, 32, 33, 34,
      ],

      CREDITOS: [7, 8, 9, 14],

      DERIVADOS: [37],

      SEGUROS: [],

      'REGISTRO DE MARCAS': [],

      'OPERACIONES DE COMERCIO EXTERIOR': [15, 16, 17],

      'PAGOS Y EXTINCION DE OBLIGACIONES': [],

      'FIRMA DE DOCUMENTOS Y RETIRO DE CORRESPONDENCIA': [],

      MANDATOS: [39],

      'REPRESENTACION JUDICIAL': [],

      AUTOCONTRATACION: [],
    };

    // iniciar diccionario de mapeo
    const diccionario_mapeo = {};
    for (const i of Object.keys(facultades_datamart)) {
      diccionario_mapeo[i] = {
        n: i,
        f: facultades_datamart[i],
        m: [],
      };
    }

    // mapear
    for (const fres of Object.keys(this.p.facultades.valor.facultades)) {
      for (const i of mapeo[fres]) {
        // agregar
        diccionario_mapeo[i].m.push(fres);
      }
    }
    // Remove faculty because 2 is needed to this case
    // Check https://equipolegalbot.atlassian.net/wiki/spaces/L/pages/216956939/Facultades+cliente#%F0%9F%93%8D-Conversi%C3%B3n-de-facultades-BCI
    if (diccionario_mapeo[32].m.length === 1) {
      diccionario_mapeo[32].m = [];
    }

    // traspasar
    this.facultades_mapeadas = Object.keys(diccionario_mapeo).map(
      (i) => diccionario_mapeo[i],
    );
  }

  formatearNumero(numero: number): string {
    if (numero === undefined) return '- -';
    return numero.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  search(field: string) {
    this.busqueda.emit(STUDY_ATTRIBUTE_TO_SEARCH_ID[field]);
  }

  copiar(valor: string, tipo: string) {
    this.textCopied.emit({
      text: valor,
      type: tipo,
    });

    const el = document.createElement('textarea');
    el.value = valor;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  formatearFecha(fecha) {
    return fecha.split('-').reverse().join('/');
  }

  obtenerMensajeLey() {
    const d = new Date();
    const date = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    const minutes = d.getMinutes();
    const time = `${d.getHours()}:${
      minutes > 9 ? minutes : '0' + minutes.toString()
    }`;
    return `Esta sociedad fue creada por Ley 20659 / ${date} ${time}`;
  }

  quitarPuntos(valor) {
    return valor.toString().replace(/\.+/g, '');
  }

  openDialog(field: STUDY_EDIT_FIELD) {
    const dialogRef = this.editDialog.open(
      StudyPartialEditDialogContainerComponent,
      {
        data: {
          field,
          study: {
            id: this.id,
            sglCode: this.sglCode,
            status: this.status,
          },
        },
        disableClose: true,
      },
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.fieldName) {
        this[`${result.fieldName}Change`].emit(result.fieldValue);
      }
    });
  }
}

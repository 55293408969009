export enum DURATION_TYPE {
  INDEFINITE = 1,
  RENEWABLE = 2,
  DEFINITE = 7,
}

export enum DURATION_TERM_TYPE {
  MONTH = 1,
  YEAR = 2,
}

export const DURATION_TYPES = [
  {
    value: DURATION_TYPE.INDEFINITE,
    displayName: 'indefinido',
  },
  {
    value: DURATION_TYPE.RENEWABLE,
    displayName: 'renovable',
  },
  { value: DURATION_TYPE.DEFINITE, displayName: 'definido' },
];

export const DURATION_TERM_TYPES = [
  { value: DURATION_TERM_TYPE.YEAR, displayName: 'año' },
  { value: DURATION_TERM_TYPE.MONTH, displayName: 'mes' },
];

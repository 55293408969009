<div [className]="classNames">
  <h3 class="title">{{ title }}</h3>
  <div class="inputs-container">
    <ng-content></ng-content>
  </div>
  <app-button
    (buttonClick)="addHandler()"
    [disabled]="formArray?.disabled"
    [fullWidth]="true"
    type="button"
  >
    Nuevo
  </app-button>
</div>

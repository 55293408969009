import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';

import { Administrator } from '../../../types';
import { ADMINISTRATION_ROLES } from '../../../constants/administration-kinds';
import { getDisplayName } from '../../../utils/displays';

const SNACKBAR_MESSAGE = 'Copiado';

@Component({
  selector: 'app-study-read-only-section-administrators-table',
  standalone: true,
  imports: [
    ClipboardModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
  ],
  templateUrl: './study-read-only-section-administrators-table.component.html',
  styleUrl: './study-read-only-section-administrators-table.component.scss',
})
export class StudyReadOnlySectionAdministratorsTableComponent {
  @Input() administrators: Omit<Administrator, 'administrationKind'>[];
  @Output() search = new EventEmitter<void>();

  displayedColumns = ['rut', 'name', 'type', 'actions'];

  constructor(private snackbar: MatSnackBar) {}

  administrationDisplay(role: number) {
    return getDisplayName(role, ADMINISTRATION_ROLES);
  }

  textCopied() {
    this.snackbar.open(SNACKBAR_MESSAGE, '', { duration: 2000 });
  }
}

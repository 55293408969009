<div class="app-study-form" *ngIf="study">
  <app-study-main-section
    [study]="study"
    [disabled]="disabled"
    (search)="onAttributeSearch($event)"
    (valueChanges)="onValueChanges($event, 'mainSection')"
  />
  <app-study-duration-section
    [study]="study"
    [disabled]="disabled"
    (search)="onAttributeSearch($event)"
    (valueChanges)="onValueChanges($event, 'durationSection')"
  />
  <app-study-shareholders-section
    [study]="study"
    [disabled]="disabled"
    (search)="onAttributeSearch($event)"
    (valueChanges)="onValueChanges($event, 'shareholdersSection')"
  />
  <app-study-administration-section
    [study]="study"
    [disabled]="disabled"
    (search)="onAttributeSearch($event)"
    (valueChanges)="onValueChanges($event, 'administrationSection')"
  />
</div>

import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';

import { PaginatorIntl } from '../mat-intl/paginator-intl';
import { StudiesTableComponent } from './studies-table.component';
import { StudiesTableCustomerFilterComponent } from './studies-table-customer-filter/studies-table-customer-filter-container.component';
import { StudiesTableCustomerLawFirmFilterComponent } from './studies-table-customer-law-firm-filter/studies-table-customer-law-firm-filter-container.component';
import { StudiesTableFiltersComponent } from './studies-table-filters/studies-table-filters.component';
import { StudiesTableMessageComponent } from './studies-table-message/studies-table-message.component';
import { StudiesTableMonthFilterComponent } from './studies-table-month-filter/studies-table-month-filter.component';
import { StudiesTablePaginationComponent } from './studies-table-pagination/studies-table-pagination.component';
import { StudiesTableSelectFilterComponent } from './studies-table-select-filter/studies-table-select-filter.component';
import { StudiesTableTextFilterComponent } from './studies-table-text-filter/studies-table-text-filter.component';
import { StudiesTableWrapperComponent } from './studies-table-wrapper/studies-table-wrapper.component';
import { StudiesTableYearFilterComponent } from './studies-table-year-filter/studies-table-year-filter.component';

@NgModule({
  declarations: [
    StudiesTableComponent,
    StudiesTableCustomerFilterComponent,
    StudiesTableCustomerLawFirmFilterComponent,
    StudiesTableFiltersComponent,
    StudiesTableMessageComponent,
    StudiesTableMonthFilterComponent,
    StudiesTablePaginationComponent,
    StudiesTableSelectFilterComponent,
    StudiesTableTextFilterComponent,
    StudiesTableWrapperComponent,
    StudiesTableYearFilterComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    RouterModule,
  ],
  exports: [StudiesTableWrapperComponent],
  providers: [{ provide: MatPaginatorIntl, useClass: PaginatorIntl }],
})
export class StudiesTableModule {}

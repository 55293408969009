import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

import { environment } from '../../../environments/environment';
import { createErrorResponse } from '../utils';
import { SimpleResponse } from '../types';
import { BackendAdministrator } from './types';

const ADMINISTRATORS_URL = `${environment.api}/v2/administrators/`;

@Injectable({
  providedIn: 'root',
})
export class AdministratorsService {
  constructor(private http: HttpClient) {}

  public async updateAdministrators(
    administrators: Omit<BackendAdministrator, 'id'>[],
  ): Promise<SimpleResponse<BackendAdministrator[]>> {
    try {
      const response = await lastValueFrom(
        this.http.post<BackendAdministrator[]>(
          ADMINISTRATORS_URL,
          administrators,
        ),
      );

      return {
        data: response,
      };
    } catch (error) {
      /**
       * FIXME: We should use a more specific error message provided by the backend
       * Backend will return a better error message after this issue is resolved:
       * https://equipolegalbot.atlassian.net/browse/LB-1288
       */
      return createErrorResponse(
        Error(
          'Error al actualizar los administradores. Asegúrate de que exista a lo menos uno y que no hayas repetido algún rut',
        ),
      );
    }
  }
}

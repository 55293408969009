import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ButtonModule } from '../button/button.module';

import { StudySectionComponent } from './study-section.component';

@NgModule({
  declarations: [StudySectionComponent],
  imports: [
    BrowserAnimationsModule,
    ButtonModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatSidenavModule,
    MatTooltipModule,
  ],
  exports: [StudySectionComponent],
})
export class StudySectionModule {}

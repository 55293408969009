<app-study-section
  *ngIf="isStudyDispatchOpen"
  class="app-study-dispatch-update"
  title="Editar/Finalizar revisión manual"
  (cancel)="cancelHandler()"
  (save)="saveHandler()"
  [errorMessage]="errorMessage"
  [isInvalid]="dispatchForm.invalid"
  [isSaving]="isSaving"
  [isUnsaved]="dispatchForm.dirty"
  [showActions]="true"
>
  <mat-form-field class="form-field">
    <mat-label>Estado revisión</mat-label>
    <mat-select [formControl]="dispatchForm.controls.studyDispatch">
      <mat-option *ngFor="let status of statuses" [value]="status.value">
        {{ status.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="form-field">
    <mat-label>Observaciones</mat-label>
    <textarea
      matInput
      placeholder="Observaciones"
      [formControl]="dispatchForm.controls.message"
    ></textarea>
  </mat-form-field>
</app-study-section>

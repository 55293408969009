import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Dispatch } from '../../../types';

import {
  getStudyDispatch,
  STUDY_DISPATCH_APPROVED,
  STUDY_DISPATCH_OPEN,
  STUDY_DISPATCH_REJECTED,
} from './utils/study-dispatch';

@Component({
  selector: 'app-study-dispatch-update',
  templateUrl: './study-dispatch-update.component.html',
  styleUrls: ['./study-dispatch-update.component.scss'],
})
export class StudyDispatchUpdateComponent implements OnChanges, OnInit {
  @Input() dispatch: Dispatch;
  @Input() disabled: boolean;
  @Input() isSaving: boolean;
  @Input() errorMessage: string;
  @Output() save: EventEmitter<Partial<Dispatch>> = new EventEmitter();
  @Output() valueChanges: EventEmitter<Partial<Dispatch>> = new EventEmitter();

  statuses = [
    {
      label: 'Abierto',
      value: STUDY_DISPATCH_OPEN,
    },
    {
      label: 'Aprobado',
      value: STUDY_DISPATCH_APPROVED,
    },
    {
      label: 'Rechazado',
      value: STUDY_DISPATCH_REJECTED,
    },
  ];

  dispatchForm = new FormGroup({
    /**
     * Study dispatch is a mix of status and isApproved
     * as we don't want the user to be able to change the status
     * and approval status separately.
     */
    studyDispatch: new FormControl(null, [Validators.required]),
    message: new FormControl(null),
  });

  get isStudyDispatchOpen() {
    return this.dispatch.status === STUDY_DISPATCH_OPEN.status;
  }

  ngOnInit() {
    this.checkRequiredDispatch();

    this.dispatchForm.valueChanges.subscribe(({ studyDispatch, message }) => {
      this.updatePristineStates();

      const partialDispatch = {
        ...studyDispatch,
        message,
      };
      this.valueChanges.emit(partialDispatch);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.checkRequiredDispatch();

    const shouldDisableForm = this.isSaving || this.disabled;
    shouldDisableForm ? this.disableForm() : this.enableForm();

    const { previousValue, currentValue } = changes['dispatch'] || {};
    const studyHasChanged = previousValue !== currentValue;

    if (studyHasChanged) {
      this.syncFormWithDispatch();
    }
  }

  checkRequiredDispatch() {
    if (!this.dispatch) {
      throw new Error('StudyDispatchDialogueComponent: dispatch is required');
    }
  }

  syncFormWithDispatch() {
    this.dispatchForm.patchValue({
      studyDispatch: getStudyDispatch(this.dispatch),
      message: this.dispatch.message,
    });
  }

  updatePristineStates() {
    const { studyDispatch, message } = this.dispatchForm.value;

    if (getStudyDispatch(this.dispatch) === studyDispatch) {
      this.dispatchForm.get('studyDispatch').markAsPristine();
    }

    if (
      this.dispatch.message === message ||
      (this.dispatch.message === null && message === '')
    ) {
      this.dispatchForm.get('message').markAsPristine();
    }
  }

  disableForm() {
    /**
     * Disabling a form clears its dirty state, so we need to check if the form
     * is dirty before disabling it in order to keep the dirty state.
     * We set emitEvent to false to avoid triggering the valueChanges event.
     */
    const isDirty = this.dispatchForm.dirty;
    this.dispatchForm.disable({ emitEvent: false });
    isDirty && this.dispatchForm.markAsDirty();
  }

  enableForm() {
    this.dispatchForm.enable({ emitEvent: false });
  }

  saveHandler() {
    const { studyDispatch, message } = this.dispatchForm.value;
    const partialDispatch = {
      ...studyDispatch,
      message,
    };

    this.save.emit(partialDispatch);
  }

  cancelHandler() {
    this.syncFormWithDispatch();
  }
}

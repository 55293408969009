import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
  @Input() username: string;
  @Input() password: string;
  @Input() loading: boolean;
  @Input() message: string;
  @Input() disabled: boolean;

  @Output() login = new EventEmitter();
  @Output() usernameChange = new EventEmitter();
  @Output() passwordChange = new EventEmitter();

  onUsernameChange(username: string) {
    this.usernameChange.emit(username);
  }

  onPasswordChange(password: string) {
    this.passwordChange.emit(password);
  }

  onSubmit() {
    this.login.emit({
      username: this.username,
      password: this.password,
    });
  }
}

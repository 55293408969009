import { Component } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';

import { Shareholder } from '../../../../types';
import { rutValidator } from '../../../../utils/form-validators';

import { EquityValidator } from './validators/equity-validator';
import { StudyBaseSectionComponent } from '../study-base-section.component';

@Component({
  selector: 'app-study-shareholders-section',
  templateUrl: './study-shareholders-section.component.html',
  styleUrls: ['./study-shareholders-section.component.scss'],
})
export class StudyShareholdersSectionComponent extends StudyBaseSectionComponent {
  override studyForm = new FormGroup(
    {
      equity: new FormControl(null, [
        Validators.required,
        Validators.min(1),
        Validators.pattern(/^\d+$/),
      ]),
      associates: new FormArray([], Validators.required),
    },
    { validators: EquityValidator },
  );

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  get studyShareholders() {
    return this.study?.associates || [];
  }

  get shareholders() {
    return this.studyForm.get('associates') as FormArray<
      FormGroup<{
        equity: FormControl<number>;
        name: FormControl<string>;
        rut: FormControl<string>;
        stock: FormControl<number>;
      }>
    >;
  }

  createShareholder(shareholder: Shareholder) {
    return this.formBuilder.group({
      equity: [
        shareholder?.equity || null,
        [Validators.required, Validators.min(1), Validators.pattern(/^\d+$/)],
      ],
      name: [shareholder?.name || null, Validators.required],
      rut: [shareholder?.rut || null, [Validators.required, rutValidator]],
      stock: [
        shareholder?.stock || null,
        [
          Validators.required,
          Validators.min(0.01),
          Validators.pattern(/^\d+(.\d{1,2})?$/),
        ],
      ],
    });
  }

  addShareholder() {
    this.shareholders.push(this.createShareholder(null));
  }

  removeShareholder(index: number) {
    this.shareholders.removeAt(index);
  }

  override syncFormWithStudy({ previousStudy, currentStudy }) {
    if (previousStudy?.associates.length !== currentStudy?.associates.length) {
      const isDisabled = this.shareholders.disabled;

      this.shareholders.clear({ emitEvent: false });
      this.studyShareholders.reverse().forEach((shareholder) => {
        this.shareholders.push(this.createShareholder(shareholder));
      });

      isDisabled && this.shareholders.disable({ emitEvent: false });
    }

    this.studyForm.patchValue(
      {
        equity: this.study.equity,
        associates: this.studyShareholders.reverse(),
      },
      { emitEvent: false },
    );
  }

  override updatePristineStates() {
    const formShareholders = this.shareholders.controls;

    formShareholders.forEach((control, index) => {
      const studyShareholder = this.studyShareholders[index];

      if (!studyShareholder) {
        return;
      }

      Object.keys(control.controls).forEach((key) => {
        if (control.get(key).value === studyShareholder[key]) {
          control.get(key).markAsPristine();
        }
      });
    });

    if (formShareholders.length !== this.studyShareholders.length) {
      this.shareholders.markAsDirty();
    }

    const formEquity = this.studyForm.get('equity');
    const studyEquity = this.study?.equity;

    if (formEquity.value === studyEquity) {
      formEquity.markAsPristine();
    }
  }

  resetShareHolderField(field: string, index: number) {
    const studyShareholder = this.studyShareholders[index];

    this.shareholders.controls[index]
      .get(field)
      .reset(studyShareholder?.[field]);
  }
}

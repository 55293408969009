import { TitleExtractionBlock } from '../../../types';
import { HtmlBuilder } from './html-builder-interface';

export const titleHtmlBuilder: HtmlBuilder<TitleExtractionBlock> = ({
  tipo,
  texto,
}) => {
  const tag = tipo === 'titulo_primero' ? 'h1' : 'h2';

  return `<${tag}>${texto}</${tag}>`;
};

import { Injectable } from '@angular/core';

const TOKEN_KEY = 'token';
const REFRESH_TOKEN_KEY = 'refresh';

@Injectable({
  providedIn: 'root',
})
export class TokenRepository {
  private token: string;
  private refreshToken: string;

  constructor() {
    this.token = localStorage.getItem(TOKEN_KEY);
    this.refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  public getToken(): string {
    return this.token;
  }

  public setToken(token: string): void {
    localStorage.setItem(TOKEN_KEY, token);
    this.token = token;
  }

  public getRefreshToken(): string {
    return this.refreshToken;
  }

  public setRefreshToken(refreshToken: string): void {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    this.refreshToken = refreshToken;
  }
}

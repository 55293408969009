import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

import { environment } from '../../../environments/environment';

import { Session } from '../auth/session';

type BaseParams = {
  environment: string;
  version: string;
  userId?: string;
  username?: string;
};

@Injectable({
  providedIn: 'root',
})
export class LogsService {
  private baseParams: BaseParams;

  constructor(
    private session: Session,
    private analytics: GoogleAnalyticsService,
  ) {
    this.baseParams = {
      environment: environment.env,
      version: environment.version,
    };

    this.setUser();
    this.session.sessionChanged$.subscribe(() => this.setUser());
  }

  setUser() {
    const user = this.session.getUser();

    this.baseParams = {
      ...this.baseParams,
      userId: user?.id.toString(),
      username: user?.username,
    };
  }

  logAction(name: string, params?: Record<string, string>) {
    const allParams = {
      ...this.baseParams,
      ...params,
    };

    this.analytics?.event(
      name,
      'action',
      undefined,
      undefined,
      true,
      allParams,
    );
  }
}

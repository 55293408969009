import { Injectable } from '@angular/core';

import { Session } from './session';

/**
 * Keep roles sync with the backend
 * class RoleGroup
 */
export enum ROLE {
  ADMINISTRATOR = 'ADMINISTRATOR',
  ATTORNEY = 'ATTORNEY',
  ATTORNEY_SUPERVISOR = 'ATTORNEY_SUPERVISOR',
  DISPATCHER = 'DISPATCHER',
  EXECUTIVE = 'EXECUTIVE',
  EXECUTIVE_SUPERVISOR = 'EXECUTIVE_SUPERVISOR',
  LAWYER = 'LAWYER',
  LAWYER_SUPERVISOR = 'LAWYER_SUPERVISOR',
  STUDY_CREATOR = 'STUDY_CREATOR',
}

/**
 * Map values should be the same as the backend
 * class RoleGroup
 */
const roleMap = {
  [ROLE.ADMINISTRATOR]: 'Administrator',
  [ROLE.ATTORNEY]: 'Attorney',
  [ROLE.ATTORNEY_SUPERVISOR]: 'Attorney supervisor',
  [ROLE.DISPATCHER]: 'Dispatcher',
  [ROLE.EXECUTIVE]: 'Executive',
  [ROLE.EXECUTIVE_SUPERVISOR]: 'Executive supervisor',
  [ROLE.LAWYER]: 'Lawyer',
  [ROLE.LAWYER_SUPERVISOR]: 'Lawyer supervisor',
  [ROLE.STUDY_CREATOR]: 'Study Creator',
};

export enum PAGE_SECTION {
  CUSTOMER_STUDIES_LIST = 'CUSTOMER_STUDIES_LIST',
  DISPATCHED_STUDIES_LIST = 'DISPATCHED_STUDIES_LIST',
  STUDIES_LIST = 'STUDIES_LIST',
  STUDY = 'STUDY',
  STUDY__EDIT = 'STUDY__EDIT',
  STUDY__MANUAL_REVIEW = 'STUDY__MANUAL_REVIEW',
}

export enum ACTION {
  STUDY_UPDATE__ALL = 'STUDY_UPDATE__ALL',
  STUDY_UPDATE__SGL = 'STUDY_UPDATE__SGL',
  STUDY_UPDATE__STATUS = 'STUDY_UPDATE__STATUS',
  STUDY_UPDATE__STUDY = 'STUDY_UPDATE__STUDY',
}

const sectionViewCapabilities: Record<PAGE_SECTION, ROLE[]> = {
  [PAGE_SECTION.CUSTOMER_STUDIES_LIST]: [ROLE.ADMINISTRATOR, ROLE.LAWYER],
  [PAGE_SECTION.DISPATCHED_STUDIES_LIST]: [ROLE.ADMINISTRATOR, ROLE.LAWYER],
  [PAGE_SECTION.STUDIES_LIST]: [ROLE.ADMINISTRATOR, ROLE.LAWYER],
  [PAGE_SECTION.STUDY]: [ROLE.ADMINISTRATOR, ROLE.LAWYER],
  [PAGE_SECTION.STUDY__EDIT]: [ROLE.ADMINISTRATOR, ROLE.LAWYER],
  [PAGE_SECTION.STUDY__MANUAL_REVIEW]: [ROLE.ADMINISTRATOR, ROLE.LAWYER],
};

const actionsCapabilities: Record<ACTION, ROLE[]> = {
  [ACTION.STUDY_UPDATE__ALL]: [ROLE.ADMINISTRATOR, ROLE.LAWYER],
  [ACTION.STUDY_UPDATE__SGL]: [ROLE.ADMINISTRATOR, ROLE.LAWYER],
  [ACTION.STUDY_UPDATE__STATUS]: [ROLE.ADMINISTRATOR, ROLE.LAWYER],
  [ACTION.STUDY_UPDATE__STUDY]: [ROLE.ADMINISTRATOR, ROLE.LAWYER],
};

@Injectable({
  providedIn: 'root',
})
export class UserCapabilities {
  static readonly ROLE = ROLE;
  static readonly PAGE_SECTION = PAGE_SECTION;
  static readonly ACTION = ACTION;

  constructor(private session: Session) {}

  public hasRole(role: ROLE): boolean {
    const user = this.session.getUser();

    if (!user) {
      return false;
    }

    return user.roles.includes(roleMap[role]);
  }

  public hasAnyRole(roles: ROLE[]): boolean {
    return roles.some((role) => this.hasRole(role));
  }

  public hasSectionViewCapability(section: PAGE_SECTION): boolean {
    const allowedRoles = sectionViewCapabilities[section] || [];

    return this.hasAnyRole(allowedRoles);
  }

  public hasActionCapability(action: ACTION): boolean {
    const allowedRoles = actionsCapabilities[action] || [];

    return this.hasAnyRole(allowedRoles);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SearchLabel } from '../../../types';
import { StudyTableSelectFilter } from '../types';

export enum SELECT_FILTER {
  CUSTOMER,
  CUSTOMER_LAWFIRM,
  DISPATCH_STATUS,
  STATUS,
}
@Component({
  selector: 'app-studies-table-filters',
  templateUrl: './studies-table-filters.component.html',
  styleUrls: ['./studies-table-filters.component.scss'],
})
export class StudiesTableFiltersComponent implements OnInit {
  @Input() customer: string;
  @Input() customerLawFirm: string;
  @Input() month: string;
  @Input() year: string;
  @Input() selectFilters: StudyTableSelectFilter[];
  @Input() searchLabel: SearchLabel;
  @Input() searchLabels: SearchLabel[];
  @Input() searchText: string;
  @Output() customerChange = new EventEmitter<string>();
  @Output() customerLawFirmChange = new EventEmitter<string>();
  @Output() filterChanged = new EventEmitter();
  @Output() monthChange = new EventEmitter<string>();
  @Output() searchLabelChange = new EventEmitter<SearchLabel>();
  @Output() searchTextLabelChange = new EventEmitter();
  @Output() searchTextChange = new EventEmitter<string>();
  @Output() selectFiltersChange = new EventEmitter();
  @Output() yearChange = new EventEmitter();

  showCustomerFilter: boolean;
  showCustomerLawFirmFilter: boolean;

  ngOnInit() {
    this.setShowCustomerFilters();
  }

  setShowCustomerFilters() {
    this.showCustomerFilter = this.customer !== undefined;
    this.showCustomerLawFirmFilter = this.customerLawFirm !== undefined;
  }

  valueChange() {
    this.filterChanged.emit();
  }

  customerChanged() {
    this.customerChange.emit(this.customer);

    if (this.showCustomerLawFirmFilter) {
      this.customerLawFirm = '';
      this.customerLawFirmChange.emit(this.customerLawFirm);
    }

    this.filterChanged.emit();
  }

  customerLawFirmChanged() {
    this.customerLawFirmChange.emit(this.customerLawFirm);
    this.filterChanged.emit();
  }

  yearChanged() {
    this.yearChange.emit(this.year);
    this.filterChanged.emit();
  }

  monthChanged() {
    this.monthChange.emit(this.month);
    this.filterChanged.emit();
  }

  searchLabelChanged() {
    this.searchLabelChange.emit(this.searchLabel);
    this.searchTextLabelChange.emit();
  }

  searchTextChanged() {
    this.searchTextChange.emit(this.searchText);
    this.filterChanged.emit();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

const REFRESH_TOKEN_URL = `${environment.api}/v2/token/refresh/`;

type RefreshTokenResponse = {
  access: string;
};

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private http: HttpClient) {}

  public refresh(refresh: string): Observable<RefreshTokenResponse> {
    return this.http.post<RefreshTokenResponse>(REFRESH_TOKEN_URL, {
      refresh,
    });
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEsCl from '@angular/common/locales/es-CL';

import { createErrorHandler } from '@sentry/angular-ivy';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './services/auth/auth.interceptor';
import { CustomersComponent } from './pages/customers/customers.component';
import { ComponentsModule } from './components/components.module';
import { DispatchesComponent } from './pages/dispatches/dispatches.component';
import { ErrorComponent } from './pages/error/error.component';
import { StudiesComponent } from './pages/studies/studies.component';
import { LoginComponent } from './pages/login/login.component';
import { ResultadoComponent } from './resultado/resultado.component';
import { ResultadoNavComponent } from './resultado/resultado-nav/resultado-nav.component';
import { ResultadoPrincipalComponent } from './resultado/resultado-principal/resultado-principal.component';
import { SafePipesModule } from './pipes/sanitizador.pipe';
import { StudyModule } from './pages/study/study.module';
import { DEFAULT_LOCALE } from './constants/formats';

registerLocaleData(localeEsCl);

@NgModule({
  declarations: [
    AppComponent,
    CustomersComponent,
    DispatchesComponent,
    ErrorComponent,
    StudiesComponent,
    LoginComponent,
    ResultadoComponent,
    ResultadoNavComponent,
    ResultadoPrincipalComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    ComponentsModule,
    FormsModule,
    HttpClientModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsTrackingId),
    NgxGoogleAnalyticsRouterModule,
    ReactiveFormsModule,
    SafePipesModule,
    StudyModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler(),
    },
    {
      provide: LOCALE_ID,
      useValue: DEFAULT_LOCALE,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

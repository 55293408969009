import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StudyTableSelectOption } from '../types';

const defaultOptions = [{ displayName: 'Todos', value: '' }];

@Component({
  selector: 'app-studies-table-select-filter',
  templateUrl: './studies-table-select-filter.component.html',
  styleUrls: ['./studies-table-select-filter.component.scss'],
})
export class StudiesTableSelectFilterComponent {
  @Input() errorMessage: string;
  @Input() label: string;
  @Input() value: StudyTableSelectOption['value'];
  @Input() set options(options: StudyTableSelectOption[]) {
    this._options = [...defaultOptions, ...options];
  }
  @Output() valueChange = new EventEmitter<StudyTableSelectOption['value']>();
  _options: StudyTableSelectOption[] = defaultOptions;

  selectionChange() {
    this.valueChange.emit(this.value);
  }
}

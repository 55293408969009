const EMPTY_DISPLAY_NAME = '- -';

export const getDisplayName = (
  id: number,
  options: {
    displayName: string;
    value: number;
  }[],
): string => {
  if (!options?.length) {
    return EMPTY_DISPLAY_NAME;
  }

  const displayName = options.find((status) => status.value === id);

  return displayName?.displayName || EMPTY_DISPLAY_NAME;
};

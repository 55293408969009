<div class="app-study-read-only-section-administrators-table">
  <span class="label">Administradores</span>
  <table mat-table [dataSource]="administrators">
    <ng-container matColumnDef="rut">
      <th mat-header-cell *matHeaderCellDef>RUT</th>
      <td mat-cell *matCellDef="let element">
        <div class="cell nowrap">
          {{ element.rut }}
          <button
            mat-icon-button
            [cdkCopyToClipboard]="element.rut"
            (cdkCopyToClipboardCopied)="textCopied()"
          >
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nombre</th>
      <td mat-cell *matCellDef="let element">
        <div class="cell">
          {{ element.name }}
          <button
            mat-icon-button
            [cdkCopyToClipboard]="element.name"
            (cdkCopyToClipboardCopied)="textCopied()"
          >
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Tipo</th>
      <td mat-cell *matCellDef="let element">
        <div class="cell">
          {{ administrationDisplay(element.administrationRole) }}
          <button
            mat-icon-button
            [cdkCopyToClipboard]="
              administrationDisplay(element.administrationRole)
            "
            (cdkCopyToClipboardCopied)="textCopied()"
          >
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          (click)="search.emit()"
          [disabled]="!search.observers.length"
        >
          <mat-icon>search</mat-icon>
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';

import { ComponentsModule } from '../../components/components.module';

import { StudyComponent } from './study.component';
import { StudyFormPageComponent } from './study-form/study-form-page.component';
import { StudyHistoryPageComponent } from './study-history/study-history-page.component';
import { StudyInfoPageComponent } from './study-info/study-info-page.component';
import { StudyReadOnlySectionComponent } from '../../components/study-read-only-section/study-read-only-section.component';
import { StudyReadOnlySectionShareholdersTableComponent } from '../../components/study-read-only-section/study-read-only-shareholders-table/study-read-only-section-shareholders-table.component';
import { StudyReadOnlySectionFieldComponent } from '../../components/study-read-only-section/study-read-only-section-field/study-read-only-section-field.component';
import { StudyReadOnlySectionAdministratorsTableComponent } from '../../components/study-read-only-section/study-read-only-administrators-table/study-read-only-section-administrators-table.component';
import { StudyReadOnlySectionFacultiesTableComponent } from '../../components/study-read-only-section/study-read-only-faculties-table/study-read-only-section-faculties-table.component';

@NgModule({
  declarations: [
    StudyComponent,
    StudyFormPageComponent,
    StudyInfoPageComponent,
    StudyHistoryPageComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    MatCardModule,
    MatExpansionModule,
    RouterModule,
    StudyReadOnlySectionComponent,
    StudyReadOnlySectionFieldComponent,
    StudyReadOnlySectionShareholdersTableComponent,
    StudyReadOnlySectionAdministratorsTableComponent,
    StudyReadOnlySectionFacultiesTableComponent,
  ],
  exports: [StudyComponent],
})
export class StudyModule {}

<button
  mat-raised-button
  class="app-button"
  [ngClass]="{
    'app-button--full-width': fullWidth
  }"
  [color]="matColor"
  [disabled]="disabled || loading"
  [type]="type"
  (click)="clickHandler()"
>
  <ng-content *ngIf="!loading"></ng-content>
  <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>
</button>

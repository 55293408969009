import { Component, Inject } from '@angular/core';

import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snack-bar-component',
  styleUrls: ['./custom-snack-bar.component.scss'],
  templateUrl: './custom-snack-bar.component.html',
})
export class CustomSnackBarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<CustomSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: string,
  ) {}

  close() {
    this.snackBarRef.dismiss();
  }
}

export const RES_FACULTIES = [
  {
    value: 1,
    displayName: 'REPRESENTACION ANTE INSTITUCIONES',
  },
  {
    value: 2,
    displayName: 'REPRESENTACION ANTE SOCIEDADES Y ASOCIACIONES',
  },
  {
    value: 3,
    displayName: 'CELEBRACION DE CONTRATOS',
  },
  {
    value: 4,
    displayName: 'CONSTITUCION DE GARANTIAS',
  },
  {
    value: 5,
    displayName: 'CONTRATOS DE TRABAJO',
  },
  {
    value: 6,
    displayName: 'CONSTITUCION DE SOCIEDADES',
  },
  {
    value: 7,
    displayName:
      'OPERACIONES CON CHEQUES, LETRAS, PAGARES Y OTROS DOCUMENTOS MERCANTILES',
  },
  {
    value: 8,
    displayName: 'COBRAR Y PERCIBIR',
  },
  {
    value: 9,
    displayName: 'OPERACIONES CON BANCOS E INSTITUCIONES FINANCIERAS',
  },
  {
    value: 10,
    displayName: 'CREDITOS',
  },
  {
    value: 11,
    displayName: 'DERIVADOS',
  },
  {
    value: 12,
    displayName: 'SEGUROS',
  },
  {
    value: 13,
    displayName: 'REGISTRO DE MARCAS',
  },
  {
    value: 14,
    displayName: 'OPERACIONES DE COMERCIO EXTERIOR',
  },
  {
    value: 15,
    displayName: 'PAGOS Y EXTINCION DE OBLIGACIONES',
  },
  {
    value: 16,
    displayName: 'FIRMA DE DOCUMENTOS Y RETIRO DE CORRESPONDENCIA',
  },
  {
    value: 17,
    displayName: 'MANDATOS',
  },
  {
    value: 18,
    displayName: 'REPRESENTACION JUDICIAL',
  },
  {
    value: 19,
    displayName: 'AUTOCONTRATACION',
  },
  {
    value: 20,
    displayName: 'NO SE INFORMA',
  },
];

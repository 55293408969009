<div class="app-listbox" cdkListbox cdkListboxMultiple [formControl]="control">
  <h3 class="title">{{ title }}</h3>
  <div class="actions">
    <button mat-button (click)="selectAll()">Seleccionar todo</button>
    <span class="spacer"></span>
    <ng-content></ng-content>
  </div>
  <ul class="controls">
    <li
      *ngFor="let option of options"
      class="option"
      [cdkOption]="option.value"
    >
      {{ option.label }}
    </li>
  </ul>
</div>

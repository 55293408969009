import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-sidenav-layout',
  styleUrls: ['./sidenav-layout.component.scss'],
  templateUrl: './sidenav-layout.component.html',
})
export class SidenavLayoutComponent {
  @ViewChild('drawer') private drawer: MatDrawer;

  @Input() opened = true;
  @Input() disabled = false;
  @Output() openedChange = new EventEmitter<boolean>();

  private emitOpenedChange() {
    this.openedChange.emit(this.drawer.opened);
  }

  closeSidenav() {
    this.drawer.close();
    this.emitOpenedChange();
  }

  openSidenav() {
    this.drawer.open();
    this.emitOpenedChange();
  }

  toggleSidenav() {
    this.drawer.toggle();
    this.emitOpenedChange();
  }
}

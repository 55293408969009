import { Component } from '@angular/core';

import { Session } from '../../services/auth/session';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loading: boolean;
  message: string;
  password: string;
  username: string;

  constructor(private session: Session) {}

  onSubmit() {
    if (this.loading) {
      return;
    }

    this.message = null;
    this.loading = true;

    this.session.logIn(this.username, this.password).subscribe({
      error: () => {
        this.loading = false;
        this.message = 'Tu correo no coincide con tu clave';
      },
    });
  }
}

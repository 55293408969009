<div class="app-studies-table-filters">
  <app-studies-table-year-filter
    *ngIf="year"
    [(selectedYear)]="year"
    (selectedYearChange)="yearChanged()"
  ></app-studies-table-year-filter>
  <app-studies-table-month-filter
    *ngIf="month"
    [(selectedMonth)]="month"
    (selectedMonthChange)="monthChanged()"
  ></app-studies-table-month-filter>
  <app-studies-table-select-filter
    *ngFor="let selectFilter of selectFilters"
    [label]="selectFilter.label"
    [options]="selectFilter.options"
    [(value)]="selectFilter.value"
    (valueChange)="valueChange()"
  ></app-studies-table-select-filter>
  <app-studies-table-customer-filter
    *ngIf="showCustomerFilter"
    [(selectedCustomer)]="customer"
    (selectedCustomerChange)="customerChanged()"
  />
  <app-studies-table-customer-law-firm-filter
    *ngIf="showCustomerLawFirmFilter"
    [(selectedCustomerLawFirm)]="customerLawFirm"
    (selectedCustomerLawFirmChange)="customerLawFirmChanged()"
    [selectedCustomer]="customer"
  />
  <app-studies-table-text-filter
    *ngIf="searchLabels?.length"
    [searchLabels]="searchLabels"
    [(searchLabel)]="searchLabel"
    [(searchText)]="searchText"
    (searchLabelChange)="searchLabelChanged()"
    (searchTextChangeDebounced)="searchTextChanged()"
  ></app-studies-table-text-filter>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkListbox, CdkOption } from '@angular/cdk/listbox';
import { MatButtonModule } from '@angular/material/button';

import { ListBoxComponent } from './list-box.component';

@NgModule({
  declarations: [ListBoxComponent],
  imports: [
    CdkListbox,
    CdkOption,
    CommonModule,
    FormsModule,
    MatButtonModule,
    ReactiveFormsModule,
  ],
  exports: [ListBoxComponent],
})
export class ListBoxModule {}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { StudiesService } from '../../services/studies/studies.service';
import { Document } from '../../types';

@Component({
  selector: 'app-study-documents-explorer',
  template: `
    <app-base-study-documents-explorer
      [documents]="documents"
      [(selectedDocument)]="selectedDocument"
      [search]="search"
      [loading]="loading"
    />
  `,
})
export class StudyDocumentsExplorerContainerComponent implements OnChanges {
  @Input() studyId: number;
  @Input() search: string;
  @Input() selectedDocumentId: string;

  documents: Document[];
  selectedDocument: Document;
  loading = false;

  constructor(private studiesService: StudiesService) {}

  async ngOnChanges(changes: SimpleChanges) {
    const { studyIdHasChanged, selectedDocumentIdHasChanged } =
      this.getChanges(changes);

    if (selectedDocumentIdHasChanged) {
      this.updateSelectedDocument();
    }

    if (studyIdHasChanged) {
      this.resetState();

      if (!this.studyId) {
        return;
      }

      await this.updateDocuments();
      this.updateSelectedDocument();

      return;
    }
  }

  private resetState() {
    this.documents = null;
    this.selectedDocument = null;
  }

  private getChanges(changes: SimpleChanges) {
    return {
      studyIdHasChanged:
        changes['studyId']?.previousValue !== changes['studyId']?.currentValue,
      searchHasChanged:
        changes['search']?.previousValue !== changes['search']?.currentValue,
      selectedDocumentIdHasChanged:
        changes['selectedDocumentId']?.previousValue !==
        changes['selectedDocumentId']?.currentValue,
    };
  }

  private async updateDocuments() {
    this.loading = true;
    const { data: documents, error } =
      await this.studiesService.getStudyDocuments(this.studyId);

    this.loading = false;

    if (error) {
      /**
       * TODO: Properly handle the case where documents are not found
       * or an error occurs.
       */
      return;
    }

    this.documents = documents;
  }

  private updateSelectedDocument() {
    if (!this.documents?.length) {
      return;
    }

    const lastDocument = this.documents[this.documents.length - 1];

    if (!this.selectedDocumentId) {
      this.selectedDocument = lastDocument;
    }

    this.selectedDocument =
      this.documents.find(
        (document) => document.id === this.selectedDocumentId,
      ) || lastDocument;
  }
}

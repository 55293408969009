import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject, debounceTime, distinctUntilChanged, filter, tap } from 'rxjs';

import { SearchLabel } from '../../../types';

const labelOptions = [
  { displayName: 'Ejecutivo', value: SearchLabel.EXECUTIVE },
  { displayName: 'Razón Social', value: SearchLabel.BUSINESS_NAME },
  { displayName: 'RUT', value: SearchLabel.RUT },
  { displayName: 'SGL', value: SearchLabel.SGL },
];

@Component({
  selector: 'app-studies-table-text-filter',
  templateUrl: './studies-table-text-filter.component.html',
  styleUrls: ['./studies-table-text-filter.component.scss'],
})
export class StudiesTableTextFilterComponent implements OnInit {
  @Input() searchText: string;
  @Input() searchLabel: SearchLabel = SearchLabel.RUT;
  @Input() searchLabels: SearchLabel[];
  @Output() searchTextChange = new EventEmitter<string>();
  @Output() searchLabelChange = new EventEmitter<SearchLabel>();
  @Output() searchTextChangeDebounced = new EventEmitter();

  searchText$ = new Subject<string>();

  get options() {
    return labelOptions.filter((labelOption) =>
      this.searchLabels?.includes(labelOption.value),
    );
  }

  ngOnInit() {
    this.searchText$
      .pipe(
        debounceTime(500),
        filter((text) => !text || text.length > 2),
        distinctUntilChanged(),
        tap((text) => this.searchTextChangeDebounced.emit(text)),
      )
      .subscribe();
  }

  labelChanged() {
    this.searchLabelChange.emit(this.searchLabel);
  }

  textChanged() {
    this.searchText = this.searchText.trim();

    this.searchTextChange.emit(this.searchText);
    this.searchText$.next(this.searchText);
  }
}

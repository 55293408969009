import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Document } from '../../types';

@Component({
  selector: 'app-base-study-documents-explorer',
  templateUrl: './study-documents-explorer.component.html',
  styleUrls: ['./study-documents-explorer.component.scss'],
})
export class StudyDocumentsExplorerComponent {
  @Input() documents: Document[];
  @Input() selectedDocument: Document;
  @Input() search: string;
  @Input() loading: boolean;

  @Output() selectedDocumentChange = new EventEmitter<Document>();

  onSelectedDocumentChange(event: { value: Document }) {
    this.selectedDocumentChange.emit(event.value);
  }
}

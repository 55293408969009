<div
  *ngIf="control"
  class="app-study-form-input"
  [ngClass]="{ dirty: control.dirty }"
>
  <mat-form-field
    class="field-container"
    appearance="outline"
    floatLabel="always"
    subscriptSizing="dynamic"
  >
    <mat-label>{{ label }}</mat-label>
    <ng-container [ngSwitch]="type">
      <input
        *ngSwitchCase="'text'"
        type="text"
        matInput
        [formControl]="control"
        [errorStateMatcher]="matcher"
      />
      <textarea
        *ngSwitchCase="'textarea'"
        matInput
        [formControl]="control"
        [errorStateMatcher]="matcher"
      ></textarea>
      <input
        *ngSwitchCase="'number'"
        type="number"
        matInput
        [formControl]="control"
        [errorStateMatcher]="matcher"
      />
      <select
        *ngSwitchCase="'select'"
        matNativeControl
        [formControl]="control"
        [errorStateMatcher]="matcher"
      >
        <option *ngFor="let option of options" [ngValue]="option.value">
          {{ option.label }}
        </option>
      </select>
      <input
        *ngSwitchCase="'date'"
        matNativeControl
        type="date"
        [formControl]="control"
      />
    </ng-container>
    <button
      *ngIf="control.dirty"
      mat-icon-button
      [disabled]="control.disabled"
      (click)="undo.emit()"
      matSuffix
    >
      <mat-icon>undo</mat-icon>
    </button>
    <button
      *ngIf="search.observers.length"
      mat-icon-button
      (click)="search.emit()"
      matSuffix
    >
      <mat-icon>search</mat-icon>
    </button>
    <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
  </mat-form-field>
</div>

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-study-form-unsaved-dialog',
  templateUrl: './study-form-unsaved-dialog.component.html',
  styleUrls: ['./study-form-unsaved-dialog.component.scss'],
})
export class StudyFormUnsavedDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<StudyFormUnsavedDialogComponent>,
  ) {}

  continue(shouldSave: boolean): void {
    this.dialogRef.close(shouldSave);
  }
}

import { Component, Input } from '@angular/core';

import { StudyHistoryTable } from '../../types';

@Component({
  selector: 'app-study-history',
  templateUrl: './study-history.component.html',
  styleUrls: ['./study-history.component.scss'],
})
export class StudyHistoryComponent {
  @Input() studyHistoryTable: StudyHistoryTable;
}

<app-header></app-header>
<div class="customers-container">
  <app-studies-table-wrapper
    [tableColumns]="tableColumns"
    [searchLabels]="textFilterLabels"
    [selectFilters]="selectFilters"
    [defaultSearchLabel]="defaultTextFilterLabel"
    [getStudies]="getStudies"
  ></app-studies-table-wrapper>
</div>

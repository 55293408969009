import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { StudyDispatchDialogueComponent } from './study-dispatch-dialogue.component';
import { StudyDispatchDialogueContainerComponent } from './study-dispatch-dialogue-container.component';
import { ReactiveFormsModule } from '@angular/forms';
import { StudySectionModule } from '../study-section/study-section.module';
import { StudyDispatchMessageComponent } from './study-dispatch-message/study-dispatch-message.component';
import { StudyDispatchUpdateComponent } from './study-dispatch-update/study-dispatch-update.component';

@NgModule({
  declarations: [
    StudyDispatchDialogueComponent,
    StudyDispatchDialogueContainerComponent,
    StudyDispatchMessageComponent,
    StudyDispatchUpdateComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    ReactiveFormsModule,
    StudySectionModule,
  ],
  exports: [StudyDispatchDialogueContainerComponent],
})
export class StudyDispatchDialogueModule {}

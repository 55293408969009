<app-study-section
  [initiallyExpanded]="initiallyExpanded"
  [isInvalid]="studyForm.invalid"
  [isUnsaved]="studyForm.dirty"
  [title]="'Información general de la sociedad ' + study?.rut"
>
  <app-study-form-input
    (undo)="resetField('businessName')"
    (search)="searchField('businessName')"
    [control]="studyForm.controls.businessName"
    label="Razón Social"
  />
  <app-study-form-input
    (undo)="resetField('companyKind')"
    [control]="studyForm.controls.companyKind"
    label="Tipo de sociedad"
    type="select"
    [options]="companyKindOptions"
  />
  <app-study-form-input
    (undo)="resetField('address')"
    (search)="searchField('address')"
    [control]="studyForm.controls.address"
    label="Domicilio"
  />
  <app-study-form-input
    (undo)="resetField('corporatePurpose')"
    (search)="searchField('corporatePurpose')"
    [control]="studyForm.controls.corporatePurpose"
    label="Objeto"
    type="textarea"
  />
  <app-study-form-input
    (undo)="resetField('fantasyName')"
    (search)="searchField('fantasyName')"
    [control]="studyForm.controls.fantasyName"
    label="Nombre de Fantasía"
  />
</app-study-section>

import { Directive, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[appRutMask]',
})
export class RutMaskDirective implements OnInit {
  @Input() control: FormControl;

  ngOnInit() {
    this.control.valueChanges.subscribe((value) => {
      if (value) {
        this.control.setValue(this.formatRut(value), {
          emitEvent: false,
        });
      }
    });
  }

  formatRut(value: string) {
    const rut = value.replace(/[^0-9kK]+/g, '');
    if (rut.length <= 1) {
      return rut;
    }
    const rutDigits = rut.slice(0, -1);
    const rutDv = rut.slice(-1);

    return `${rutDigits.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}-${rutDv}`;
  }
}

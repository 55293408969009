import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import {
  DURATION_TERM_TYPES,
  DURATION_TYPE,
  DURATION_TYPES,
} from '../../../../constants/durations';

import { RequiredDurationValidator } from './validators/required-duration-validator';
import { StudyBaseSectionComponent } from '../study-base-section.component';

@Component({
  selector: 'app-study-duration-section',
  templateUrl: './study-duration-section.component.html',
  styleUrls: ['./study-duration-section.component.scss'],
})
export class StudyDurationSectionComponent extends StudyBaseSectionComponent {
  durationOptions = DURATION_TYPES.map(({ value, displayName }) => ({
    value,
    label: displayName,
  }));
  durationTermOptions = DURATION_TERM_TYPES.map(({ value, displayName }) => ({
    value,
    label: displayName,
  }));

  override studyForm = new FormGroup(
    {
      durationType: new FormControl(null, [Validators.required]),
      durationEndDate: new FormControl(null),
      durationTermType: new FormControl(null),
      durationTerm: new FormControl(null),
    },
    { validators: RequiredDurationValidator },
  );

  get durationIsDefinite() {
    const { value } = this.studyForm.controls['durationType'];

    return value === DURATION_TYPE.DEFINITE;
  }

  get durationIsRenewable() {
    const { value } = this.studyForm.controls['durationType'];

    return value === DURATION_TYPE.RENEWABLE;
  }

  override subscribeToValueChanges() {
    super.subscribeToValueChanges();

    this.studyForm.get('durationType').valueChanges.subscribe(() => {
      this.cleanDurations();
    });
  }

  cleanDurations() {
    this.studyForm.patchValue(
      {
        durationEndDate: null,
        durationTerm: null,
        durationTermType: null,
      },
      { emitEvent: false },
    );
  }

  override syncFormWithStudy() {
    this.studyForm.patchValue(
      {
        durationType: this.study.durationType,
        durationEndDate: this.study.durationEndDate,
        durationTerm: this.study.durationTerm,
        durationTermType: this.study.durationTermType,
      },
      { emitEvent: false },
    );
  }
}

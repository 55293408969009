<div class="pagination-container">
  <mat-paginator
    #paginator
    [length]="length"
    [hidePageSize]="true"
    [pageIndex]="pageIndex"
    (page)="handlePageEventEmit($event)"
    [pageSize]="pageSize"
  ></mat-paginator>
</div>

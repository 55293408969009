<mat-expansion-panel
  class="app-study-section"
  [ngClass]="{ unsaved: isUnsaved }"
  [expanded]="_isExpanded"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ title }}
      <mat-icon
        *ngIf="isInvalid"
        aria-hidden="false"
        aria-label="invalid fields"
        color="warn"
        fontIcon="warning"
        matTooltip="Asegurate de completar todos los campos y cumplir con las validaciones básicas del formulario"
      ></mat-icon>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-content></ng-content>
  <mat-action-row
    *ngIf="showActions && (isUnsaved || isSaving || errorMessage)"
  >
    <p *ngIf="errorMessage" class="error-message">
      {{ errorMessage }}
    </p>
    <ng-container *ngIf="isUnsaved || isSaving">
      <app-button
        color="secondary"
        [loading]="isSaving"
        [disabled]="isInvalid"
        (buttonClick)="save.emit()"
      >
        Guardar
      </app-button>
      <app-button [disabled]="isSaving" (buttonClick)="cancel.emit()">
        Cancelar
      </app-button>
    </ng-container>
  </mat-action-row>
</mat-expansion-panel>

<app-header></app-header>
<div class="app-study">
  <app-sidenav-layout [(opened)]="sidenavOpened">
    <div class="documents-container" sidenav>
      <app-study-documents-explorer [studyId]="studyId" [search]="search" />
    </div>
    <section class="study-container">
      <router-outlet></router-outlet>
    </section>
  </app-sidenav-layout>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { CustomSnackBarComponent } from './error-snack-bar/custom-snack-bar.component';
import { ErrorMessageComponent } from './error-message.component';
import { ErrorSnackBarComponent } from './error-snack-bar/error-snack-bar.component';

@NgModule({
  declarations: [
    CustomSnackBarComponent,
    ErrorMessageComponent,
    ErrorSnackBarComponent,
  ],
  exports: [ErrorMessageComponent, ErrorSnackBarComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatSnackBarModule],
})
export class ErrorMessageModule {}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { ButtonModule } from '../button/button.module';
import { ErrorMessageModule } from '../error-message/error-message.module';
import { STUDY_STATUSES } from '../../constants/statuses';
import { STUDY_EDIT_FIELD } from './constants';
import { StudyPartialEdit } from './types';

@Component({
  selector: 'app-base-study-partial-edit-dialog',
  standalone: true,
  imports: [
    ButtonModule,
    ErrorMessageModule,
    FormsModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatFormField,
    MatInputModule,
  ],
  templateUrl: './study-partial-edit-dialog.component.html',
  styleUrl: './study-partial-edit-dialog.component.scss',
})
export class StudyPartialEditDialogComponent {
  studyStatuses = STUDY_STATUSES;
  studyEditField = STUDY_EDIT_FIELD;

  @Input() data: StudyPartialEdit;
  @Input() errorMessage: string;
  @Input() loading: boolean;
  @Input() saveIsDisabled: boolean;
  @Output() closeDialog = new EventEmitter();
  @Output() save = new EventEmitter();
}

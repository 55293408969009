export enum SearchLabel {
  BUSINESS_NAME = 'businessName',
  EXECUTIVE = 'executive',
  ID = 'id',
  RUT = 'rut',
  SGL = 'sglCode',
}

export type SearchFilter = Partial<
  {
    customerId: string;
    lawFirmId: string;
    month: string;
    ordering: string;
    status: string;
    year: string;
  } & {
    [key in SearchLabel]: string;
  }
>;

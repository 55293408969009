<div class="text-filter-container">
  <div>
    <mat-form-field class="text-filter-container--text">
      <mat-label>Buscar por...</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="searchText"
        (ngModelChange)="textChanged()"
      />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-select [(value)]="searchLabel" (selectionChange)="labelChanged()">
        <mat-option *ngFor="let option of options" [value]="option.value">{{
          option.displayName
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

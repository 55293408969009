<header class="app-study-header">
  <mat-toolbar class="toolbar" color="secondary">
    <a
      mat-button
      *ngFor="let navItem of navItems"
      class="nav-button"
      routerLinkActive="active"
      [routerLink]="navItem.path"
    >
      {{ navItem.name }}
    </a>

    <!--
      DELETEME:
      This is a temporary link to the legacy form. It should be removed once the new form is fully
      implemented and the old form is no longer needed.
    -->
    <a
      mat-stroked-button
      class="nav-button legacy-form-link"
      routerLinkActive="active"
      [routerLink]="['/resultado', navItems?.[0]?.path.split('/')?.[2]]"
    >
      <mat-icon fontIcon="arrow_outward"></mat-icon>
      Ir a vista antigua
    </a>

    <span class="spacer"></span>
    <mat-icon
      *ngIf="showInvalidMessage"
      class="invalid-warning"
      aria-hidden="false"
      aria-label="invalid fields"
      color="warn"
      fontIcon="warning"
      matTooltip="Asegurate de completar todos los campos y cumplir con las validaciones básicas del formulario"
    ></mat-icon>
    <app-button
      *ngIf="saveButtonVisible"
      [disabled]="saveButtonDisabled"
      [loading]="saveButtonLoading"
      (buttonClick)="save.emit()"
      color="secondary"
    >
      Guardar
    </app-button>
  </mat-toolbar>
</header>

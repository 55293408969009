import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ROUTES } from '../../constants/routes';
import { UserCapabilities } from '../../services/auth/user-capabilities';

const BASE_ROUTE = ROUTES.STUDY__BETA;
const NAV_ITEMS = [
  {
    name: 'Estudio',
    path: ROUTES.STUDY__BETA__INFO,
    pageSectionCapability: UserCapabilities.PAGE_SECTION.STUDY,
  },
  {
    name: 'Despacho',
    path: ROUTES.STUDY__BETA__FORM,
    pageSectionCapability: UserCapabilities.PAGE_SECTION.STUDY,
  },
  {
    name: 'Historial',
    path: ROUTES.STUDY__BETA__HISTORY,
    pageSectionCapability: UserCapabilities.PAGE_SECTION.STUDY,
  },
];

@Component({
  selector: 'app-study-header',
  template: `
    <app-base-study-header
      [navItems]="navItems"
      [saveButtonDisabled]="saveButtonDisabled"
      [saveButtonLoading]="saveButtonLoading"
      [saveButtonVisible]="saveButtonVisible"
      [showInvalidMessage]="showInvalidMessage"
      (save)="save.emit($event)"
    />
  `,
})
export class StudyHeaderContainerComponent implements OnInit {
  navItems: {
    name: string;
    path: string;
  }[];
  @Input() saveButtonDisabled: boolean;
  @Input() saveButtonLoading: boolean;
  @Input() saveButtonVisible: boolean;
  @Input() showInvalidMessage: boolean;

  @Output() save = new EventEmitter<void>();

  constructor(
    private route: ActivatedRoute,
    private userCapabilities: UserCapabilities,
  ) {}

  ngOnInit() {
    this.route.parent.params.subscribe((params) => {
      this.navItems = NAV_ITEMS.filter((item) =>
        this.userCapabilities.hasSectionViewCapability(
          item.pageSectionCapability,
        ),
      ).map(({ name, path }) => ({
        name,
        path: `/${BASE_ROUTE}/${params['id']}/${path}`,
      }));
    });
  }
}

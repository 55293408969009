export const ADMINISTRATION_KINDS = [
  {
    value: 1,
    displayName: 'individualmente',
  },
  {
    value: 2,
    displayName: 'separadamente',
  },
  {
    value: 3,
    displayName: 'conjuntamente',
  },
  {
    value: 4,
    displayName: 'indistintamente',
  },
  {
    value: 5,
    displayName: 'no determinado',
  },
  {
    value: 6,
    displayName: 'un representante no socio',
  },
  {
    value: 100,
    displayName: 'otro',
  },
];

export const ADMINISTRATION_ROLES = [
  {
    value: 1,
    displayName: 'administrador',
  },
  {
    value: 2,
    displayName: 'gerente general',
  },
  {
    value: 3,
    displayName: 'administratores',
  },
  {
    value: 4,
    displayName: 'director',
  },
  {
    value: 100,
    displayName: 'otro',
  },
];

import { Component } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';

import { Administrator, Faculty } from '../../../../types';
import { rutValidator } from '../../../../utils/form-validators';
import {
  ADMINISTRATION_KINDS,
  ADMINISTRATION_ROLES,
} from '../../../../constants/administration-kinds';
import { RES_FACULTIES } from '../../../../constants/faculties';

import { StudyBaseSectionComponent } from '../study-base-section.component';

@Component({
  selector: 'app-study-administration-section',
  templateUrl: './study-administration-section.component.html',
  styleUrls: ['./study-administration-section.component.scss'],
})
export class StudyAdministrationSectionComponent extends StudyBaseSectionComponent {
  override studyForm = new FormGroup({
    administrators: new FormArray([], Validators.required),
    administrationKind: new FormControl<number>(null, [Validators.required]),
    faculties: new FormControl<Faculty[]>([], Validators.required),
  });

  administrationKindOptions = ADMINISTRATION_KINDS.map(
    ({ displayName, value }) => ({
      label: displayName,
      value,
    }),
  );
  administrationRoleOptions = ADMINISTRATION_ROLES.map(
    ({ displayName, value }) => ({
      label: displayName,
      value,
    }),
  );
  facultiesOptions = RES_FACULTIES.map(({ displayName, value }) => ({
    label: displayName,
    value: {
      faculty: value,
    },
  }));

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  get studyAdministrators() {
    return this.study?.administrators || [];
  }

  get administrators() {
    return this.studyForm.get('administrators') as FormArray<
      FormGroup<{
        administrationKind: FormControl<number>;
        administrationRole: FormControl<number>;
        name: FormControl<string>;
        rut: FormControl<string>;
      }>
    >;
  }

  createAdministrator(administrator: Administrator) {
    return this.formBuilder.group({
      administrationKind: [
        administrator?.administrationKind || null,
        [Validators.required],
      ],
      administrationRole: [
        administrator?.administrationRole || null,
        [Validators.required],
      ],
      name: [administrator?.name || null, Validators.required],
      rut: [administrator?.rut || null, [Validators.required, rutValidator]],
    });
  }

  addAdministrator() {
    this.administrators.push(this.createAdministrator(null));
  }

  removeAdministrator(index: number) {
    this.administrators.removeAt(index);
  }

  override syncFormWithStudy({ previousStudy, currentStudy }) {
    if (
      previousStudy?.administrators.length !==
      currentStudy?.administrators.length
    ) {
      const isDisabled = this.administrators.disabled;

      this.administrators.clear({ emitEvent: false });
      this.studyAdministrators.reverse().forEach((administrator) => {
        this.administrators.push(this.createAdministrator(administrator));
      });

      isDisabled && this.administrators.disable({ emitEvent: false });
    }

    const selectedFaculties = this.study.faculties.map((faculty) => {
      const facultyNumber = faculty.faculty;
      const facultyOption = this.facultiesOptions.find(
        ({ value }) => value.faculty === facultyNumber,
      );

      return facultyOption?.value;
    });

    this.studyForm.patchValue(
      {
        administrationKind: this.study.administrationKind,
        faculties: selectedFaculties.reverse(),
        administrators: this.studyAdministrators.reverse(),
      },
      { emitEvent: false },
    );
  }

  override updatePristineStates() {
    const formAdministrators = this.administrators.controls;

    formAdministrators.forEach((control, index) => {
      const studyAdministrator = this.studyAdministrators[index];

      if (!studyAdministrator) {
        return;
      }

      Object.keys(control.controls).forEach((key) => {
        if (control.get(key).value === studyAdministrator[key]) {
          control.get(key).markAsPristine();
        }
      });
    });

    if (formAdministrators.length !== this.studyAdministrators.length) {
      this.administrators.markAsDirty();
    }

    const formFaculties = this.studyForm.get('faculties');
    const studyFacultiesIds = this.study?.faculties.map(
      ({ faculty }) => faculty,
    );
    const facultiesIncluded = formFaculties.value.every(({ faculty }) =>
      studyFacultiesIds.includes(faculty),
    );

    if (
      facultiesIncluded &&
      formFaculties.value.length === studyFacultiesIds.length
    ) {
      formFaculties.markAsPristine();
    } else {
      formFaculties.markAsDirty();
    }

    const formAdministrationKind = this.studyForm.get('administrationKind');
    const studyAdministrationKind = this.study?.administrationKind;

    if (formAdministrationKind.value === studyAdministrationKind) {
      formAdministrationKind.markAsPristine();
    }
  }

  resetAdministratorField(field: string, index: number) {
    const studyShareholder = this.studyAdministrators[index];

    this.administrators.controls[index]
      .get(field)
      .reset(studyShareholder?.[field]);
  }
}

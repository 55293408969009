import { Component, ElementRef, Input, OnChanges } from '@angular/core';

import { Document } from '../../../types';

@Component({
  selector: 'app-html-document',
  template: `<div [innerHTML]="html | safeiframe"></div>`,
})
export class HtmlDocumentComponent implements OnChanges {
  @Input() document: Document;
  @Input() search: string;

  html: string;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges() {
    this.recalculateInnerHtml();
  }

  private getSearchSectionsAndTexts(document: Document, search: string) {
    if (!search) {
      return {
        sections: [],
        texts: [],
      };
    }

    const searchItem = document?.content?.searchItems.find(
      (item) => item.searchId === search,
    );

    return {
      sections: searchItem?.sections || [],
      texts: searchItem?.texts || [],
    };
  }

  private buildSectionsStyle(sections: string[]) {
    const sectionStyles = sections
      .map((section) => `#${section} { background-color: #d4edda; }`)
      .join('');

    return sectionStyles && `<style>${sectionStyles}</style>`;
  }

  private buildSearchedDocumentContent(
    documentContent: string,
    texts: string[],
  ) {
    if (!texts.length) {
      return documentContent;
    }

    return texts.reduce(
      (content, text) =>
        content.replace(
          new RegExp(text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g'),
          `<span style="background-color: #ffeeba;">$&</span>`,
        ),
      documentContent,
    );
  }

  private scrollToFirstSection(sections: string[]) {
    const firstSection = sections[0];

    if (firstSection) {
      const element = this.elementRef.nativeElement.querySelector(
        `#${firstSection}`,
      );

      element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }

  recalculateInnerHtml() {
    const documentContent = this.document?.content?.value;
    const { sections, texts } = this.getSearchSectionsAndTexts(
      this.document,
      this.search,
    );

    if (!sections.length && !texts.length) {
      this.html = documentContent;

      return;
    }

    this.html = `
      ${this.buildSectionsStyle(sections)}
      ${this.buildSearchedDocumentContent(documentContent, texts)}
    `;

    this.scrollToFirstSection(sections);
  }
}

import { formatDate } from '@angular/common';

import { ADMINISTRATION_KINDS } from '../../../../constants/administration-kinds';
import { DATE_FORMAT, DEFAULT_LOCALE } from '../../../../constants/formats';
import {
  DURATION_TERM_TYPES,
  DURATION_TYPES,
} from '../../../../constants/durations';
import { getDisplayName } from '../../../../utils/displays';
import { STUDY_STATUSES } from '../../../../constants/statuses';
import { Study } from '../../../../types';

export type StudyDisplay = Study & {
  administrationDisplay: string;
  durationDisplay: string;
  durationEndDateDisplay: string;
  durationTermTypeDisplay: string;
  equityDisplay: string;
  statusDisplay: string;
  stocksDisplay: string;
};

/**
 * FIXME: Stocks can be decimals, and when summing,
 * things like this can happen:
 * (1.01 + 1.02).toString() = '2.0300000000000002'.
 */

export const getStudyDisplay = (study: Study): StudyDisplay => {
  return {
    ...study,
    administrationDisplay: getDisplayName(
      study.administrationKind,
      ADMINISTRATION_KINDS,
    ),
    durationDisplay: getDisplayName(study.durationType, DURATION_TYPES),
    durationEndDateDisplay: study?.durationEndDate
      ? formatDate(study.durationEndDate, DATE_FORMAT, DEFAULT_LOCALE)
      : '',
    durationTermTypeDisplay: getDisplayName(
      study.durationTermType,
      DURATION_TERM_TYPES,
    ),
    equityDisplay: study.equity.toString(),
    statusDisplay: getDisplayName(study.status, STUDY_STATUSES),
    stocksDisplay: study.associates
      .reduce((acc, associate) => acc + associate.stock, 0)
      .toString(),
  };
};

import { PreStudy } from '../types';

type StudyHistoryTableCell = {
  title: string;
  text: string;
  hasChanged?: boolean;
};

type StudyHistoryTableRow = StudyHistoryTableCell[];

export class StudyHistoryTable {
  public headerRow: StudyHistoryTableRow = [];
  public bodyRows: StudyHistoryTableRow[] = [];

  constructor(preStudies: PreStudy[]) {
    this.headerRow = this.createHeaderRows(preStudies);
    this.bodyRows = this.createBodyRows(preStudies);
  }

  createHeaderRows(preStudies: PreStudy[]) {
    return preStudies.map(({ tipoActuacion, fecha }) => ({
      title: tipoActuacion,
      text: fecha,
    }));
  }

  createBodyRows(preStudies: PreStudy[]) {
    const bussinesNameRow = this.createRow(
      preStudies,
      'Razón Social',
      (preStudy) => {
        return preStudy.razonSocial.valor;
      },
    );

    const addressRow = this.createRow(preStudies, 'Domicilio', (preStudy) => {
      return preStudy.domicilio.valor;
    });

    const durationRow = this.createRow(preStudies, 'Duración', (preStudy) => {
      return preStudy.duracion.valor.texto;
    });

    const objectRow = this.createRow(preStudies, 'Objeto', (preStudy) => {
      return preStudy.objeto.valor;
    });

    const shareHolderRow = this.createRow(
      preStudies,
      'Accionistas',
      (preStudy) => {
        const shareHolderSummaryText = preStudy.accionistas.valor
          .map(({ nombre, rut, participacionDinero }) => {
            return `${nombre}, RUT ${rut} con $${participacionDinero}`;
          })
          .join('; ');

        return `(${preStudy.accionistas.valor.length} Accionista/s) ${shareHolderSummaryText}}`;
      },
    );

    const adminRow = this.createRow(
      preStudies,
      'Administradores',
      (preStudy) => {
        const adminSummaryText = preStudy.administracion.valor.administradores
          .map(({ nombre, rut, tipo, provisorio, comoAdministra }) => {
            return [
              nombre,
              rut,
              tipo,
              provisorio && 'provisorio',
              comoAdministra,
            ]
              .filter(Boolean)
              .join(', ');
          })
          .join('; ');

        return `(${preStudy.administracion.valor.administradores.length} Administrador/es) ${adminSummaryText}`;
      },
    );

    const facultiesRow = this.createRow(
      preStudies,
      'Facultades',
      (preStudy) => {
        const facultiesMap = preStudy.facultades?.valor?.facultades || {};
        const facultiesList = Object.keys(facultiesMap);
        const facultiesListText = facultiesList.join(', ');

        if (!facultiesListText) {
          return 'Sin facultades conocidas';
        }

        return `(${facultiesList.length} Facultad/es) ${facultiesListText}`;
      },
    );

    const exhibitsRow = this.createRow(preStudies, 'Anexos', (preStudy) => {
      const exhibitsTexts = preStudy.anexos?.map((exhibit) => exhibit.texto);

      if (!exhibitsTexts?.length) {
        return 'Sin anexos';
      }

      const exhibitsText = exhibitsTexts.join(', ');

      return `(${exhibitsTexts.length} Anexo/s) ${exhibitsText}`;
    });

    return [
      bussinesNameRow,
      addressRow,
      durationRow,
      objectRow,
      shareHolderRow,
      adminRow,
      facultiesRow,
      exhibitsRow,
    ];
  }

  createRow(
    preStudies: PreStudy[],
    title: string,
    getCellText: (preStudy: PreStudy) => string,
  ): StudyHistoryTableRow {
    const historyTableRow = preStudies.reduce((row, preStudy, i) => {
      if (preStudy.disuelto || preStudy.errorFatal) {
        row.push({
          title,
          text: '',
        });

        return row;
      }

      const cellText = getCellText(preStudy);
      const hasChanged = i > 0 && cellText !== row[i - 1].text;

      row.push({
        title,
        text: cellText,
        hasChanged,
      });

      return row;
    }, []);

    return historyTableRow;
  }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { Session } from './services/auth/session';
import { AUTH_HOME_ROUTE, UNAUTH_HOME_ROUTE } from './constants/routes';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private session: Session,
    private router: Router,
  ) {
    this.session.sessionChanged$.subscribe(({ user }) => {
      const redirectRoute = user ? AUTH_HOME_ROUTE : UNAUTH_HOME_ROUTE;
      this.router.navigate([redirectRoute]);
    });
  }
}

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Session } from './session';

type GenericRequestBody = Record<string, unknown>;
type GenericHttpRequest = HttpRequest<GenericRequestBody>;
type GenericHttpHandledEvent = Observable<HttpEvent<GenericRequestBody>>;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private session: Session) {}

  intercept(
    request: GenericHttpRequest,
    next: HttpHandler,
  ): GenericHttpHandledEvent {
    const isLoginRequest = request.url.includes('/login');
    const isRefreshTokenRequest = request.url.includes('/refresh');

    if (isLoginRequest) {
      return next.handle(request);
    }

    if (isRefreshTokenRequest) {
      return next.handle(request).pipe(
        this.handle401Error(() => {
          return this.closeSessionAndThrow();
        }),
      );
    }

    const requestWithBearer = this.getRequestWithBearer(request);

    if (!requestWithBearer) {
      return this.closeSessionAndThrow();
    }

    return next.handle(requestWithBearer).pipe(
      this.handle401Error(() => {
        return this.refreshToken(request, next);
      }),
    );
  }

  private handle401Error(
    onError: () => GenericHttpHandledEvent,
  ): OperatorFunction<never, HttpEvent<GenericRequestBody>> {
    return catchError<never, GenericHttpHandledEvent>((error) => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return onError();
      }

      return throwError(() => error);
    });
  }

  private refreshToken(
    request: GenericHttpRequest,
    next: HttpHandler,
  ): GenericHttpHandledEvent {
    return this.session.refreshToken().pipe(
      switchMap((didTokenRefresh) => {
        if (!didTokenRefresh) {
          return this.closeSessionAndThrow();
        }

        const requestWithBearer = this.getRequestWithBearer(request);
        return next.handle(requestWithBearer);
      }),
    );
  }

  private getRequestWithBearer(
    request: GenericHttpRequest,
  ): GenericHttpRequest {
    const token = this.session.getAuthToken();

    if (!token) {
      return null;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private closeSessionAndThrow(): Observable<never> {
    this.session.closeSession();

    return throwError(() => new Error('No autorizado'));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonComponent } from './button/button.component';
import { ButtonModule } from './button/button.module';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { ErrorMessageModule } from './error-message/error-message.module';
import { ErrorSnackBarComponent } from './error-message/error-snack-bar/error-snack-bar.component';
import { HeaderContainerComponent } from './header/header-container.component';
import { HeaderModule } from './header/header.module';
import { InputGroupComponent } from './input-group/input-group.component';
import { InputGroupModule } from './input-group/input-group.module';
import { ListBoxComponent } from './list-box/list-box.component';
import { ListBoxModule } from './list-box/list-box.module';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { LoadingSpinnerModule } from './loading-spinner/loading-spinner.module';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginFormModule } from './login-form/login-form.module';
import { SidenavLayoutComponent } from './sidenav-layout/sidenav-layout.component';
import { SidenavLayoutModule } from './sidenav-layout/sidenav-layout.module';
import { StudiesTableModule } from './studies-table/studies-table.module';
import { StudiesTableWrapperComponent } from './studies-table/studies-table-wrapper/studies-table-wrapper.component';
import { StudyDispatchDialogueContainerComponent } from './study-dispatch-dialogue/study-dispatch-dialogue-container.component';
import { StudyDispatchDialogueModule } from './study-dispatch-dialogue/study-dispatch-dialogue.module';
import { StudyDocumentsExplorerContainerComponent } from './study-documents-explorer/study-documents-explorer-container.component';
import { StudyDocumentsExplorerModule } from './study-documents-explorer/study-documents-explorer.module';
import { StudyFormComponent } from './study-form/study-form.component';
import { StudyFormInputComponent } from './study-form-input/study-form-input.component';
import { StudyFormInputModule } from './study-form-input/study-form-input.module';
import { StudyFormModule } from './study-form/study-form.module';
import { StudyFormUnsavedDialogModule } from './study-form-unsaved-dialog/study-form-unsaved-dialog.module';
import { StudyHeaderContainerComponent } from './study-header/study-header-container.component';
import { StudyHeaderModule } from './study-header/study-header.module';
import { StudyHistoryComponent } from './study-history/study-history.component';
import { StudyHistoryModule } from './study-history/study-history.module';
import { StudySectionComponent } from './study-section/study-section.component';
import { StudySectionModule } from './study-section/study-section.module';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    ErrorMessageModule,
    HeaderModule,
    InputGroupModule,
    ListBoxModule,
    LoadingSpinnerModule,
    LoginFormModule,
    SidenavLayoutModule,
    StudiesTableModule,
    StudyDispatchDialogueModule,
    StudyDocumentsExplorerModule,
    StudyFormInputModule,
    StudyFormModule,
    StudyFormUnsavedDialogModule,
    StudyHeaderModule,
    StudyHistoryModule,
    StudySectionModule,
  ],
  providers: [],
  exports: [
    ButtonComponent,
    ErrorMessageComponent,
    ErrorSnackBarComponent,
    HeaderContainerComponent,
    InputGroupComponent,
    ListBoxComponent,
    LoadingSpinnerComponent,
    LoginFormComponent,
    SidenavLayoutComponent,
    StudiesTableWrapperComponent,
    StudyDispatchDialogueContainerComponent,
    StudyDocumentsExplorerContainerComponent,
    StudyFormComponent,
    StudyFormInputComponent,
    StudyHeaderContainerComponent,
    StudyHistoryComponent,
    StudySectionComponent,
  ],
})
export class ComponentsModule {}

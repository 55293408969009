import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Study } from '../../../types';
import { STUDY_ATTRIBUTE_TO_SEARCH_ID } from '../../../constants/document';
import { StudyFormChange } from '../types';

@Component({
  selector: 'app-study-base-section',
  template: '',
})
export class StudyBaseSectionComponent implements OnChanges, OnInit {
  @Input() study: Study;
  @Input() initiallyExpanded = true;
  @Input() disabled: boolean;

  @Output() search: EventEmitter<string> = new EventEmitter();
  @Output() valueChanges: EventEmitter<StudyFormChange> = new EventEmitter();

  studyForm: FormGroup;

  ngOnInit() {
    this.checkRequiredStudy();
    this.subscribeToValueChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.checkRequiredStudy();

    changes['disabled']?.currentValue ? this.disableForm() : this.enableForm();

    const { previousValue, currentValue } = changes['study'] || {};
    const studyHasChanged = previousValue !== currentValue;

    if (studyHasChanged) {
      this.syncFormWithStudy({
        currentStudy: currentValue,
        previousStudy: previousValue,
      });
      this.updatePristineStates();

      this.valueChanges.emit({
        changes: {},
        /**
         * We consider the form valid if it's not invalid,
         * which means it's either valid or pending.
         */
        isValid: !this.studyForm.invalid,
        isUnsaved: this.studyForm.dirty,
      });
    }
  }

  subscribeToValueChanges() {
    this.studyForm.valueChanges.subscribe((partialStudy) => {
      this.updatePristineStates();
      this.valueChanges.emit({
        changes: partialStudy,
        isValid: this.studyForm.valid,
        isUnsaved: this.studyForm.dirty,
      });
    });
  }

  checkRequiredStudy() {
    if (!this.study) {
      throw new Error('StudySectionComponent: study is required');
    }
  }

  resetField(field: string) {
    this.studyForm.get(field).reset(this.study[field]);
  }

  searchField(field: string) {
    this.search.emit(STUDY_ATTRIBUTE_TO_SEARCH_ID[field]);
  }

  disableForm() {
    /**
     * Disabling a form clears its dirty state, so we need to check if the form
     * is dirty before disabling it in order to keep the dirty state.
     * We set emitEvent to false to avoid triggering the valueChanges event.
     */
    const isDirty = this.studyForm.dirty;
    this.studyForm.disable({ emitEvent: false });
    isDirty && this.studyForm.markAsDirty();
  }

  enableForm() {
    this.studyForm.enable({ emitEvent: false });
  }

  updatePristineStates() {
    Object.keys(this.studyForm.controls).forEach((key) => {
      if (this.study[key] === this.studyForm.get(key).value) {
        this.studyForm.get(key).markAsPristine();
      }
    });
  }

  syncFormWithStudy({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currentStudy,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    previousStudy,
  }: {
    currentStudy?: Study;
    previousStudy?: Study;
  } = {}) {
    return;
  }
}

import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';

import { CustomSnackBarComponent } from './custom-snack-bar.component';

@Component({
  selector: 'app-error-snack-bar',
  template: '',
  styleUrls: ['./error-snack-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorSnackBarComponent implements OnChanges {
  @Input() message: string;

  constructor(private _snackBar: MatSnackBar) {}

  ngOnChanges() {
    if (!this.message) {
      this._snackBar.dismiss();

      return;
    }

    this._snackBar.openFromComponent(CustomSnackBarComponent, {
      data: this.message,
      panelClass: 'app-error-snack-bar',
    });
  }
}

import {
  MainExtractionBlock,
  ArticleExtractionBlockArticle,
} from '../../../types';
import { HtmlBuilder } from './html-builder-interface';

const articleHtmlBuilder = (article: ArticleExtractionBlockArticle) => {
  const { articulo, id, subtitulo, texto } = article;

  const isFirstArticle = articulo === 'inicio';

  const title = !isFirstArticle ? `<b>${articulo} ${subtitulo}</b>: ` : '';

  return `<p id="${id}">${title}${texto}</p>`;
};

export const mainHtmlBuilder: HtmlBuilder<MainExtractionBlock> = (block) => {
  const htmlArticles = block.articulos.map(articleHtmlBuilder);

  return htmlArticles.join('');
};

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { StudiesService } from '../../../services/studies/studies.service';
import { StudyHistoryTable } from '../../../types';

@Component({
  selector: 'app-study-history-page',
  styleUrls: ['./study-history-page.component.scss'],
  templateUrl: './study-history-page.component.html',
})
export class StudyHistoryPageComponent implements OnInit {
  studyId: number;
  errorMessage: string;
  studyHistoryTable: StudyHistoryTable;
  loading = false;

  constructor(
    private route: ActivatedRoute,
    private studiesService: StudiesService,
  ) {}

  async ngOnInit() {
    this.studyId = Number(this.route.parent.snapshot.params['id']);
    this.loading = true;

    const { data, error } = await this.studiesService.getStudyHistory(
      this.studyId,
    );

    this.loading = false;
    this.errorMessage = error?.message || '';
    this.studyHistoryTable = data;
  }
}

import {
  SignaturesExtractionBlock,
  SignaturesExtractionBlockSignatue,
} from '../../../types';
import { HtmlBuilder } from './html-builder-interface';

const signatureHtmlBuilder = (signature: SignaturesExtractionBlockSignatue) => {
  const { rut, nombre, firmado, anotacion } = signature;

  const nameEl = `<h3>${nombre}</h3>`;
  const rutEl = `RUT: ${rut}`;
  const nameRutCell = `<td>${nameEl}${rutEl}</td>`;
  const signedCell = `<td>${firmado}</td>`;
  const mainRow = `<tr>${nameRutCell}${signedCell}</tr>`;
  const notesRow = anotacion
    ? `<tr><td colspan="2"><i>${anotacion}</i></td></tr>`
    : '';

  return `${mainRow}${notesRow}`;
};

export const signaturesHtmlBuilder: HtmlBuilder<SignaturesExtractionBlock> = (
  block,
) => {
  const { firmas } = block;
  const htmlSignatures = firmas.map(signatureHtmlBuilder);
  const htmlRows = htmlSignatures.join('<tr style="height: 12px;"></tr>');

  return `<table>${htmlRows}</table>`;
};

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CustomerService } from '../../../services/customer.service';
import { StudyTableSelectOption } from '../types';
import { Customer } from '../../../types';

@Component({
  selector: 'app-studies-table-customer-filter',
  template: `<app-studies-table-select-filter
    label="Cliente"
    [errorMessage]="errorMessage"
    [(value)]="selectedCustomer"
    [options]="customerOptions"
    (valueChange)="customerChange()"
  />`,
})
export class StudiesTableCustomerFilterComponent implements OnInit {
  @Input() selectedCustomer: StudyTableSelectOption['value'];
  @Output() selectedCustomerChange = new EventEmitter<string>();

  customerList: Customer[] = [];
  customerOptions = [];
  errorMessage: string;
  options: StudyTableSelectOption[];

  constructor(private customerService: CustomerService) {}

  ngOnInit() {
    this.loadCustomers();
  }

  setCustomerOptions() {
    const options = this.customerList.map((customer) => ({
      displayName: customer.name,
      value: customer.id.toString(),
    }));

    this.customerOptions = options;
  }

  customerChange() {
    this.selectedCustomerChange.emit(this.selectedCustomer.toString());
  }

  async loadCustomers() {
    this.errorMessage = null;
    const { error, results } = await this.customerService.getCustomers();

    if (error) {
      this.errorMessage = error.message;
      return;
    }

    this.customerList = results;
    this.setCustomerOptions();
  }
}

import { BackendShareholder, BackendStudy } from '../types';
import { Study, StudyShareholder } from '../../../types';

export const backendStudyToStudy = (study: BackendStudy): Study => {
  return {
    ...study,
    associates: study.associates?.map(backendShareholderToStudyShareholder),
  };
};

export const backendShareholderToStudyShareholder = (
  backendShareholder: BackendShareholder,
): StudyShareholder => {
  return {
    ...backendShareholder,
    stock: Number(backendShareholder.stock),
  };
};

export const studyShareholderToBackendShareholder = (
  studyShareholder: StudyShareholder & { study: number },
): Omit<BackendShareholder, 'id'> => {
  return {
    ...studyShareholder,
    stock: studyShareholder.stock.toString(),
  };
};

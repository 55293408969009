import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getYears } from '../../utils/dates';

@Component({
  selector: 'app-studies-table-year-filter',
  templateUrl: './studies-table-year-filter.component.html',
  styleUrls: ['./studies-table-year-filter.component.scss'],
})
export class StudiesTableYearFilterComponent {
  @Input() selectedYear: string;
  @Output() selectedYearChange = new EventEmitter<string>();

  years: string[] = getYears();

  selectionChange() {
    this.selectedYearChange.emit(this.selectedYear);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Dispatch } from '../types';
import { StudiesService } from '../services/studies/studies.service';
import { LogsService } from '../services/logs/logs.service';
import { DISPATCH_STATUS } from '../constants/statuses';

/**
 * TODO: https://equipolegalbot.atlassian.net/browse/LB-925
 * Create types for:
 * - Study
 * - PreStudy
 * - Dispatch
 * - Customer
 * - SearchFilter
 * - StudyStatus
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type Study = any;
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type PreStudy = any;
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type StudyStatus = any;

@Component({
  selector: 'app-resultado',
  templateUrl: './resultado.component.html',
})
export class ResultadoComponent implements OnInit {
  cargando = true;
  id: string;

  estudio: Study;
  lastDispatch: Dispatch;
  estado: StudyStatus;
  editMessage: string;
  isEditSuccess: boolean;
  status: number;
  sglCode: string;

  prestudios: PreStudy[] = [];
  prestudio_seleccionado: PreStudy;

  customer: string;

  loaders = {
    studies: false,
  };
  search: string;
  showingExtraction = false;
  showRightPanel = true;
  selectedActuationCVE: string;
  isDispatchOpen = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private studiesService: StudiesService,
    private logsService: LogsService,
  ) {
    this.id = this.route.snapshot.params['id'];

    if (!this.id) {
      this.router.navigate(['']);

      return;
    }
  }

  get studyId(): number {
    return Number(this.id);
  }

  ngOnInit() {
    try {
      this.obtenerEstudio();
    } catch (error) {
      console.log(error);
    }
  }

  async obtenerEstudio() {
    this.cargando = true;
    const { data, error } = await this.studiesService.getFullStudy(
      Number(this.id),
    );
    this.cargando = false;

    if (error || !data.estudio) {
      return;
    }

    const { estudio, estado, sglCode, status, lastDispatch } = data;
    this.customer = estudio.customer;

    if (estudio.prestudios) {
      this.prestudios = estudio.prestudios.filter(this.sinErrorFatal);
      this.actualizarPrestudio(this.prestudios[this.prestudios.length - 1]);
    }

    this.estudio = estudio;
    this.lastDispatch = lastDispatch;
    this.estado = estado;
    this.sglCode = sglCode;
    this.status = status;
    this.isDispatchOpen = lastDispatch?.status === DISPATCH_STATUS.IN_STUDY;
  }

  sinErrorFatal(prestudio: PreStudy) {
    return !prestudio.errorFatal;
  }

  seleccionarPrestudio(prestudio: PreStudy) {
    this.logsService.logAction('preStudySelected', {
      studyId: this.id,
      preStudyId: prestudio.id,
    });

    this.actualizarPrestudio(prestudio);
  }

  actualizarPrestudio(prestudio: PreStudy) {
    this.prestudio_seleccionado = prestudio;
    this.showRightPanel = !this.prestudio_seleccionado.revisionManual;
    this.showingExtraction = this.showingExtraction && this.showRightPanel;
    this.selectedActuationCVE = this.prestudio_seleccionado.cve;
    this.search = null;
  }

  goToHistory() {
    this.logsService.logAction('goToHistory', {
      studyId: this.id,
    });

    this.router.navigate(['../../', 'study', this.id, 'history']);
  }

  busquedaTexto(searchId: string) {
    this.logsService.logAction('propertySearch', {
      studyId: this.id,
      propertySearchObjectType: searchId,
    });

    if (!this.showingExtraction) {
      this.showExtraction();
    }

    this.search = searchId;
  }

  showExtraction() {
    this.logsService.logAction('showExtraction', {
      studyId: this.id,
    });

    this.showingExtraction = !this.showingExtraction;
  }

  selectedTabChange(tabName: string) {
    this.logsService.logAction('tabChange', {
      studyId: this.id,
      tabChangetabName: tabName,
    });
  }

  textCopied({ text, type }: { text: string; type: string }) {
    this.logsService.logAction('textCopied', {
      studyId: this.id,
      textCopiedtext: text,
      textCopiedtype: type,
    });
  }
}

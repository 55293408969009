import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { ButtonModule } from '../button/button.module';

import { RutMaskDirective } from './directives/rut-mask.directive';
import { StudyFormInputComponent } from './study-form-input.component';

@NgModule({
  declarations: [RutMaskDirective, StudyFormInputComponent],
  imports: [
    BrowserAnimationsModule,
    ButtonModule,
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  exports: [RutMaskDirective, StudyFormInputComponent],
})
export class StudyFormInputModule {}

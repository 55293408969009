<app-study-section
  [initiallyExpanded]="initiallyExpanded"
  [isInvalid]="studyForm.invalid"
  [isUnsaved]="studyForm.dirty"
  title="Duración"
>
  <app-study-form-input
    (undo)="resetField('durationType')"
    (search)="searchField('duration')"
    [control]="studyForm.controls.durationType"
    label="Duración"
    type="select"
    [options]="durationOptions"
  />
  <app-study-form-input
    *ngIf="durationIsDefinite"
    (undo)="resetField('durationEndDate')"
    (search)="searchField('duration')"
    [control]="studyForm.controls.durationEndDate"
    label="Fecha de Término"
    type="date"
  />
  <app-study-form-input
    *ngIf="durationIsRenewable"
    (undo)="resetField('durationTerm')"
    (search)="searchField('duration')"
    [control]="studyForm.controls.durationTerm"
    label="Plazo"
    type="number"
  />
  <app-study-form-input
    *ngIf="durationIsRenewable"
    (undo)="resetField('durationTermType')"
    (search)="searchField('duration')"
    [control]="studyForm.controls.durationTermType"
    label="Tipo Plazo"
    type="select"
    [options]="durationTermOptions"
  />
</app-study-section>

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Dispatch } from '../../types';

@Component({
  selector: 'app-base-study-dispatch-dialogue',
  templateUrl: './study-dispatch-dialogue.component.html',
  styleUrls: ['./study-dispatch-dialogue.component.scss'],
})
export class StudyDispatchDialogueComponent {
  @Input() dispatch: Dispatch;
  @Input() disabled: boolean;
  @Input() isSaving: boolean;
  @Input() errorMessage: string;
  @Input() failedRules: string[];
  @Output() save: EventEmitter<Partial<Dispatch>> = new EventEmitter();

  saveHandler(partialDispatch: Partial<Dispatch>) {
    this.save.emit(partialDispatch);
  }
}

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';

import { StudyFormUnsavedDialogComponent } from './study-form-unsaved-dialog.component';

@NgModule({
  declarations: [StudyFormUnsavedDialogComponent],
  imports: [MatDialogModule, MatButtonModule],
  exports: [StudyFormUnsavedDialogComponent],
})
export class StudyFormUnsavedDialogModule {}

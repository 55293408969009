import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-base-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() navItems: {
    name: string;
    path: string;
  }[];

  @Output() logout = new EventEmitter<void>();

  logoutHandler() {
    this.logout.emit();
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-studies-table-message',
  templateUrl: './studies-table-message.component.html',
  styleUrls: ['./studies-table-message.component.scss'],
})
export class StudiesTableMessageComponent {
  @Input() message: string;
}

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ButtonModule } from '../button/button.module';
import { StudyHeaderComponent } from './study-header.component';
import { StudyHeaderContainerComponent } from './study-header-container.component';

@NgModule({
  declarations: [StudyHeaderComponent, StudyHeaderContainerComponent],
  imports: [
    BrowserAnimationsModule,
    ButtonModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    RouterModule,
    MatIconModule,
  ],
  exports: [StudyHeaderContainerComponent],
})
export class StudyHeaderModule {}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { StudiesService } from '../../services/studies/studies.service';
import { Study } from '../../types';
import { StudyPartialEdit } from './types';
import { StudyPartialEditDialogComponent } from './study-partial-edit-dialog.component';
import { STUDY_EDIT_FIELD, STUDY_EDIT_FIELD_NAME } from './constants';
import { STUDY_STATUSES } from '../../constants/statuses';

@Component({
  selector: 'app-study-partial-edit-dialog',
  standalone: true,
  template: `<app-base-study-partial-edit-dialog
    [data]="data"
    [errorMessage]="errorMessage"
    [loading]="loading"
    [saveIsDisabled]="!shouldSave"
    (closeDialog)="close()"
    (save)="editStudy()"
  >
  </app-base-study-partial-edit-dialog>`,
  imports: [StudyPartialEditDialogComponent],
})
export class StudyPartialEditDialogContainerComponent implements OnInit {
  errorMessage: string;
  studyStatuses = STUDY_STATUSES;
  studyEditField = STUDY_EDIT_FIELD;
  loading: boolean;
  originalField: Partial<Study>;

  constructor(
    public dialogRef: MatDialogRef<StudyPartialEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StudyPartialEdit,
    private studiesService: StudiesService,
  ) {}

  ngOnInit(): void {
    this.originalField = this.data.study[this.fieldName];
  }

  get fieldName() {
    return STUDY_EDIT_FIELD_NAME[this.data.field];
  }

  get shouldSave() {
    const value = this.data.study[this.fieldName];

    return this.originalField !== value;
  }

  async editStudy() {
    this.loading = true;
    this.errorMessage = '';

    const value = this.data.study[this.fieldName];
    const param = {
      [this.fieldName]: value,
    };

    const { error } = await this.studiesService.updateStudy(
      this.data.study.id,
      param,
    );

    this.loading = false;

    if (error) {
      this.errorMessage = error.message;
    } else {
      this.dialogRef.close({
        fieldName: this.fieldName,
        fieldValue: value,
      });
    }
  }

  close() {
    this.errorMessage = '';
    this.dialogRef.close();
  }
}

import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { STUDY_COMPANY_KINDS } from '../../../../constants/study-company-kinds';

import { StudyBaseSectionComponent } from '../study-base-section.component';

@Component({
  selector: 'app-study-main-section',
  templateUrl: './study-main-section.component.html',
  styleUrls: ['./study-main-section.component.scss'],
})
export class StudyMainSectionComponent extends StudyBaseSectionComponent {
  companyKindOptions = STUDY_COMPANY_KINDS.map(({ value, displayName }) => ({
    value,
    label: displayName,
  }));

  override studyForm = new FormGroup({
    businessName: new FormControl(null, [Validators.required]),
    address: new FormControl(null, [Validators.required]),
    corporatePurpose: new FormControl(null, [Validators.required]),
    companyKind: new FormControl<number>(null, [Validators.required]),
    fantasyName: new FormControl(),
  });

  override syncFormWithStudy() {
    this.studyForm.patchValue(
      {
        businessName: this.study.businessName,
        address: this.study.address,
        corporatePurpose: this.study.corporatePurpose,
        companyKind: this.study.companyKind,
        fantasyName: this.study.fantasyName,
      },
      { emitEvent: false },
    );
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StudyPageService {
  public search$ = new Subject<{ searchId: string }>();

  public search(searchId: string): void {
    this.search$.next({ searchId });
  }
}

import { PaginatedResponse, SimpleResponse } from '../types';

export const createErrorResponse = (error: Error): SimpleResponse => ({
  error,
  data: null,
});

export const createErrorPaginatedResponse = (
  error: Error,
): PaginatedResponse => ({
  error,
  count: 0,
  next: null,
  previous: null,
  results: null,
});

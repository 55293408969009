<app-header></app-header>
<div data-test-id="studies-table" class="studies-container">
  <app-studies-table-wrapper
    [tableColumns]="tableColumns"
    [searchLabels]="textFilterLabels"
    [selectFilters]="selectFilters"
    [defaultSearchLabel]="defaultTextFilterLabel"
    [getStudies]="getStudies"
  />
</div>

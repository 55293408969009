import { Exhibit } from '../../types';

export const createExhibitsHtmlBlock = (exhibits: Exhibit[] = []) => {
  if (!exhibits.length) {
    return '';
  }

  const exhibitLinks = exhibits.map(
    (exhibit) =>
      `<li><a href="${exhibit.url}" target="_blank">${exhibit.texto}</a></li>`,
  );

  return `
    <div id="exhibits-list">
      <h2>Anexos:</h2>
      <ul>${exhibitLinks.join('')}</ul>
    </div>
  `;
};

<div class="login-page">
  <app-login-form
    (login)="onSubmit()"
    [(password)]="password"
    [(username)]="username"
    [disabled]="!username || !password"
    [loading]="loading"
    [message]="message"
  />
</div>

<!-- tabs navegación principal -->
<ul class="nav nav-tabs navegacion-principal">
  <li class="nav-item" *ngFor="let t of tabs_navegacion; let i = index">
    <button
      class="nav-link active"
      [ngClass]="{
        active: tab_navegacion_seleccionado === i,
        disabled: !t.activo
      }"
      (click)="seleccionarTab(i)"
    >
      {{ t.nombre }}
      <span *ngIf="t.numero > 0">({{ t.numero }})</span>
      <i *ngIf="t.alerta" class="alerta fas fa-exclamation-circle"></i>
    </button>
  </li>
  <li *ngIf="isDispatchOpen" class="nav-item new-form">
    <a class="nav-link" [href]="'/study/' + id">
      <i class="fa fa-arrow-up"></i>
      Editar estudio
    </a>
  </li>
</ul>

<!-- contenido principal -->
<div class="contenido-principal" *ngIf="p && !p.disuelto && !p.errorFatal">
  <!-- RESUMEN -->
  <div
    class="contenido"
    *ngIf="tabs_navegacion[tab_navegacion_seleccionado].nombre === 'Resumen'"
  >
    <!-- alerta articulos repetidos -->
    <div class="alerta" *ngIf="!p.articulosRepetidos.ok">
      <p *ngFor="let m of p.articulosRepetidos.mensajes">
        <i class="fas fa-exclamation-circle"></i> {{ m }}
      </p>
    </div>

    <div class="resumen">
      <table>
        <tr>
          <th>Estado</th>
          <td>
            {{ statusDisplay }}
            <button (click)="openDialog(STUDY_EDIT_FIELD.STATUS)">
              <i class="far fa-edit"></i>
            </button>
          </td>
        </tr>
        <tr>
          <th>SGL</th>
          <td>
            {{ sglCode }}
            <button (click)="openDialog(STUDY_EDIT_FIELD.SGL_CODE)">
              <i class="far fa-edit"></i>
            </button>
          </td>
        </tr>
      </table>
    </div>

    <!-- info básica del documento -->
    <div class="resumen">
      <table>
        <tr>
          <th>Fecha del Documento</th>
          <td>
            {{ formatearFecha(p.fecha) }}
            <button (click)="copiar(formatearFecha(p.fecha), 'fecha')">
              <i class="far fa-copy"></i>
            </button>
          </td>
        </tr>
        <tr>
          <th>Tipo Documento</th>
          <td>{{ p.tipoActuacion }}</td>
        </tr>
        <tr>
          <th>Tipo Sociedad</th>
          <td>{{ p.tipoSociedad | uppercase }}</td>
        </tr>
        <tr>
          <th>RUT</th>
          <td>
            {{ p.rut }}
            <button (click)="copiar(p.rut, 'rut')">
              <i class="far fa-copy"></i>
            </button>
          </td>
        </tr>
        <tr>
          <th>Ley No. 20659</th>
          <td>
            {{ obtenerMensajeLey() }}
            <button (click)="copiar(obtenerMensajeLey(), 'mensajeLey')">
              <i class="far fa-copy"></i>
            </button>
          </td>
        </tr>
      </table>
    </div>

    <!-- info específica de la sociedad -->
    <!-- razon social -->
    <div class="contenido-item">
      <div class="titulo">
        <span class="nombre">Razón Social:</span>
        <span class="botones">
          <button *ngIf="!p.revisionManual" (click)="search('businessName')">
            <i class="fas fa-search"></i>
          </button>
          <button
            (click)="copiar(p.razonSocial.valor.toUpperCase(), 'razonSocial')"
          >
            <i class="far fa-copy"></i>
          </button>
        </span>
      </div>

      <div class="extraccion">
        {{ p.razonSocial.valor | uppercase }}
      </div>
      <div *ngIf="!p.razonSocial.ok" class="alerta">
        <i class="fas fa-exclamation-circle"></i>
        <span *ngFor="let m of p.razonSocial.mensajes">
          {{ m }}
        </span>
      </div>
    </div>

    <!-- fantasia -->
    <div class="contenido-item" *ngIf="p.nombreFantasia.ok">
      <div class="titulo">
        <span>Nombre Fantasía:</span>
        <span class="botones">
          <button *ngIf="!p.revisionManual" (click)="search('fantasyName')">
            <i class="fas fa-search"></i>
          </button>
          <button
            (click)="
              copiar(p.nombreFantasia.valor.toUpperCase(), 'nombreFantasia')
            "
          >
            <i class="far fa-copy"></i>
          </button>
        </span>
      </div>
      <div class="extraccion">
        {{ p.nombreFantasia.valor || "- -" | uppercase }}
      </div>
    </div>

    <!-- domicilio -->
    <div class="contenido-item">
      <div class="titulo">
        <span>Domicilio:</span>
        <span class="botones">
          <button *ngIf="!p.revisionManual" (click)="search('address')">
            <i class="fas fa-search"></i>
          </button>
          <button (click)="copiar(p.domicilio.valor, 'domicilio')">
            <i class="far fa-copy"></i>
          </button>
        </span>
      </div>
      <div class="extraccion">
        {{ p.domicilio.valor }}
      </div>
      <div *ngIf="!p.domicilio.ok" class="alerta">
        <i class="fas fa-exclamation-circle"></i>
        <span *ngFor="let m of p.domicilio.mensajes">
          {{ m }}
        </span>
      </div>
    </div>

    <!-- duracion -->
    <div class="contenido-item">
      <div class="titulo">
        <span>Duración:</span>
        <span class="botones">
          <button *ngIf="!p.revisionManual" (click)="search('duration')">
            <i class="fas fa-search"></i>
          </button>
          <button (click)="copiar(p.duracion.valor.texto, 'duracion')">
            <i class="far fa-copy"></i>
          </button>
        </span>
      </div>
      <div class="extraccion">
        <div class="duracion-texto">
          {{ p.duracion.valor.texto }}
        </div>
      </div>
      <div *ngIf="!p.duracion.ok" class="alerta">
        <i class="fas fa-exclamation-circle"></i>
        <span *ngFor="let m of p.duracion.mensajes">
          {{ m }}
        </span>
      </div>
    </div>

    <!-- objeto -->
    <div class="contenido-item">
      <div class="titulo">
        <span>Objeto:</span>
        <span class="botones">
          <button
            *ngIf="!p.revisionManual"
            (click)="search('corporatePurpose')"
          >
            <i class="fas fa-search"></i>
          </button>
          <button (click)="copiar(p.objeto.valor, 'objeto')">
            <i class="far fa-copy"></i>
          </button>
        </span>
      </div>
      <div class="extraccion">
        {{ p.objeto.valor }}
      </div>
      <div *ngIf="!p.objeto.ok" class="alerta">
        <i class="fas fa-exclamation-circle"></i>
        <span *ngFor="let m of p.objeto.mensajes">
          {{ m }}
        </span>
      </div>
    </div>

    <!-- administracion -->
    <div class="contenido-item">
      <div class="titulo">
        <span>Administracion:</span>
        <span class="botones">
          <button *ngIf="!p.revisionManual" (click)="search('administration')">
            <i class="fas fa-search"></i>
          </button>
          <button
            (click)="
              copiar(
                p.administracion.frasesReferencia,
                'administracionFrasesReferencia'
              )
            "
          >
            <i class="far fa-copy"></i>
          </button>
        </span>
      </div>
      <div class="extraccion">
        {{ p.administracion.frasesReferencia }}
      </div>
      <div *ngIf="!p.administracion.ok" class="alerta">
        <i class="fas fa-exclamation-circle"></i>
        <span *ngFor="let m of p.administracion.mensajes">
          {{ m }}
        </span>
      </div>
    </div>
  </div>

  <!-- SOCIOS -->
  <div
    class="contenido"
    *ngIf="tabs_navegacion[tab_navegacion_seleccionado].nombre === 'Socios'"
  >
    <!-- alerta-->
    <div class="alerta" *ngIf="!p.capital.ok || !p.accionistas.ok">
      <p *ngFor="let m of p.capital.mensajes">
        <i class="fas fa-exclamation-circle"></i> {{ m }}
      </p>
      <p *ngFor="let m of p.accionistas.mensajes">
        <i class="fas fa-exclamation-circle"></i> {{ m }}
      </p>
    </div>

    <!-- tabla accionistas -->
    <table class="tabla-accionistas table table-bordered">
      <thead>
        <tr>
          <th>Socios</th>
          <th>RUT</th>
          <th>Acciones</th>
          <th>Capital</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let a of p.accionistas.valor">
          <td class="upper">
            <span>{{ a.nombre }}</span>
            <span class="botones">
              <button
                *ngIf="!p.revisionManual"
                (click)="search('shareholders')"
              >
                <i class="fas fa-search"></i>
              </button>
              <button (click)="copiar(a.nombre, 'nombreAccionista')">
                <i class="far fa-copy"></i>
              </button>
            </span>
          </td>
          <td class="rut">
            <span>{{ a.rut }}</span>
            <span class="botones">
              <button (click)="copiar(a.rut, 'rutAccionista')">
                <i class="far fa-copy"></i>
              </button>
            </span>
          </td>
          <td>
            <span>{{ formatearNumero(a.participacion) }}</span>
            <span class="botones">
              <button
                (click)="copiar(a.participacion, 'participacionAccionista')"
              >
                <i class="far fa-copy"></i>
              </button>
            </span>
          </td>
          <td>
            <span>{{ formatearNumero(a.participacionDinero) }}</span>
            <span class="botones">
              <button
                (click)="copiar(a.participacionDinero, 'dineroAccionista')"
              >
                <i class="far fa-copy"></i>
              </button>
            </span>
          </td>
        </tr>
        <tr>
          <td><b>Capital Total:</b></td>
          <td colspan="3" class="derecho">
            <span
              ><b>{{ formatearNumero(p.capital.valor) }}</b></span
            >
            <span class="botones">
              <button *ngIf="!p.revisionManual" (click)="search('equity')">
                <i class="fas fa-search"></i>
              </button>
              <button
                (click)="
                  copiar(quitarPuntos(p.capital.valor), 'capitalAccionista')
                "
              >
                <i class="far fa-copy"></i>
              </button>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <h3 class="listado-socios">Listado Socios</h3>
    <ul class="lista-socios">
      <li *ngFor="let a of p.accionistas.valor">{{ a.nombre }}</li>
    </ul>
  </div>

  <!-- ADMINISTRACION -->
  <div
    class="contenido"
    *ngIf="
      tabs_navegacion[tab_navegacion_seleccionado].nombre === 'Administración'
    "
  >
    <!-- alerta administración -->
    <div class="alerta" *ngIf="!p.administracion.ok">
      <p *ngFor="let m of p.administracion.mensajes">
        <i class="fas fa-exclamation-circle"></i> {{ m }}
      </p>
    </div>

    <!-- tabla administradores -->
    <table class="tabla-administradores table table-bordered">
      <thead>
        <tr>
          <th>Administradores</th>
          <th>RUT</th>
          <th>Tipo</th>
          <th>Administración</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let a of p.administracion.valor.administradores">
          <td class="upper">
            <span>{{ a.nombre }}</span>
            <span class="botones">
              <button
                *ngIf="!p.revisionManual"
                (click)="search('administration')"
              >
                <i class="fas fa-search"></i>
              </button>
              <button (click)="copiar(a.nombre, 'nombreAdministrador')">
                <i class="far fa-copy"></i>
              </button>
            </span>
          </td>
          <td class="rut">
            <span>{{ a.rut }}</span>
            <span class="botones">
              <button (click)="copiar(a.rut, 'rutAdministrador')">
                <i class="far fa-copy"></i>
              </button>
            </span>
          </td>
          <td>
            <span>{{ a.tipo }} <i *ngIf="a.provisorio">provisorio</i></span>
          </td>
          <td>
            <span>{{ a.comoAdministra }}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Observaciones -->
    <div
      class="contenido-item"
      *ngIf="p.revisionManual && p.administracion.valor.observacion"
    >
      <div class="titulo">
        <span>Observaciones:</span>
        <span class="botones">
          <button
            (click)="
              copiar(
                p.administracion.valor.observacion,
                'observacionAdmionistracion'
              )
            "
          >
            <i class="far fa-copy"></i>
          </button>
        </span>
      </div>
      <div class="extraccion">
        {{ p.administracion.valor.observacion }}
      </div>
    </div>

    <!-- alerta facultades -->
    <div class="alerta" *ngIf="!p.facultades.ok">
      <p *ngFor="let m of p.facultades.mensajes">
        <i class="fas fa-exclamation-circle"></i> {{ m }}
      </p>
    </div>

    <!-- tabla facultades -->
    <div
      class="texto-inicio-facultados"
      *ngIf="p.facultades.valor.inicio && p.facultades.valor.inicio.length > 2"
    >
      {{ p.facultades.valor.inicio }}
    </div>
    <table
      class="tabla-facultades-planas table table-bordered"
      *ngIf="
        p.facultades.valor.facultades &&
        objectKeys(p.facultades.valor.facultades).length > 0
      "
    >
      <thead>
        <tr>
          <th>Facultad</th>
          <th>Fuente</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let f of facultades_mapeadas"
          [ngClass]="{ 'no-seleccionada': f.m.length === 0 }"
        >
          <td class="titulo-facultad">{{ f.n }}.- {{ f.f }}</td>
          <td *ngIf="!p.revisionManual" class="texto-facultad">
            <button *ngFor="let fm of f.m" (click)="search('faculties')">
              {{ fm }}
            </button>
          </td>
          <td *ngIf="p.revisionManual" class="texto-facultad">
            <button class="no-link" *ngFor="let fm of f.m">{{ fm }}</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- ADJUNTOS -->
  <div
    class="contenido"
    *ngIf="tabs_navegacion[tab_navegacion_seleccionado].nombre === 'Adjuntos'"
  >
    <div *ngFor="let a of p.anexos" class="card adjunto-item">
      <div class="card-body">
        <a href="{{ a.url }}" target="_blank">{{ a.texto }}</a>
      </div>
    </div>
  </div>
</div>

<!-- caso disolución -->
<div class="contenido-principal" *ngIf="p && p.disuelto">
  <div class="alert alert-danger" role="alert">
    La sociedad ha sido disuelta o emigrada al sistema antiguo
  </div>
</div>

<!-- caso error fatal -->
<div class="contenido-principal" *ngIf="p && p.errorFatal">
  <div class="alert alert-danger" role="alert">
    El sistema legalbot no pudo extraer el texto en PDF de la actuación debido a
    un error interno del sistema.
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { StudiesService } from 'src/app/services/studies/studies.service';
import { StudyPageService } from '../study-page.service';
import { STUDY_ATTRIBUTE_TO_SEARCH_ID } from 'src/app/constants/document';

import { getStudyDisplay, StudyDisplay } from './utils/get-study-display';

@Component({
  selector: 'app-study-info-page',
  styleUrls: ['./study-info-page.component.scss'],
  templateUrl: './study-info-page.component.html',
})
export class StudyInfoPageComponent implements OnInit {
  errorMessage: string;
  loading = false;
  study: StudyDisplay;

  constructor(
    private route: ActivatedRoute,
    private studiesService: StudiesService,
    private studyPageService: StudyPageService,
  ) {}

  ngOnInit() {
    const studyId = Number(this.route.parent.snapshot.params['id']);
    this.loadStudy(studyId);
  }

  async loadStudy(studyId: number) {
    this.loading = true;

    const { error, data } = await this.studiesService.getStudy(studyId);

    this.loading = false;
    this.errorMessage = error?.message || '';
    this.study = data && getStudyDisplay(data);
  }

  searchField(field: string) {
    this.studyPageService.search(STUDY_ATTRIBUTE_TO_SEARCH_ID[field]);
  }
}

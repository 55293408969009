import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-list-box',
  templateUrl: './list-box.component.html',
  styleUrls: ['./list-box.component.scss'],
})
export class ListBoxComponent {
  @Input() options: {
    label: string;
    value: unknown;
  }[] = [];
  @Input() control: FormControl;
  @Input() title: string;

  selectAll() {
    const options = this.options.map((option) => option.value);
    this.control.setValue(options);
  }
}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Shareholder } from 'src/app/types';

export const EquityValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const shareHolders: [Shareholder] = control.get('associates').value;
  const equityControl = control.get('equity');
  const totalEquity: number = equityControl.value;

  const shareHoldersEquity = shareHolders.reduce(
    (acc: number, shareholder: Shareholder) => (acc += shareholder.equity),
    0,
  );
  const isValid = totalEquity === shareHoldersEquity;

  if (isValid) {
    equityControl.setErrors(null);
    return null;
  }

  equityControl.setErrors({
    message: `El capital total no coincide con la suma del capital de cada socio (${shareHoldersEquity})`,
  });

  return {
    equity: true,
  };
};

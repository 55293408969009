<div class="app-study-read-only-section-faculties-table">
  <span class="label">Facultades</span>
  <table mat-table [dataSource]="faculties">
    <ng-container matColumnDef="faculty">
      <th mat-header-cell *matHeaderCellDef>Facultad</th>
      <td mat-cell *matCellDef="let element">
        <div class="nowrap">
          {{ element.message }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="restrictions">
      <th mat-header-cell *matHeaderCellDef>Restricciones</th>
      <td mat-cell *matCellDef="let element">
        <ul>
          @for (restriction of element.restriction; track $index) {
            <li>{{ restriction.value }} {{ restriction.unit }}</li>
          }
        </ul>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StudyHistoryComponent } from './study-history.component';

@NgModule({
  declarations: [StudyHistoryComponent],
  exports: [StudyHistoryComponent],
  imports: [CommonModule],
})
export class StudyHistoryModule {}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { DURATION_TYPE } from '../../../../../constants/durations';

export const RequiredDurationValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const durationEndDateControl = control.get('durationEndDate');
  const durationTermControl = control.get('durationTerm');
  const durationTermTypeControl = control.get('durationTermType');
  const durationTypeControl = control.get('durationType');

  const durationEndDate = durationEndDateControl.value;
  const durationTerm = durationTermControl.value;
  const durationTermType = durationTermTypeControl.value;
  const durationType = durationTypeControl.value;

  durationEndDateControl.setErrors(null);
  durationTermControl.setErrors(null);
  durationTermTypeControl.setErrors(null);

  if (durationType === DURATION_TYPE.DEFINITE && !durationEndDate) {
    durationEndDateControl.setErrors({ required: true });

    return { required: true };
  }

  if (
    durationType === DURATION_TYPE.RENEWABLE &&
    !(durationTerm && durationTermType)
  ) {
    if (!durationTerm) {
      durationTermControl.setErrors({ required: true });
    }

    if (!durationTermType) {
      durationTermTypeControl.setErrors({ required: true });
    }

    return { required: true };
  }

  return null;
};

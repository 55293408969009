import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-study-section',
  styleUrls: ['./study-section.component.scss'],
  templateUrl: './study-section.component.html',
})
export class StudySectionComponent {
  @Input() errorMessage: string;
  @Input() set initiallyExpanded(value: boolean) {
    if (this._isExpanded === undefined) {
      this._isExpanded = value;
    }
  }
  @Input() isInvalid: boolean;
  @Input() isSaving: boolean;
  @Input() isUnsaved: boolean;
  @Input() showActions: boolean;
  @Input() title: string;

  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<void>();

  _isExpanded: boolean;
}

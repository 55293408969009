import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

const LOGIN_URL = `${environment.api}/v2/login/`;

type AuthResponse = {
  access: string | null;
  refresh: string | null;
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  public logIn(username: string, password: string): Observable<AuthResponse> {
    const body = {
      username,
      password,
    };

    return this.http.post<AuthResponse>(LOGIN_URL, body);
  }
}

import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { StudySectionModule } from '../study-section/study-section.module';

import { ButtonModule } from '../button/button.module';
import { InputGroupComponent } from './input-group.component';

@NgModule({
  declarations: [InputGroupComponent],
  imports: [ButtonModule, CommonModule, MatButtonModule, StudySectionModule],
  exports: [InputGroupComponent],
})
export class InputGroupModule {}

import { getDisplayName } from '../../../utils/displays';
import { DISPATCH_STATUSES, STUDY_STATUSES } from 'src/app/constants/statuses';
import { StudiesTableDispatch, StudiesTableStudy } from '../types';

export const enrichDispatch = (
  dispatch: StudiesTableDispatch,
): StudiesTableDispatch => {
  if (!dispatch) {
    return null;
  }

  return {
    ...dispatch,
    statusDisplay: getDisplayName(dispatch.status, DISPATCH_STATUSES),
  };
};

export const enrichStudies = (
  studies: StudiesTableStudy[],
): StudiesTableStudy[] => {
  return studies.map((study) => {
    return {
      ...study,
      statusDisplay: getDisplayName(study.status, STUDY_STATUSES),
      approvedDisplay: customApprovedName(study),
      lastDispatch: enrichDispatch(study.lastDispatch),
    };
  });
};

export const customApprovedName = (study: StudiesTableStudy) => {
  if (study.isPreApproved) {
    return 'Aprobación Legal Automática';
  }

  if (study.isApproved) {
    return 'Aprobación Legal';
  }

  if (study.isApproved === null) {
    return 'Sin Aprobación Legal Automática';
  }

  if (study.isApproved === false) {
    return 'Rechazo Legal';
  }

  return '';
};

export type CamelToSnake<
  T extends string,
  P extends string = '',
> = string extends T
  ? string
  : T extends `${infer C0}${infer R}`
    ? CamelToSnake<
        R,
        `${P}${C0 extends Lowercase<C0> ? '' : '_'}${Lowercase<C0>}`
      >
    : P;

export const camelToSnake = (str: string): CamelToSnake<string> => {
  return str.replace(
    /[A-Z]/g,
    (uppercaseLetter) => `_${uppercaseLetter.toLowerCase()}`,
  );
};

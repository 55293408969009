import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { AdministrationGroupFaculty } from 'src/app/types';

const SNACKBAR_MESSAGE = 'Copiado';

@Component({
  selector: 'app-study-read-only-section-faculties-table',
  standalone: true,
  imports: [
    ClipboardModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
  ],
  templateUrl: './study-read-only-section-faculties-table.component.html',
  styleUrl: './study-read-only-section-faculties-table.component.scss',
})
export class StudyReadOnlySectionFacultiesTableComponent {
  @Input() faculties: AdministrationGroupFaculty[];
  @Output() search = new EventEmitter<void>();

  displayedColumns = ['faculty', 'restrictions'];

  constructor(private snackbar: MatSnackBar) {}

  textCopied() {
    this.snackbar.open(SNACKBAR_MESSAGE, '', { duration: 2000 });
  }
}

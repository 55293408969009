<h1 mat-dialog-title>¡Atención!</h1>
<div mat-dialog-content>
  <p>Tienes cambios sin guardar en el formulario de estudio.</p>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="continue(false)">
    Salir sin guardar
  </button>
  <button
    mat-raised-button
    color="accent"
    (click)="continue(true)"
    cdkFocusInitial
  >
    Guardar cambios
  </button>
</div>

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { Dispatch } from '../../types';
import { DispatchesService } from '../../services/dispatches.service';
import { StudiesService } from '../../services/studies/studies.service';

@Component({
  selector: 'app-study-dispatch-dialogue',
  template: `
    <app-base-study-dispatch-dialogue
      *ngIf="dispatch || errorMessage"
      [dispatch]="dispatch"
      [disabled]="disabled"
      [isSaving]="isSaving"
      [errorMessage]="errorMessage"
      [failedRules]="failedRules"
      (save)="saveHandler($event)"
    />
  `,
})
export class StudyDispatchDialogueContainerComponent implements OnChanges {
  @Input() dispatch: Dispatch;
  @Input() disabled: boolean;
  @Output() saved: EventEmitter<void> = new EventEmitter();

  isSaving = false;
  errorMessage: string;
  failedRules: string[] = [];

  constructor(
    private dispatchesService: DispatchesService,
    private studiesService: StudiesService,
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    const lastDispatch: Dispatch = changes['dispatch']?.previousValue;
    const newDispatch: Dispatch = changes['dispatch']?.currentValue;

    if (!newDispatch || newDispatch === lastDispatch) {
      return;
    }

    const studyId = newDispatch.studyId;

    const { data } = await this.studiesService.getStudyFailedRules(studyId);

    this.failedRules = data || [];
  }

  async saveHandler(partialDispatch: Partial<Dispatch>) {
    this.isSaving = true;
    this.errorMessage = '';

    const { error } = await this.dispatchesService.createStatus({
      id: this.dispatch.id,
      ...partialDispatch,
    });

    this.isSaving = false;
    this.errorMessage = error?.message || '';

    if (!error) {
      this.saved.emit();
    }
  }
}

export enum ROUTES {
  CUSTOMER_STUDIES_LIST = 'customers',
  DISPATCHED_STUDIES_LIST = 'dispatches',
  ERROR = 'error',
  LOGIN = 'ingreso',
  STUDIES_LIST = 'studies',
  STUDY = 'resultado',
  STUDY__BETA = 'study',
  STUDY__BETA__FORM = 'form',
  STUDY__BETA__INFO = 'info',
  STUDY__BETA__HISTORY = 'history',
}

export const AUTH_HOME_ROUTE = ROUTES.STUDIES_LIST;
export const UNAUTH_HOME_ROUTE = ROUTES.LOGIN;

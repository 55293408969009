import { inject } from '@angular/core';
import { CanDeactivateFn, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { StudyFormUnsavedDialogComponent } from '../components/study-form-unsaved-dialog/study-form-unsaved-dialog.component';

import { StudyFormState } from './types';

export const unsavedStudyFormGuard: CanDeactivateFn<StudyFormState> = (
  component,
  _,
  __,
  nextState,
) => {
  const dialog = inject(MatDialog);
  const router = inject(Router);
  const isUnsaved = component.studyFormChange.isUnsaved;

  if (isUnsaved) {
    const dialogRef = dialog.open(StudyFormUnsavedDialogComponent);

    dialogRef.afterClosed().subscribe((shouldSave) => {
      if (shouldSave === undefined) {
        return;
      }

      if (shouldSave) {
        component.save();

        return;
      }

      component.resetStudyFormChange();
      router.navigate([nextState.url]);
    });
  }

  return !isUnsaved;
};

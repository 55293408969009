import { Injectable } from '@angular/core';

import { User } from './types';

const USER_KEY = 'user';

@Injectable({
  providedIn: 'root',
})
export class UserRepository {
  private user: User;

  constructor() {
    const stringifiedUser = localStorage.getItem(USER_KEY);
    this.user = stringifiedUser && JSON.parse(stringifiedUser);
  }

  public getUser(): User {
    return this.user;
  }

  public setUser(user: User): void {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
    this.user = user;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';

import { EQUITY_FORMAT } from '../../../constants/formats';

const SNACKBAR_MESSAGE = 'Copiado';

@Component({
  selector: 'app-study-read-only-section-field',
  standalone: true,
  imports: [ClipboardModule, CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './study-read-only-section-field.component.html',
  styleUrl: './study-read-only-section-field.component.scss',
})
export class StudyReadOnlySectionFieldComponent {
  @Input() label: string;
  @Input() type: 'text' | 'equity' = 'text';
  @Input() value: string;
  @Output() search = new EventEmitter<void>();

  EQUITY_FORMAT = EQUITY_FORMAT;

  constructor(private snackbar: MatSnackBar) {}

  textCopied() {
    this.snackbar.open(SNACKBAR_MESSAGE, '', { duration: 2000 });
  }
}

import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';

import { PaginatorEvent } from './types/studies-table-pagination';

const PAGE_SIZE = 100;

@Component({
  selector: 'app-studies-table-pagination',
  templateUrl: './studies-table-pagination.component.html',
  styleUrls: ['./studies-table-pagination.component.scss'],
})
export class StudiesTablePaginationComponent {
  @Input() length: number;
  @Input() pageIndex: number;
  @Output() handlePageEvent = new EventEmitter<PaginatorEvent>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  pageSize = PAGE_SIZE;

  handlePageEventEmit(pageEvent: PageEvent) {
    const { pageIndex, previousPageIndex } = pageEvent;

    this.handlePageEvent.emit({ pageIndex, previousPageIndex });
  }
}

import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SafePipesModule } from '../../pipes/sanitizador.pipe';

import { HtmlDocumentComponent } from './html-document/html-document.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { StudyDocumentsExplorerComponent } from './study-documents-explorer.component';
import { StudyDocumentsExplorerContainerComponent } from './study-documents-explorer-container.component';

@NgModule({
  declarations: [
    HtmlDocumentComponent,
    StudyDocumentsExplorerComponent,
    StudyDocumentsExplorerContainerComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    LoadingSpinnerModule,
    MatFormFieldModule,
    MatSelectModule,
    SafePipesModule,
  ],
  exports: [StudyDocumentsExplorerContainerComponent],
})
export class StudyDocumentsExplorerModule {}

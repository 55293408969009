import { Component } from '@angular/core';

import { PaginatedResponse } from '../../services/types';
import { SearchFilter, SearchLabel } from '../../types';
import { SELECT_FILTER } from '../../components/studies-table/studies-table-wrapper/studies-table-wrapper.component';
import { StudiesService } from '../../services/studies/studies.service';
import { StudiesTableStudy } from '../../components/studies-table/types';
import { STUDY_TABLE_COLUMN } from '../../components/studies-table/studies-table.component';

const SENT_TO_INFORM_STATUS = 2;

@Component({
  selector: 'app-studies',
  templateUrl: './studies.component.html',
})
export class StudiesComponent {
  constructor(private studiesService: StudiesService) {
    this.getStudies = this.getStudies.bind(this);
  }

  tableColumns = [
    STUDY_TABLE_COLUMN.CREATED_AT,
    STUDY_TABLE_COLUMN.EXECUTIVE_USERNAME,
    STUDY_TABLE_COLUMN.RUT,
    STUDY_TABLE_COLUMN.BUSINESS_NAME,
    STUDY_TABLE_COLUMN.SGL_CODE,
    STUDY_TABLE_COLUMN.STATUS_DISPLAY,
  ];
  textFilterLabels = [
    SearchLabel.BUSINESS_NAME,
    SearchLabel.RUT,
    SearchLabel.SGL,
  ];
  defaultTextFilterLabel = SearchLabel.SGL;
  selectFilters = [
    { filter: SELECT_FILTER.STATUS, defaultValue: SENT_TO_INFORM_STATUS },
  ];

  async getStudies(
    params: SearchFilter,
    pageUrl: string,
  ): Promise<PaginatedResponse<StudiesTableStudy[]>> {
    return await this.studiesService.getStudies(params, pageUrl);
  }
}

<div class="app-study-history-page">
  <app-study-header />
  <div class="scroll-container">
    <div class="page-loading" *ngIf="loading">
      <app-loading-spinner />
    </div>
    <div class="page-content" *ngIf="!loading">
      <app-error-snack-bar [message]="errorMessage" />
      <app-study-history [studyHistoryTable]="studyHistoryTable" />
    </div>
  </div>
</div>

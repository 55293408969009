import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class InvalidErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    return Boolean(control?.invalid);
  }
}

@Component({
  selector: 'app-study-form-input',
  templateUrl: './study-form-input.component.html',
  styleUrls: ['./study-form-input.component.scss'],
})
export class StudyFormInputComponent {
  @Input() label: string;
  @Input() control: FormControl;
  @Input() type: 'date' | 'text' | 'textarea' | 'number' | 'select' = 'text';
  @Input() options: { value: string | number; label: string }[] = [];
  @Input() errorMessage: string;

  @Output() search = new EventEmitter<void>();
  @Output() undo = new EventEmitter<void>();

  matcher = new InvalidErrorStateMatcher();
}

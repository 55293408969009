<div class="app-study-partial-edit-dialog">
  <app-error-snack-bar [message]="errorMessage" />
  <h2 mat-dialog-title>Editar Estudio</h2>
  <mat-dialog-content>
    @if (data.field === studyEditField.SGL_CODE) {
      <p>Actualizar SGL</p>
      <mat-form-field>
        <mat-label>Sgl</mat-label>
        <input matInput [(ngModel)]="data.study.sglCode" />
      </mat-form-field>
    } @else if (data.field === studyEditField.STATUS) {
      <p>Actualizar Estado</p>
      <mat-form-field>
        <mat-label>Estado</mat-label>
        <select matNativeControl [(ngModel)]="data.study.status">
          @for (status of studyStatuses; track status.value) {
            <option [ngValue]="status.value">
              {{ status.displayName }}
            </option>
          }
        </select>
      </mat-form-field>
    }
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <app-button
      class="cancel-button"
      [disabled]="loading"
      (buttonClick)="closeDialog.emit()"
      >Cerrar</app-button
    >
    <app-button
      (buttonClick)="save.emit()"
      [loading]="loading"
      [disabled]="saveIsDisabled"
      >Guardar</app-button
    >
  </mat-dialog-actions>
</div>

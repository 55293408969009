export const STUDY_COMPANY_KINDS = [
  {
    value: 1,
    displayName: 'sociedad por acciones',
  },
  {
    value: 2,
    displayName: 'empresa individual de responsabilidad limitada',
  },
  {
    value: 3,
    displayName: 'sociedad de responsabilidad limitada',
  },
  {
    value: 4,
    displayName: 'sociedad anónima',
  },
  {
    value: 5,
    displayName: 'sociedad anónima cerrada',
  },
  {
    value: 6,
    displayName: 'na',
  },
  {
    value: 100,
    displayName: 'otro',
  },
];

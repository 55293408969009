import { Injectable } from '@angular/core';
import {
  Observable,
  Subject,
  catchError,
  map,
  switchMap,
  throwError,
} from 'rxjs';
import { setUser as sentrySetUser } from '@sentry/angular-ivy';

import { AuthService } from './auth.service';
import { TokenService } from './token.service';
import { TokenRepository } from './token-repository';
import { UserRepository } from './user-repository';
import { UserService } from './user.service';
import { User } from './types';

@Injectable({
  providedIn: 'root',
})
export class Session {
  public sessionChanged$ = new Subject<{ user: User }>();

  constructor(
    private authService: AuthService,
    private tokenRepository: TokenRepository,
    private tokenService: TokenService,
    private userRepository: UserRepository,
    private userService: UserService,
  ) {
    this.monitorUser();
  }

  private monitorUser(): void {
    const initialUser = this.getUser();
    initialUser &&
      sentrySetUser({ id: initialUser.id, username: initialUser.username });

    this.sessionChanged$.subscribe(({ user }) => {
      sentrySetUser(user && { id: user.id, username: user.username });
    });
  }

  public closeSession(): void {
    const user = this.userRepository.getUser();
    const didSessionChanged = user !== null;

    this.tokenRepository.setToken(null);
    this.tokenRepository.setRefreshToken(null);
    this.userRepository.setUser(null);

    if (didSessionChanged) {
      this.sessionChanged$.next({
        user: null,
      });
    }
  }

  public getAuthToken(): string {
    return this.tokenRepository.getToken();
  }

  public getUser(): User {
    return this.userRepository.getUser();
  }

  public logIn(username: string, password: string): Observable<boolean> {
    return this.authService.logIn(username, password).pipe(
      switchMap((authResponse) => {
        if (!authResponse?.access) {
          throw new Error('No access token');
        }

        this.tokenRepository.setToken(authResponse.access);
        this.tokenRepository.setRefreshToken(authResponse.refresh);

        return this.userService.getUser();
      }),
      map((user) => {
        if (!user) {
          throw new Error('No user found');
        }

        this.userRepository.setUser(user);
        this.sessionChanged$.next({ user });

        return true;
      }),
      catchError((error) => {
        this.closeSession();

        return throwError(() => new Error(error));
      }),
    );
  }

  public refreshToken(): Observable<boolean> {
    const currentUser = this.userRepository.getUser();
    const refreshToken = this.tokenRepository.getRefreshToken();

    if (!refreshToken) {
      this.closeSession();

      return throwError(() => new Error('No refresh token'));
    }

    return this.tokenService.refresh(refreshToken).pipe(
      switchMap((response) => {
        if (!response?.access) {
          throw new Error('No access token');
        }

        this.tokenRepository.setToken(response.access);

        return this.userService.getUser();
      }),
      map((user) => {
        if (!user) {
          throw new Error('No user found');
        }

        this.userRepository.setUser(user);

        if (currentUser?.id !== user.id) {
          this.sessionChanged$.next({ user });
        }

        return true;
      }),
      catchError((error) => {
        this.closeSession();

        return throwError(() => new Error(error));
      }),
    );
  }
}

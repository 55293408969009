import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-input-group',
  templateUrl: './input-group.component.html',
  styleUrls: ['./input-group.component.scss'],
})
export class InputGroupComponent {
  @Input() title: string;
  @Input() formArray: FormArray;
  @Output() add = new EventEmitter<void>();

  get classNames(): string {
    const classes = ['app-input-group'];

    if (this.formArray.disabled) {
      classes.push('disabled');
    }

    if (this.formArray.invalid) {
      classes.push('invalid');
    }

    if (this.formArray.dirty) {
      classes.push('dirty');
    }

    return classes.join(' ');
  }

  addHandler() {
    this.add.emit();
  }
}

import { SearchItem } from '../../../types/document';
import { STUDY_DOCUMENT_SEARCH_ID } from '../../../constants/document';

import { PreStudy, StudyBlock } from '../types';

const ES_KEYS_TO_SEARCH_ID = {
  accionistas: STUDY_DOCUMENT_SEARCH_ID.SHAREHOLDERS,
  administracion: STUDY_DOCUMENT_SEARCH_ID.ADMINISTRATION,
  capital: STUDY_DOCUMENT_SEARCH_ID.EQUITY,
  domicilio: STUDY_DOCUMENT_SEARCH_ID.ADDRESS,
  duracion: STUDY_DOCUMENT_SEARCH_ID.DURATION,
  facultades: STUDY_DOCUMENT_SEARCH_ID.FACULTIES,
  nombreFantasia: STUDY_DOCUMENT_SEARCH_ID.FANTASY_NAME,
  objeto: STUDY_DOCUMENT_SEARCH_ID.CORPORATE_PURPOSE,
  razonSocial: STUDY_DOCUMENT_SEARCH_ID.BUSINESS_NAME,
};

export const buildSearchItems = (preStudy: PreStudy): SearchItem[] => {
  const searchItems = Object.keys(ES_KEYS_TO_SEARCH_ID).map((key) => {
    const searchId = ES_KEYS_TO_SEARCH_ID[key];
    const studyBlock = preStudy[key] as StudyBlock<never>;

    return {
      searchId,
      sections: studyBlock.articulosReferencia,
      texts: studyBlock.frasesReferencia,
    };
  });

  return searchItems;
};

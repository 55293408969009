import { Injectable } from '@angular/core';

import {
  Administrator,
  Document,
  Faculty,
  SearchFilter,
  Shareholder,
  Study,
  StudyAdministrator,
  StudyFaculty,
  StudyShareholder,
} from '../../types';
import { PaginatedResponse, SimpleResponse } from '../types';
import { createErrorResponse } from '../utils';
import {
  ADMINISTRATION_KINDS,
  ADMINISTRATION_ROLES,
} from 'src/app/constants/administration-kinds';
import { RES_FACULTIES } from '../../constants/faculties';
import { preStudyToHtml } from './utils/prestudy-to-html/prestudy-to-html';
import { buildSearchItems } from './utils/build-search-items';
import { StudyHistoryTable } from './utils/study-history-table';
import { BackendStudy, LegacyV1BackendStudyData } from './types';
import { LegacyV1StudiesService } from './legacy-v1-studies.service';
import { V2StudiesService, StudyUpdateParams } from './v2-studies.service';
import { AdministratorsService } from './administrators.service';
import { FacultiesService } from './faculties.service';
import { ShareholdersService } from './shareholders.service';
import {
  backendShareholderToStudyShareholder,
  backendStudyToStudy,
  studyShareholderToBackendShareholder,
} from './utils/backend-study-to-study';

@Injectable({
  providedIn: 'root',
})
export class StudiesService {
  constructor(
    private administratorsService: AdministratorsService,
    private facultiesService: FacultiesService,
    private legacyV1StudiesService: LegacyV1StudiesService,
    private shareholdersService: ShareholdersService,
    private v2StudiesService: V2StudiesService,
  ) {}

  private homologateStudyToV1(v1Study, v2Study) {
    const prestudy = v1Study.estudio.prestudios.slice(-1)[0];
    const study = {
      accionistas: {
        ok: true,
        valor: v2Study.associates.map((associate) => ({
          nombre: associate.name,
          rut: associate.rut,
          participacion: associate.stock,
          participacionDinero: associate.equity,
        })),
      },
      administracion: {
        ok: true,
        frasesReferencia: prestudy.administracion.frasesReferencia,
        valor: {
          administradores: v2Study.administrators.map((administrator) => ({
            rut: administrator.rut,
            tipo: administrator.administrationRoleDisplay,
            comoAdministra: administrator.administrationKindDisplay,
            nombre: administrator.name,
          })),
          observacion: v2Study.administrationObservation,
        },
      },
      anexos: [],
      articulosRepetidos: {
        ok: true,
      },
      capital: {
        ok: true,
        valor: v2Study.equity,
      },
      disuelto: false,
      revisionManual: true,
      domicilio: {
        ok: true,
        valor: v2Study.address,
      },
      duracion: {
        ok: true,
        valor: {
          tags: [],
          texto: prestudy.duracion.valor.texto,
        },
      },
      facultades: {
        ok: true,
        valor: {
          facultades: v2Study.faculties.reduce(
            (a, faculty) => ({ ...a, [faculty.facultyDisplay]: '' }),
            {},
          ),
        },
      },
      fecha: prestudy.fecha,
      nombreFantasia: prestudy.nombreFantasia,
      objeto: {
        ok: true,
        valor: v2Study.corporatePurpose,
      },
      razonSocial: {
        ok: true,
        valor: v2Study.businessName,
      },
      rut: v2Study.rut,
      tipoActuacion: prestudy.tipoActuacion,
      tipoSociedad: v2Study.companyKindDisplay,
    };
    return study;
  }

  private findChoice = (
    choices: { displayName: string; value: number }[],
    value: number,
  ): string => {
    if (!value) return null;

    const match = choices.find((choice) => choice.value === value);

    return match?.displayName || null;
  };

  private enrichV2Study(study: BackendStudy) {
    const administrationKindDisplay = this.findChoice(
      ADMINISTRATION_KINDS,
      study.administrationKind,
    );
    const administrators = study.administrators.map((admin) => ({
      ...admin,
      administrationKindDisplay: this.findChoice(
        ADMINISTRATION_KINDS,
        admin.administrationKind,
      ),
      administrationRoleDisplay: this.findChoice(
        ADMINISTRATION_ROLES,
        admin.administrationRole,
      ),
    }));
    const faculties = study.faculties.map((studyFaculty) => ({
      ...studyFaculty,
      facultyDisplay: this.findChoice(RES_FACULTIES, studyFaculty.faculty),
    }));

    return {
      ...study,
      administrationKindDisplay,
      administrators,
      faculties,
    };
  }

  public async getStudy(studyId: number): Promise<SimpleResponse<Study>> {
    const response = await this.v2StudiesService.getStudy(studyId);

    return {
      ...response,
      data: response.data && backendStudyToStudy(response.data),
    };
  }

  public getStudyFailedRules(
    studyId: number,
  ): Promise<SimpleResponse<string[]>> {
    return this.v2StudiesService.getStudyFailedRules(studyId);
  }

  public async getFullStudy(studyId: number): Promise<
    SimpleResponse<
      LegacyV1BackendStudyData & {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        lastDispatch?: any;
        sglCode: string;
        status: number;
      }
    >
  > {
    const [v1StudyResponse, v2StudyResponse] = await Promise.all([
      this.legacyV1StudiesService.getStudy(studyId),
      this.v2StudiesService.getStudy(studyId),
    ]);

    const error = v1StudyResponse.error || v2StudyResponse.error;
    if (error) {
      return createErrorResponse(error);
    }

    const v1Study = v1StudyResponse.data;
    const v2Study = v2StudyResponse.data;
    const defaultStudy = {
      ...v1Study,
      sglCode: v2Study.sglCode,
      status: v2Study.status,
    };

    if (!v2Study.lastDispatch) {
      return {
        data: defaultStudy,
      };
    }

    const enrichedV2Study = this.enrichV2Study(v2Study);
    const lastDispatch = {
      ...v2Study.lastDispatch,
      study: this.homologateStudyToV1(v1Study, enrichedV2Study),
    };

    return {
      data: { ...defaultStudy, lastDispatch },
    };
  }

  public async getStudies(
    filter: SearchFilter,
    pageUrl?: string,
  ): Promise<PaginatedResponse<Study[]>> {
    const response = await this.v2StudiesService.getStudies(filter, pageUrl);

    return { ...response, results: response.results?.map(backendStudyToStudy) };
  }

  public async updateStudy(
    studyId: number,
    params: StudyUpdateParams,
  ): Promise<SimpleResponse<Study>> {
    const response = await this.v2StudiesService.updateStudy(studyId, params);

    return {
      ...response,
      data: response.data && backendStudyToStudy(response.data),
    };
  }

  public updateStudyAdministrators(
    studyId: number,
    administrators: Administrator[],
  ): Promise<SimpleResponse<StudyAdministrator[]>> {
    const studyAdministrators = administrators.map((administrator) => ({
      ...administrator,
      study: studyId,
    }));

    return this.administratorsService.updateAdministrators(studyAdministrators);
  }

  public updateStudyFaculties(
    studyId: number,
    faculties: Faculty[],
  ): Promise<SimpleResponse<StudyFaculty[]>> {
    const studyFaculties = faculties.map((faculty) => ({
      ...faculty,
      study: studyId,
    }));

    return this.facultiesService.updateFaculties(studyFaculties);
  }

  public async updateStudyShareholders(
    studyId: number,
    shareholders: Shareholder[],
  ): Promise<SimpleResponse<StudyShareholder[]>> {
    const studyShareholders = shareholders
      .map((shareholder) => ({
        ...shareholder,
        study: studyId,
      }))
      .map(studyShareholderToBackendShareholder);
    const response =
      await this.shareholdersService.updateShareholders(studyShareholders);

    return {
      ...response,
      data: response.data?.map(backendShareholderToStudyShareholder),
    };
  }

  public async updateStudyRelations(
    studyId: number,
    associates?: StudyShareholder[],
    administrators?: StudyAdministrator[],
    faculties?: StudyFaculty[],
  ): Promise<Error[]> {
    /**
     * Due to backend race conditions, we need to update the study relations
     * in a specific order to avoid conflicts.
     * This should be repsolved once the groups flow is fully implemented.
     */
    const errors = [];

    if (associates) {
      const { error } = await this.updateStudyShareholders(studyId, associates);
      errors.push(error);
    }
    if (administrators) {
      const { error } = await this.updateStudyAdministrators(
        studyId,
        administrators,
      );
      errors.push(error);
    }
    if (faculties) {
      const { error } = await this.updateStudyFaculties(studyId, faculties);
      errors.push(error);
    }

    return errors.filter(Boolean);
  }

  public async getStudyDocuments(
    studyId: number,
  ): Promise<SimpleResponse<Document[]>> {
    const result = await this.legacyV1StudiesService.getStudy(studyId);

    const documents: Document[] = result.data?.estudio.prestudios.map(
      (presStudy) => ({
        id: presStudy.cve,
        name: `${presStudy.fecha} ${presStudy.tipoActuacion}`,
        content: {
          type: 'html',
          value: preStudyToHtml(presStudy),
          searchItems: buildSearchItems(presStudy),
        },
      }),
    );

    return {
      ...result,
      data: documents,
    };
  }

  public async getStudyHistory(
    studyId: number,
  ): Promise<SimpleResponse<StudyHistoryTable>> {
    const result = await this.legacyV1StudiesService.getStudy(studyId);

    const history = new StudyHistoryTable(
      result.data?.estudio.prestudios || [],
    );

    return {
      ...result,
      data: history,
    };
  }
}

export const getYears = (start = 2019): string[] => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const years = [];

  if (start > currentYear) {
    throw new Error('start year cannot be greater than the current year');
  }

  for (let i = start; i <= currentYear; i++) {
    years.push(i.toString());
  }

  return years.reverse();
};

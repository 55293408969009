<span class="label">{{ label }}</span>
<span class="value">
  @switch (type) {
    @case ("text") {
      {{ value }}
    }
    @case ("equity") {
      {{ value | number: EQUITY_FORMAT }}
    }
  }
</span>
<div class="actions">
  @if (value) {
    <button
      mat-icon-button
      [cdkCopyToClipboard]="value"
      (cdkCopyToClipboardCopied)="textCopied()"
    >
      <mat-icon>content_copy</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="search.emit()"
      [disabled]="!search.observers.length"
    >
      <mat-icon>search</mat-icon>
    </button>
  }
</div>

export const STUDY_DOCUMENT_SEARCH_ID = {
  ADDRESS: 'ADDRESS',
  ADMINISTRATION: 'ADMINISTRATION',
  BUSINESS_NAME: 'BUSINESS_NAME',
  CORPORATE_PURPOSE: 'CORPORATE_PURPOSE',
  DURATION: 'DURATION',
  EQUITY: 'EQUITY',
  FACULTIES: 'FACULTIES',
  FANTASY_NAME: 'FANTASY_NAME',
  SHAREHOLDERS: 'SHAREHOLDERS',
};

export const STUDY_ATTRIBUTE_TO_SEARCH_ID = {
  address: STUDY_DOCUMENT_SEARCH_ID.ADDRESS,
  administration: STUDY_DOCUMENT_SEARCH_ID.ADMINISTRATION,
  businessName: STUDY_DOCUMENT_SEARCH_ID.BUSINESS_NAME,
  corporatePurpose: STUDY_DOCUMENT_SEARCH_ID.CORPORATE_PURPOSE,
  duration: STUDY_DOCUMENT_SEARCH_ID.DURATION,
  equity: STUDY_DOCUMENT_SEARCH_ID.EQUITY,
  faculties: STUDY_DOCUMENT_SEARCH_ID.FACULTIES,
  fantasyName: STUDY_DOCUMENT_SEARCH_ID.FANTASY_NAME,
  shareholders: STUDY_DOCUMENT_SEARCH_ID.SHAREHOLDERS,
};

import { Dispatch } from '../../../../types';

const DISPATCH_STATUS = {
  OPEN: 1,
  CLOSED: 2,
};

export const STUDY_DISPATCH_OPEN = {
  status: DISPATCH_STATUS.OPEN,
  isApproved: null,
};

export const STUDY_DISPATCH_APPROVED = {
  status: DISPATCH_STATUS.CLOSED,
  isApproved: true,
};

export const STUDY_DISPATCH_REJECTED = {
  status: DISPATCH_STATUS.CLOSED,
  isApproved: false,
};

export const getStudyDispatch = (dispatch: Dispatch) => {
  return [
    STUDY_DISPATCH_OPEN,
    STUDY_DISPATCH_APPROVED,
    STUDY_DISPATCH_REJECTED,
  ].find(({ status, isApproved }) => {
    return dispatch.status === status && dispatch.isApproved === isApproved;
  });
};

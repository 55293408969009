import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DISPATCH_STATUS } from '../../../constants/statuses';
import { StudiesService } from '../../../services/studies/studies.service';
import { Study } from '../../../types';
import { StudyFormChange } from '../../../components/study-form/types';
import { StudyFormState } from '../../../guards/types';

import { StudyPageService } from '../study-page.service';

@Component({
  selector: 'app-study-form-page',
  styleUrls: ['./study-form-page.component.scss'],
  templateUrl: './study-form-page.component.html',
})
export class StudyFormPageComponent implements StudyFormState, OnInit {
  study: Study;
  search: string;
  isSaving = false;
  disabled = false;
  loading = false;
  errorMessage: string;
  studyFormChange: StudyFormChange;

  constructor(
    private route: ActivatedRoute,
    private studiesService: StudiesService,
    private studyPageService: StudyPageService,
  ) {}

  ngOnInit() {
    this.resetStudyFormChange();

    const studyId = Number(this.route.parent.snapshot.params['id']);
    this.loadStudy(studyId);
  }

  async loadStudy(studyId: number) {
    this.loading = true;

    const { error, data } = await this.studiesService.getStudy(studyId);

    this.loading = false;
    this.errorMessage = error?.message || '';
    this.study = data;

    const lastDispatchStatus = this.study?.lastDispatch?.status;
    this.disabled = lastDispatchStatus !== DISPATCH_STATUS.IN_STUDY;
  }

  resetStudyFormChange() {
    this.studyFormChange = {
      changes: {},
      isValid: true,
      isUnsaved: false,
    };
  }

  searchInDocumentExplorer(searchId: string) {
    this.studyPageService.search(searchId);
  }

  onValueChanges(studyFormChange: StudyFormChange) {
    this.studyFormChange = {
      ...studyFormChange,
    };
  }

  async save() {
    if (this.isSaving) {
      return;
    }

    const studyId = this.study.id;
    const { associates, administrators, faculties, ...baseStudyChanges } =
      this.studyFormChange.changes;

    this.errorMessage = '';
    this.isSaving = true;
    const relationsErrors = await this.studiesService.updateStudyRelations(
      studyId,
      associates,
      administrators,
      faculties,
    );
    const { error, data: study } = await this.studiesService.updateStudy(
      studyId,
      baseStudyChanges,
    );
    this.isSaving = false;

    if (relationsErrors.length || error) {
      this.errorMessage = [
        ...relationsErrors.map(({ message }) => message),
        error?.message,
      ].join('. ');
    }

    if (study) {
      this.resetStudyFormChange();
      this.study = study;
    }
  }

  async disptachSaved() {
    this.isSaving = true;
    await this.loadStudy(this.study.id);
    this.isSaving = false;
  }
}

<mat-drawer-container class="app-sidenav-layout" autosize>
  <mat-drawer
    #drawer
    class="side-content"
    mode="side"
    position="end"
    [opened]="opened"
  >
    <ng-content select="[sidenav]"></ng-content>
  </mat-drawer>

  <mat-drawer-content class="main-content">
    <div class="scroll-container">
      <ng-content></ng-content>
    </div>
    <button
      mat-fab
      class="toggle-button"
      color="primary"
      [disabled]="disabled"
      (click)="toggleSidenav()"
    >
      <mat-icon>switch_left</mat-icon>
    </button>
  </mat-drawer-content>
</mat-drawer-container>

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-base-study-header',
  templateUrl: './study-header.component.html',
  styleUrls: ['./study-header.component.scss'],
})
export class StudyHeaderComponent {
  @Input() navItems: {
    name: string;
    path: string;
  }[];
  @Input() saveButtonDisabled: boolean;
  @Input() saveButtonLoading: boolean;
  @Input() saveButtonVisible: boolean;
  @Input() showInvalidMessage: boolean;

  @Output() save = new EventEmitter<void>();
}

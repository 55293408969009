<div class="app-study-dispatch-dialogue">
  <ng-container *ngIf="dispatch">
    <app-study-dispatch-message
      [dispatch]="dispatch"
      [failedRules]="failedRules"
    />
    <app-study-dispatch-update
      (save)="saveHandler($event)"
      [dispatch]="dispatch"
      [disabled]="disabled"
      [errorMessage]="errorMessage"
      [isSaving]="isSaving"
    />
  </ng-container>
  <p *ngIf="!dispatch && errorMessage">
    {{ errorMessage }}
  </p>
</div>

import { Component, Input } from '@angular/core';

import { Dispatch } from '../../../types';

const OPEN_DISPATCH_STATUS = 1;

@Component({
  selector: 'app-study-dispatch-message',
  templateUrl: './study-dispatch-message.component.html',
  styleUrls: ['./study-dispatch-message.component.scss'],
})
export class StudyDispatchMessageComponent {
  @Input() dispatch: Dispatch;
  @Input() failedRules: string[];

  get displayApprovalState() {
    return this.dispatch.isApproved ? 'aprobado' : 'rechazado';
  }

  get isDispatchOpen() {
    return this.dispatch.status === OPEN_DISPATCH_STATUS;
  }

  get displayObservations() {
    return this.dispatch.message
      ? `con observaciones: ${this.dispatch.message}`
      : `sin observaciones`;
  }
}

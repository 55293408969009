export enum DISPATCH_STATUS {
  IN_STUDY = 1,
  DELIVERED = 2,
  NOT_STUDIABLE = 3,
}

export const DISPATCH_STATUSES = [
  { value: DISPATCH_STATUS.IN_STUDY, displayName: 'en estudio' },
  { value: DISPATCH_STATUS.DELIVERED, displayName: 'entregado' },
  { value: DISPATCH_STATUS.NOT_STUDIABLE, displayName: 'no estudiable' },
];

export const STUDY_STATUSES = [
  { value: 1, displayName: 'pre-estudiado' },
  { value: 2, displayName: 'enviado a informar' },
  { value: 3, displayName: 'finalizado' },
  { value: 4, displayName: 'rut repetido' },
  { value: 5, displayName: 'error' },
  { value: 6, displayName: 'SGL previamente informado' },
  { value: 7, displayName: 'en estudio' },
  { value: 8, displayName: 'despachado' },
  { value: 9, displayName: 'no estudiable' },
  { value: 10, displayName: 'entregado' },
];

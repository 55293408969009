import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';

import { EQUITY_FORMAT } from '../../../constants/formats';
import { Shareholder } from '../../../types';

const SNACKBAR_MESSAGE = 'Copiado';

@Component({
  selector: 'app-study-read-only-section-shareholders-table',
  standalone: true,
  imports: [
    ClipboardModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
  ],
  templateUrl: './study-read-only-section-shareholders-table.component.html',
  styleUrl: './study-read-only-section-shareholders-table.component.scss',
})
export class StudyReadOnlySectionShareholdersTableComponent {
  @Input() shareholders: Shareholder[];
  @Output() search = new EventEmitter<void>();

  EQUITY_FORMAT = EQUITY_FORMAT;
  displayedColumns = ['rut', 'name', 'equity', 'stock', 'actions'];

  constructor(private snackbar: MatSnackBar) {}

  textCopied() {
    this.snackbar.open(SNACKBAR_MESSAGE, '', { duration: 2000 });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

import { environment } from '../../../environments/environment';
import { User } from './types';

const USER_URL = `${environment.api}/v2/users/data/`;

export type BackendUser = {
  id: number;
  username: string;
  profile: string;
  customer: number | null;
  sendsToInform: boolean;
  allowsRepeatedRut: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  public getUser(): Observable<User> {
    return this.http.get<BackendUser>(USER_URL).pipe(
      map((user) => {
        return {
          id: user.id,
          username: user.username,
          /**
           * TODO: Roles should be fetched from the backend
           * For now, we are hardcoding the roles until
           * https://equipolegalbot.atlassian.net/browse/LB-1065 is ready
           */
          roles: ['Administrator'],
        };
      }),
    );
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-studies-table-month-filter',
  templateUrl: './studies-table-month-filter.component.html',
  styleUrls: ['./studies-table-month-filter.component.scss'],
})
export class StudiesTableMonthFilterComponent {
  months = [
    { displayName: 'Enero', value: '1' },
    { displayName: 'Febrero', value: '2' },
    { displayName: 'Marzo', value: '3' },
    { displayName: 'Abril', value: '4' },
    { displayName: 'Mayo', value: '5' },
    { displayName: 'Junio', value: '6' },
    { displayName: 'Julio', value: '7' },
    { displayName: 'Agosto', value: '8' },
    { displayName: 'Septiembre', value: '9' },
    { displayName: 'Octubre', value: '10' },
    { displayName: 'Noviembre', value: '11' },
    { displayName: 'Diciembre', value: '12' },
  ];

  @Input() selectedMonth: string;
  @Output() selectedMonthChange = new EventEmitter<string>();

  selectionChange() {
    this.selectedMonthChange.emit(this.selectedMonth);
  }
}

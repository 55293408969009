import {
  ExtractionBlock,
  TitleExtractionBlock,
  TextExtractionBlock,
  MainExtractionBlock,
  ModificationExtractionBlock,
  SignaturesExtractionBlock,
} from '../../types';
import * as htmlBuilders from './html-builders';

export const blockToHtml = (
  block: ExtractionBlock,
  builders = htmlBuilders,
): string => {
  if (['titulo_primero', 'titulo'].includes(block.tipo)) {
    return builders.titleHtmlBuilder(block as TitleExtractionBlock);
  }

  if (['intro', 'accionistas', 'actomodifica', 'anexos'].includes(block.tipo)) {
    return builders.textHtmlBuilder(block as TextExtractionBlock);
  }

  if (block.tipo === 'principal') {
    return builders.mainHtmlBuilder(block as MainExtractionBlock);
  }

  if (block.tipo === 'modificacion') {
    return builders.modificationHtmlBuilder(
      block as ModificationExtractionBlock,
    );
  }

  if (block.tipo === 'firmas') {
    return builders.signaturesHtmlBuilder(block as SignaturesExtractionBlock);
  }

  return builders.unknownHtmlBuilder(block);
};

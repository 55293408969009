import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import {
  UserCapabilities,
  PAGE_SECTION,
} from '../services/auth/user-capabilities';
import { ROUTES } from '../constants/routes';

export function capabilitiesGuard(pageSection: PAGE_SECTION): CanActivateFn {
  return () => {
    const canViewSection =
      inject(UserCapabilities).hasSectionViewCapability(pageSection);

    if (!canViewSection) {
      inject(Router).navigate([ROUTES.ERROR]);

      return false;
    }

    return true;
  };
}

<mat-form-field>
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [disabled]="errorMessage"
    [(value)]="value"
    (selectionChange)="selectionChange()"
  >
    <mat-option *ngFor="let option of _options" [value]="option.value">{{
      option.displayName
    }}</mat-option>
  </mat-select>
  <mat-hint class="mat-mdc-form-field-error" *ngIf="errorMessage">
    {{ errorMessage }}
  </mat-hint>
</mat-form-field>

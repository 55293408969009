import { Component } from '@angular/core';

@Component({
  selector: 'app-error-message',
  styleUrls: ['./error-message.component.scss'],
  template: `
    <div class="app-error-message">
      <strong>Error</strong> - <ng-content></ng-content>
    </div>
  `,
})
export class ErrorMessageComponent {}

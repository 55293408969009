import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { Session } from '../services/auth/session';
import { AUTH_HOME_ROUTE } from '../constants/routes';

export const unAuthGuard: CanActivateFn = () => {
  if (!inject(Session).getUser()) {
    return true;
  }

  inject(Router).navigate([AUTH_HOME_ROUTE]);
  return false;
};

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { StudyTableSelectOption } from '../types';
import { CustomerLawFirm } from 'src/app/types';
import { CustomerService } from 'src/app/services/customer.service';
import { LEGALBOT_LAWFIRM } from 'src/app/constants/lawFirms';

@Component({
  selector: 'app-studies-table-customer-law-firm-filter',
  template: `<app-studies-table-select-filter
    label="Abogado"
    [errorMessage]="errorMessage"
    [options]="lawyerOptions"
    [(value)]="selectedCustomerLawFirm"
    (valueChange)="customerLawFirmChange()"
  />`,
})
export class StudiesTableCustomerLawFirmFilterComponent implements OnChanges {
  @Input() selectedCustomer: StudyTableSelectOption['value'];
  @Input() selectedCustomerLawFirm: StudyTableSelectOption['value'];
  @Output() selectedCustomerLawFirmChange = new EventEmitter<string>();

  customerLawFirmList: CustomerLawFirm[];
  errorMessage: string;
  lawyerOptions: StudyTableSelectOption[] = [];

  constructor(private customerService: CustomerService) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedCustomer' in changes) {
      this.loadCustomerLawFirms();
    }
  }

  setLawyerOptions() {
    this.lawyerOptions = this.customerLawFirmList.map((lawFirm) => ({
      displayName: lawFirm.name,
      value: lawFirm.lawFirm.toString(),
    }));
  }

  customerLawFirmChange() {
    this.selectedCustomerLawFirmChange.emit(
      this.selectedCustomerLawFirm.toString(),
    );
  }

  async loadCustomerLawFirms() {
    this.errorMessage = null;

    if (!this.selectedCustomer) {
      this.customerLawFirmList = [LEGALBOT_LAWFIRM];
      this.setLawyerOptions();
      return;
    }

    const { data, error } = await this.customerService.getCustomerLawFirms(
      this.selectedCustomer.toString(),
    );

    if (error) {
      this.errorMessage = error.message;
      return;
    }

    this.customerLawFirmList = data;
    this.setLawyerOptions();

    return;
  }
}

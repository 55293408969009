import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

import { environment } from '../../environments/environment';
import { Dispatch, DispatchStatus, SearchFilter } from '../types';
import { mapFiltersToParams } from '../utils/filters';
import { createErrorResponse, createErrorPaginatedResponse } from './utils';
import { PaginatedResponse, SimpleResponse } from './types';
import { UNKNOWN_HTTP_ERROR } from './constants';

const DISPATCH_URL = `${environment.api}/v2/dispatch/`;

@Injectable({
  providedIn: 'root',
})
export class DispatchesService {
  constructor(private http: HttpClient) {}

  public async createStatus(
    dispatch: Partial<Dispatch>,
  ): Promise<SimpleResponse<DispatchStatus>> {
    const url = `${DISPATCH_URL}${dispatch.id}/create-status/`;

    try {
      const dispatchStatus = await lastValueFrom(
        this.http.post<DispatchStatus>(url, dispatch),
      );

      return {
        data: dispatchStatus,
      };
    } catch (error) {
      const status = error?.status;
      const errors = error?.error?.errors;

      if (status !== 400 || !Array.isArray(errors)) {
        return createErrorResponse(UNKNOWN_HTTP_ERROR);
      }

      const errorMessage = errors.join(' ');

      return createErrorResponse(
        Error(
          `El estudio debe pasar las siguientes validaciones: ${errorMessage}`,
        ),
      );
    }
  }

  public async getDispatches(
    filter: SearchFilter,
    pageUrl?: string,
  ): Promise<PaginatedResponse<Dispatch[]>> {
    if (pageUrl && !pageUrl.startsWith(DISPATCH_URL)) {
      return createErrorPaginatedResponse(
        Error('Invalid page URL for dispatch list'),
      );
    }

    const url = pageUrl || DISPATCH_URL;
    const params = pageUrl ? undefined : mapFiltersToParams(filter);

    try {
      const response = await lastValueFrom(
        this.http.get<PaginatedResponse<Dispatch[]>>(url, {
          params,
        }),
      );

      return response;
    } catch {
      return createErrorPaginatedResponse(UNKNOWN_HTTP_ERROR);
    }
  }
}

import { SearchFilter } from '../types';
import { camelToSnake, CamelToSnake } from './strings';

type CamelKeysToSnake<T> = {
  [K in keyof T as CamelToSnake<Extract<K, string>>]: T[K];
};

export const mapFiltersToParams = (
  filters: SearchFilter,
): CamelKeysToSnake<SearchFilter> => {
  const nonEmptySnakeCaseFilters = Object.keys(filters).reduce((acc, key) => {
    if (filters[key] !== '' && filters[key] !== undefined) {
      acc[camelToSnake(key)] = filters[key];
    }

    return acc;
  }, {} as SearchFilter);

  return nonEmptySnakeCaseFilters;
};

import { Component } from '@angular/core';

@Component({
  selector: 'app-study-read-only-section',
  standalone: true,
  imports: [],
  templateUrl: './study-read-only-section.component.html',
  styleUrl: './study-read-only-section.component.scss',
})
export class StudyReadOnlySectionComponent {}

import { Component } from '@angular/core';

import { ROUTES } from '../../constants/routes';
import { Session } from '../../services/auth/session';
import { UserCapabilities } from '../../services/auth/user-capabilities';

const NAV_ITEMS = [
  {
    name: 'Estudios',
    path: `/${ROUTES.STUDIES_LIST}`,
    pageSectionCapability: UserCapabilities.PAGE_SECTION.STUDIES_LIST,
  },
  {
    name: 'Clientes',
    path: `/${ROUTES.CUSTOMER_STUDIES_LIST}`,
    pageSectionCapability: UserCapabilities.PAGE_SECTION.CUSTOMER_STUDIES_LIST,
  },
  {
    name: 'Despachos',
    path: `/${ROUTES.DISPATCHED_STUDIES_LIST}`,
    pageSectionCapability:
      UserCapabilities.PAGE_SECTION.DISPATCHED_STUDIES_LIST,
  },
];

@Component({
  selector: 'app-header',
  template: `<app-base-header [navItems]="navItems" (logout)="logout()" />`,
})
export class HeaderContainerComponent {
  navItems: {
    name: string;
    path: string;
  }[];

  constructor(
    private session: Session,
    private userCapabilities: UserCapabilities,
  ) {
    this.navItems = NAV_ITEMS.filter((item) =>
      this.userCapabilities.hasSectionViewCapability(
        item.pageSectionCapability,
      ),
    ).map(({ name, path }) => ({
      name,
      path,
    }));
  }

  logout() {
    this.session.closeSession();
  }
}

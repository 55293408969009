<app-study-section
  [initiallyExpanded]="initiallyExpanded"
  [isInvalid]="studyForm.invalid"
  [isUnsaved]="studyForm.dirty"
  class="app-study-shareholders-section"
  title="Accionistas y Capital"
>
  <app-input-group
    title="Accionistas*"
    [formArray]="shareholders"
    (add)="addShareholder()"
  >
    <app-study-section
      *ngFor="let shareholder of shareholders.controls; let i = index"
      [title]="shareholder.controls.name.value"
      [initiallyExpanded]="shareholder.invalid"
      [isUnsaved]="shareholder.dirty"
      [isInvalid]="shareholder.invalid"
    >
      <app-study-form-input
        appRutMask
        (undo)="resetShareHolderField('rut', i)"
        (search)="searchField('shareholders')"
        [control]="shareholder.controls.rut"
        label="RUT"
      />
      <app-study-form-input
        (undo)="resetShareHolderField('name', i)"
        (search)="searchField('shareholders')"
        [control]="shareholder.controls.name"
        label="Nombre"
      />
      <app-study-form-input
        (undo)="resetShareHolderField('equity', i)"
        (search)="searchField('shareholders')"
        [control]="shareholder.controls.equity"
        label="Capital"
        type="number"
      />
      <app-study-form-input
        (undo)="resetShareHolderField('stock', i)"
        (search)="searchField('shareholders')"
        [control]="shareholder.controls.stock"
        label="Acciones"
        type="number"
      />
      <app-button
        color="secondary"
        [disabled]="disabled"
        (buttonClick)="removeShareholder(i)"
      >
        Eliminar
      </app-button>
    </app-study-section>
  </app-input-group>
  <app-study-form-input
    (undo)="resetField('equity')"
    (search)="searchField('equity')"
    [control]="studyForm.controls.equity"
    [errorMessage]="studyForm.controls.equity.errors?.['message']"
    label="Capital Total"
    type="number"
  />
</app-study-section>
